import { Skeleton } from "@/components/skeleton";
import { PendingTransaction } from "@/services/types/get-pending-transactions";
import { formatLabel } from "@/utils/pending-transactions/format-label";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { format, parseISO } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type ListItemProps = { item: PendingTransaction };

export const ListItem = ({
  item: {
    TransactionId,
    TransactionStatus,
    TransactionType,
    BeneficiaryName,
    DateTransaction,
    Amount,
  },
}: ListItemProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isActive = searchParams.get("transaction") === TransactionId;

  const handleSelectTransaction = (transactionId: string) => {
    setSearchParams((params) => {
      params.set("transaction", transactionId);
      return params;
    });
  };

  const handleUnselectTransaction = () => {
    setSearchParams((params) => {
      params.delete("transaction");

      return params;
    });
  };

  return (
    <button
      className={twMerge(
        "flex w-full cursor-pointer items-center justify-between border border-b border-l-transparent border-r-transparent border-t-transparent px-6 py-3 transition-all",
        isActive && "rounded-sm border border-primary-main",
      )}
      key={TransactionId}
      onClick={() => {
        isActive
          ? handleUnselectTransaction()
          : handleSelectTransaction(TransactionId);
      }}
    >
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <div
            className={twMerge(
              "flex size-8 flex-shrink-0 items-center justify-center rounded-full",
              TransactionStatus === "APPROVED" && "bg-status-success-light",
              TransactionStatus === "REPPROVED" && "bg-status-error-light",
              TransactionStatus === "REPROVED" && "bg-status-error-light",
              TransactionStatus === "PENDING" && "bg-status-warning-light",
            )}
          >
            <Icon
              name="GeralMoneyPayReceived"
              className={twMerge(
                "size-6",
                TransactionStatus === "APPROVED" && "fill-status-success-dark",
                TransactionStatus === "REPPROVED" && "fill-status-error-dark",
                TransactionStatus === "REPROVED" && "fill-status-error-dark",
                TransactionStatus === "PENDING" && "fill-status-warning-dark",
              )}
            />
          </div>

          <div className="space-y-1 text-left">
            <h2 className="text-[14px] font-medium">
              {formatLabel({
                TransactionStatus,
                TransactionType,
              })}
            </h2>

            <p className="text-neutral-dark">{BeneficiaryName}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-shrink-0 flex-col items-end gap-1">
        <h2 className="text-[14px] text-neutral-dark">
          {format(parseISO(DateTransaction), "hh:mm")}
        </h2>

        <span
          className={twMerge(
            "text-status-warning-dark",
            TransactionStatus === "APPROVED" && "text-status-success-dark",
            TransactionStatus === "REPPROVED" && "text-status-error-dark",
            TransactionStatus === "REPROVED" && "text-status-error-dark",
            TransactionStatus === "PENDING" && "text-status-warning-dark",
          )}
        >
          - {formatCurrency(Amount)}
        </span>
      </div>
    </button>
  );
};

export const ListItemSkeleton = () => {
  return (
    <div
      className={twMerge(
        "flex cursor-pointer items-center justify-between border border-b border-l-transparent border-r-transparent border-t-transparent px-6 py-3 transition-all mobile:!max-w-[80vw]",
      )}
    >
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Skeleton className="size-8 rounded-full" />

          <div className="space-y-1">
            <Skeleton className="h-[2ex] w-[15ch]" />
            <Skeleton className="h-[1.5ex] w-[20ch]" />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end gap-1">
        <Skeleton className="h-[1.5ex] w-[7ch]" />

        <Skeleton className="h-[1.5ex] w-[5ch]" />
      </div>
    </div>
  );
};
