import React from "react";
import { Warning } from "@/pages/dashboard/components/warning";
import {
  getDashboardRoutesData,
  Hooks,
  ProtectedComponent,
} from "@/utils/utility";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import * as Styles from "./styles";

export const ContentOptions: React.FC = () => {
  const routes = getDashboardRoutesData();

  const isVerticalAvec = Hooks.useCheckVertical("avec");

  const theme = useTheme();
  const navigate = useNavigate();

  const goToPage = (url: string) => {
    navigate(url);
  };

  return (
    <div className="flex justify-between px-inset-md pb-inline-md pt-inline-sm">
      <Styles.LinkIcon onClick={() => goToPage(routes.statements)}>
        <Styles.Icon>
          <Icon name="GeralReceiptReceipt" color={theme.palette.primary.main} />
        </Styles.Icon>
        <Styles.LinkText>Extrato</Styles.LinkText>
      </Styles.LinkIcon>

      <ProtectedComponent
        allowedRoles={[
          "Administrador",
          "Financeiro",
          "Moderador",
          "Proprietário",
        ]}
      >
        <Styles.LinkIcon
          $hide={isVerticalAvec}
          onClick={() => goToPage(routes.charge)}
        >
          <Styles.Icon>
            <Icon
              name="GeralMoneyPayDemand"
              color={theme.palette.primary.main}
            />
          </Styles.Icon>
          <Styles.LinkText>Cobrança</Styles.LinkText>
        </Styles.LinkIcon>
      </ProtectedComponent>

      <Styles.LinkIcon onClick={() => goToPage(routes.pendingTransactions)}>
        <Styles.Icon>
          <Icon name="SettingCheck" color={theme.palette.primary.main} />
          <Warning className="!right-3 !top-2" />
        </Styles.Icon>

        <Styles.LinkText>
          Lançamentos <br />
          pendentes
        </Styles.LinkText>
      </Styles.LinkIcon>

      <Styles.LinkIcon onClick={() => goToPage(routes.dashboardPay)}>
        <Styles.Icon>
          <Icon name="GeralPhone" color={theme.palette.primary.main} />
        </Styles.Icon>
        <Styles.LinkText>Pay</Styles.LinkText>
      </Styles.LinkIcon>

      <Styles.LinkIcon onClick={() => goToPage(routes.settings)}>
        <Styles.Icon>
          <Icon name="Settings" color={theme.palette.primary.main} />
        </Styles.Icon>
        <Styles.LinkText>Configurações</Styles.LinkText>
      </Styles.LinkIcon>

      {/* <Styles.LinkIcon onClick={() => goToPage(routes.pendingTransactions)}>
          <Styles.Icon>
            <Icon name="SettingCheck" color={theme.palette.primary.main} />
            <Warning className="!right-3 !top-2" />
          </Styles.Icon>

          <Styles.LinkText>
            Lançamentos <br />
            pendentes
          </Styles.LinkText>
        </Styles.LinkIcon> */}

      {/* <Styles.LinkIcon onClick={() => goToPage(routes.receipts)}>
          <Styles.Icon>
            <Icon
              name="GeralReceiptReceiptLong"
              color={theme.palette.primary.main}
            />
          </Styles.Icon>
          <Styles.LinkText>Comprovantes</Styles.LinkText>
        </Styles.LinkIcon> */}

      {/* <Styles.LinkIcon onClick={() => navigate(routes.transfersDeposit)}>
          <Styles.Icon>
            <Icon name="LogoAvec" color={theme.palette.primary.main} />
          </Styles.Icon>
          <Styles.LinkText>Depositar</Styles.LinkText>
        </Styles.LinkIcon> */}

      {/* <Styles.LinkIcon onClick={() => navigate(routes.cards)}>
          <Styles.Icon>
            <Icon name="MoneyCard" color={theme.palette.primary.main} />
          </Styles.Icon>
          <Styles.LinkText>Meus cartões</Styles.LinkText>
        </Styles.LinkIcon> */}
    </div>
  );
};

export default ContentOptions;
