import styled, { css } from "styled-components";

export const LinkIcon = styled.div<{ $hide?: boolean }>`
  display: ${({ $hide }) => ($hide ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${({ theme }) => css`
    padding: ${theme.spacings.inset.x3s};
  `}
`;

export const Icon = styled.a`
  display: grid;
  place-content: center;
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacings.inset.xs};
    border-radius: ${theme.borderRadius.circle};
    border: ${theme.borderWidths.sm} solid ${theme.palette.primary.main};

    & > svg {
      width: 24px;
    }
  `}
`;

export const LinkText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x4s};
    line-height: ${theme.lineHeights.md};
    font-weight: ${theme.fontWeights.medium};
    margin-top: ${theme.spacings.stack.quarck};
    text-align: center;
  `}
`;
