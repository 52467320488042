import { createContext, ReactNode, useContext } from "react";
import { getDashboardRoutesData } from "@/utils/utility";
import { useNavigate } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import ContentList from "../../components/drawer-contents/content-list";
import { IDrawerContentOption } from "../../components/drawer-contents/content-list/types";
import ContentOptions from "../../components/drawer-contents/content-options";
import { DrawerContentKey, DrawerContextType } from "./types";

export const DrawerContext = createContext({} as DrawerContextType);

type DrawerContextProviderProps = {
  children: ReactNode;
};

export const DrawerContextProvider = (props: DrawerContextProviderProps) => {
  const routes = getDashboardRoutesData();

  const navigate = useNavigate();

  const paymentData: IDrawerContentOption[] = [
    {
      icon: "ShopBarcode",
      text: "Boleto",
      action: () => navigate(routes.cashOutBoleto),
    },
    {
      icon: "MoneyPix",
      text: "Pix",
      action: () => navigate(routes.cashOutPix),
    },
  ];

  const paymentDesktopData: IDrawerContentOption[] = [
    {
      icon: "ShopBarcode",
      text: "Boleto",
      action: () => navigate(routes.cashOutBoletoCopiaCola),
    },
    {
      icon: "MoneyPix",
      text: "Pix",
      action: () => navigate(routes.cashOutPixCopiaCola),
    },
  ];

  const chargeData: IDrawerContentOption[] = [
    { icon: "ShopShoppingCart", text: "Link de Cobrança" },
    { icon: "ShopBarcode", text: "Boleto" },
    { icon: "MoneyPix", text: "Pix" },
  ];

  const salesData: IDrawerContentOption[] = [
    {
      icon: "GeralMoneyPayReceived",
      text: "Relatório de vendas",
      subTitle: "Acompanhe suas vendas em tempo real",
      action: () => navigate(routes.salesReport),
    },
    {
      icon: "GeralMoneyMoney",
      text: "Valores Recebidos",
      subTitle: "Veja o que recebeu na sua conta",
      action: () => navigate(routes.received),
    },
    {
      icon: "MoneyAntecipate",
      text: "Recebimentos Futuros",
      subTitle: "Antecipe ou veja quem tem a receber",
      action: () => navigate(routes.futureReceipts),
    },
  ];

  const drawerContent: Record<DrawerContentKey, JSX.Element> = {
    MORE_OPTIONS: <ContentOptions />,
    PAYMENT: (
      <ContentList
        description="Escolha como deseja pagar:"
        options={paymentData}
      />
    ),
    CHARGE: (
      <ContentList
        description="Escolha como deseja cobrança:"
        options={chargeData}
      />
    ),
    PAYMENT_DESKTOP: (
      <ContentList
        description="Escolha como deseja pagar:"
        options={paymentDesktopData}
      />
    ),
    SALES: (
      <ContentList
        description="Escolha como deseja ver suas vendas:"
        options={salesData}
      />
    ),
    DEPOSIT_DESKTOP: (
      <Parcel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config={() => System.import("@hyperlocal/banking-transfers")}
        mountParcel={mountRootParcel}
      />
    ),
    "": <></>,
  };

  return (
    <DrawerContext.Provider value={{ drawerContent }}>
      {props.children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const value = useContext(DrawerContext);

  return value;
};
