import { router } from "@/router";
import { getVertical, Hooks, Notifications, themeProvider } from "@/utils";
import { Provider } from "@hyperlocal/vital-theme";
import { Toaster } from "@hyperlocal/vital2";
import { createPortal } from "react-dom";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";

export function App() {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <RouterProvider router={router} />
        <Notifications />

        {createPortal(
          <Toaster position={isMobile ? "bottom-center" : "top-right"} />,
          document.body,
        )}
      </ThemeProvider>
    </Provider>
  );
}
