import { IconProps } from "@hyperlocal/vital-icons";

export const iconName = (type: string): IconProps["name"] => {
  switch (type) {
    case "NEGADO":
      return "MoneyMoneyCanceled";
    case "ESTORNADO":
      return "MoneyMoneyCanceled";
    default:
      return "MoneyMoneyRecive";
  }
};

export const capitalizeOperation = (
  transactionType: string,
  creditCardCompany: string,
  transactionStatus: string,
): string => {
  if (transactionStatus === "NEGADO") return "Venda Cancelada";
  switch (transactionType) {
    case "PARCELAMENTO SEM JUROS":
    case "CREDITO A VISTA":
      return "Cartão de Crédito";
    case "DEBITO":
      return creditCardCompany === "PIX"
        ? "Pix na Maquininha"
        : "Cartão de Débito";
  }
};

export function formatTransactionType(value: string) {
  switch (value) {
    case "CREDITO A VISTA":
      return "Crédito à vista";
    case "PARCELAMENTO SEM JUROS":
      return "Crédito parcelado";
    case "DEBITO":
      return "Débito à vista";
    default:
      return value;
  }
}

export const cardBrandIcon = (brand: string): IconProps["name"] => {
  switch (brand) {
    case "MASTERCARD":
      return "CompaniesCompaniesCardMasterColored";
    case "VISA":
    case "VISA ELECTRON":
      return "CompaniesCompaniesCardVisaColored";
    case "ELO CREDITO":
    case "ELO DEBITO":
      return "CompaniesCompaniesCardEloColored";
    case "AMEX":
    case "AMEX CREDITO":
      return "CompaniesCompaniesCardAmericanExpressColored";
    case "CABAL CREDITO":
      return "CompaniesCompaniesCardCabalColored";
    case "HIPERCARD":
      return "CompaniesCompaniesCardHipercardColored";
    case "MAESTRO":
      return "CompaniesCompaniesCardMaestroColored";
    case "HIPER":
      return "CompaniesCompaniesCardHiperColored";
    case "SOROCRED":
      return "CompaniesCompaniesCardSorocredColored";
    case "DINERSCLUB":
      return "CompaniesCompaniesCardDinersColored";
    case "DISCOVER":
      return "CompaniesCompaniesCardDiscoverColored";
    case "AURA":
      return "CompaniesCompaniesCardAuraColored";
    case "JCB":
      return "CompaniesCompaniesCardJcbColored";
    default:
      return "MoneyPix";
  }
};

export function formatPlataform(value: string) {
  switch (value) {
    case "E_COMMERCE":
      return "E-commerce";
    case "POS":
      return "Máquina de Cartão";
    default:
      return value;
  }
}

export const transactionStatus = {
  APROVADO: "approved",
  ESTORNADO: "reversed",
  NEGADO: "denied",
  CANCELADO: "canceled",
  DESFEITO: "undone",
  PENDENTE: "pending",
};

export const parseTransactionStatus = (status: string) => {
  return transactionStatus[status] || "approved";
};
