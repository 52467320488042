import { ComponentProps, useEffect, useState } from "react";
import { useAccountInfo } from "@/api/account-management";
import ContentOptions from "@/components/drawer-contents/content-options";
import { DrawerOptions } from "@/contexts/drawer/types";
import { Warning } from "@/pages/dashboard/components/warning";
import {
  AccessGroupName,
  getDashboardRoutesData,
  Hooks,
  ProtectedComponent,
  useAccountStore,
} from "@/utils";
import Icon, { IconsValuesEnum } from "@hyperlocal/vital-icons";
import { Drawer } from "@hyperlocal/vital2";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import * as Svg from "../svgs";
import { PaymentModal } from "./payment-modal";

export type NavItems = (
  | {
      type: "button";
      onClick: () => void;
    }
  | {
      type: "link";
      to: string;
    }
) &
  (
    | {
        icon: IconsValuesEnum;
        svg?: never;
      }
    | {
        svg: (props: ComponentProps<"svg">) => JSX.Element;
        icon?: never;
      }
  ) & {
    label: string;
    actionsBlocked?: boolean;
    desktopOrder?: number;
    mobileOrder?: number;
    mobileOnly?: boolean;
    conditionToShow?: boolean;
    allowedRoles?: AccessGroupName[];
    containerClassName?: ComponentProps<"button">["className"];
    labelClassName?: ComponentProps<"button">["className"];
    hasWarning?: boolean;
  };

interface NavActionsProps {
  navItems: NavItems[];
  statusFetched: boolean;
  isMobile: boolean;
}

export function NavActions({
  navItems,
  statusFetched,
  isMobile,
}: Readonly<NavActionsProps>) {
  const sortedNavItems = [...navItems].sort((a, b) => {
    const orderA = isMobile ? (a.mobileOrder ?? 0) : (a.desktopOrder ?? 0);
    const orderB = isMobile ? (b.mobileOrder ?? 0) : (b.desktopOrder ?? 0);
    return orderA - orderB;
  });

  return (
    <>
      {sortedNavItems.map(
        ({
          label,
          icon,
          actionsBlocked,
          mobileOrder,
          desktopOrder,
          mobileOnly,
          svg,
          conditionToShow = true,
          allowedRoles = [
            "Administrador",
            "Analista",
            "Financeiro",
            "Moderador",
            "Proprietário",
          ],
          containerClassName,
          labelClassName,
          hasWarning,
          ...props
        }) => {
          if (
            (isMobile && !mobileOrder) ||
            (!isMobile && mobileOnly) ||
            !conditionToShow
          ) {
            return null;
          }

          const order = isMobile ? mobileOrder : desktopOrder;
          const iconSize = isMobile ? 20 : 32;

          const isBlocked = !statusFetched || actionsBlocked;

          const linkOrButtonStyles = twMerge(
            "flex flex-col items-center navbar-items",
            isMobile
              ? "gap-1 p-1"
              : "w-[128px] h-[112px] justify-between p-[17.5px] border border-neutral-lighter rounded-sm shadow-lower cursor-pointer bg-neutral-white transition-all duration-200 text-primary-main no-underline hover:brightness-[0.98]",
            isBlocked && "opacity-30 cursor-not-allowed pointer-events-none",
            containerClassName,
          );

          const labelStyles = twMerge(
            "font-base font-normal text-x2s text-nowrap mt-6 mobile:!mt-0 mobile:!text-x4s/md mobile:!text-neutral-darkest label-css-wildcard",
            labelClassName,
          );

          const iconStyles =
            "shrink-0 text-primary-main box-content mobile:!p-[13px] mobile:!border mobile:!border-primary-main rounded-circle";

          const SvgIcon = svg;

          const IconElement = SvgIcon ? (
            <SvgIcon
              width={iconSize}
              height={iconSize}
              className={iconStyles}
            />
          ) : (
            <Icon
              name={icon}
              width={iconSize}
              height={iconSize}
              fill="currentColor"
              className={iconStyles}
            />
          );

          return (
            <ProtectedComponent key={label} allowedRoles={allowedRoles}>
              {props.type === "link" ? (
                <Link
                  to={props.to}
                  className={linkOrButtonStyles}
                  style={{ order }}
                >
                  {hasWarning && <Warning />}

                  {IconElement}
                  <h5 className={labelStyles}>{label}</h5>
                </Link>
              ) : (
                <button
                  onClick={props.onClick}
                  className={linkOrButtonStyles}
                  style={{ order }}
                >
                  {hasWarning && <Warning />}

                  {IconElement}
                  <h5 className={labelStyles}>{label}</h5>
                </button>
              )}
            </ProtectedComponent>
          );
        },
      )}
    </>
  );
}

export function Header() {
  const routes = getDashboardRoutesData();
  const isDesktop = Hooks.useMediaQuery("desktop");
  const isNotVerticalAvec = !Hooks.useCheckVertical("avec");
  const navigate = useNavigate();
  // const { cadastralBlock, statusFetched } = Hooks.useIsUserBlocked(); <-- REMOVER AO IMPLEMENTAR O ENDPOINT DE INFORMAÇÕES DO USUÁRIO
  const cadastralBlock = false;
  const statusFetched = true;
  const [searchParam, setSearchParam] = useSearchParams();
  const [drawer, setDrawer] = useState<DrawerOptions>({
    title: "",
    state: false,
    content: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { currentAccountId } = useAccountStore();

  useAccountInfo({
    accountId: currentAccountId,
  });

  useEffect(() => {
    function handleDepositNavigation() {
      if (!searchParam.get("deposit")) return;
      if (isDesktop) {
        setDrawer({
          title: "Transferência",
          content: "DEPOSIT_DESKTOP",
          onClose: () => {
            searchParam.delete("deposit");
            setSearchParam(searchParam);
          },
        });
        return;
      }

      navigate(routes.transfersDeposit);
    }

    handleDepositNavigation();
  }, [
    searchParam,
    isDesktop,
    navigate,
    routes?.transfersDeposit,
    setSearchParam,
  ]);

  const navItems: NavItems[] = [
    // {
    //   label: "Extrato",
    //   icon: "GeralReceiptReceipt",
    //   type: "link",
    //   to: routes?.statements,
    // },
    {
      label: "Pix",
      svg: Svg.Pix,
      type: "link",
      to: routes?.pixDashboard,
      mobileOrder: 2,
      desktopOrder: 2,
      allowedRoles: ["Proprietário", "Administrador", "Moderador"],
    },
    {
      label: "Transferir",
      icon: "GeralArrowArrowTransfer",
      actionsBlocked: cadastralBlock,
      type: "link",
      to: routes?.transfers,
      mobileOrder: 3,
      desktopOrder: 3,
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Financeiro",
      ],
    },
    {
      label: "Cobrança",
      icon: "GeralMoneyPayDemand",
      type: "link",
      to: routes?.charge,
      conditionToShow: isNotVerticalAvec,
      desktopOrder: 4,
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Financeiro",
      ],
    },
    // {
    //   label: "Vendas",
    //   icon: "GeralMoneyPayReceived",
    //   onClick: () =>
    //     setDrawer({ state: !drawer.state, title: "Vendas", content: "SALES" }),
    //   type: "button",
    //   mobileOrder: 4,
    // },
    {
      label: "Pagar",
      icon: "GeralMoneyPay",
      onClick: () => setIsModalOpen(true),
      actionsBlocked: cadastralBlock,
      type: "button",
      mobileOrder: 1,
      desktopOrder: 1,
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Financeiro",
      ],
    },
    // {
    //   label: "Meus cartões",
    //   icon: "MoneyCard",
    //   type: "link",
    //   to: routes.cards,
    // },
    {
      label: "Pay",
      icon: "GeralPhone",
      type: "link",
      to: routes?.dashboardPay,
      conditionToShow: isNotVerticalAvec,
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Analista",
        "Financeiro",
      ],
    },
    {
      label: "Configurações",
      svg: Svg.Settings,
      type: "link",
      to: routes?.settings,
      desktopOrder: 4,
      allowedRoles: ["Proprietário", "Administrador", "Moderador"],
    },
    // {
    //   label: "Depositar",
    //   svg: Svg.Avec,
    //   onClick: () => setSearchParam("deposit=true"),
    //   type: "button",
    // },
    // {
    //   label: "Comprovantes",
    //   icon: "GeralReceiptReceiptLong",
    //   type: "link",
    //   to: routes?.receipts,
    // },
    {
      label: "Lançamentos pendentes",
      icon: "Check",
      type: "link",
      to: routes?.pendingTransactions,
      allowedRoles: ["Proprietário", "Administrador", "Moderador"],
      desktopOrder: 5,
      containerClassName: "relative",
      labelClassName: "text-wrap mt-2 text-center",
      hasWarning: true,
    },
    {
      label: "Ver mais",
      icon: "GeralGeralAdd",
      onClick: () =>
        setDrawer({
          state: !drawer.state,
          title: "Mais Opções",
          content: "MORE_OPTIONS",
        }),
      type: "button",
      mobileOrder: 5,
      mobileOnly: true,
      hasWarning: false,
      containerClassName: "relative",
      allowedRoles: [
        "Administrador",
        "Analista",
        "Financeiro",
        "Moderador",
        "Proprietário",
      ],
    },
  ];

  function onDrawerClose() {
    setDrawer({ state: !drawer.state, title: "", content: "" });
    if (drawer.onClose) {
      drawer.onClose();
    }
  }

  function onOpenChange(open: boolean) {
    if (!open) {
      setDrawer({ state: false });
    }
  }

  return (
    <>
      <header className="flex flex-col gap-6 pb-6 mobile:!order-2 mobile:!bg-primary-lighter mobile:!pb-4">
        <nav className="flex gap-inline-x2s mobile:!mt-4 mobile:!justify-center mobile:!gap-4">
          <NavActions
            navItems={navItems}
            statusFetched={statusFetched}
            isMobile={!isDesktop}
          />
        </nav>
      </header>

      <Drawer.Root
        direction={isDesktop ? "right" : "bottom"}
        onClose={onDrawerClose}
        onOpenChange={onOpenChange}
        open={drawer.state}
      >
        <Drawer.Content>
          <Drawer.Header>
            <Drawer.Title>{drawer.title}</Drawer.Title>
          </Drawer.Header>

          <ContentOptions />
        </Drawer.Content>
      </Drawer.Root>

      {isModalOpen && (
        <PaymentModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
}
