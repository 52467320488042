import apis from "@/services";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency, useAccountStore } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import {
  addDays,
  eachDayOfInterval,
  format,
  isSameDay,
  subDays,
} from "date-fns";
import Chart from "react-apexcharts";
import { useTheme } from "styled-components";

function getSurroundingDates() {
  const today = new Date();
  const startDate = subDays(today, 3);
  const endDate = addDays(today, 3);

  const dates = eachDayOfInterval({ start: startDate, end: endDate });
  return dates;
}

export const DashboardPayChart = () => {
  const theme = useTheme();

  const { account } = useAccountStore();
  const { customerId: guidCustomer } =
    account.companyInfoResponse || account.personInfoResponse;

  const { data, isLoading, isError } = useQuery({
    queryFn: () => apis.getWeekChart(guidCustomer),
    queryKey: ["week-chart"],
  });

  if (isLoading) {
    return (
      <div className="flex h-[367px] items-center justify-center rounded-sm bg-neutral-white">
        Carregando...
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="flex h-[367px] items-center justify-center rounded-sm bg-neutral-white">
        Erro ao carregar os dados
      </div>
    );
  }

  const dates = getSurroundingDates();

  const paidData = dates.map((date) => {
    const items = data.paidReceivables.filter(({ paymentDate }) =>
      isSameDay(new Date(paymentDate), date),
    );

    const sum = items.reduce((acc, item) => acc + item.paidAmount, 0);

    return sum;
  });

  const futureData = dates.map((date) => {
    const items = data.futureReceivables.filter(({ paymentDate }) =>
      isSameDay(new Date(paymentDate), date),
    );

    const sum = items.reduce((acc, item) => acc + item.futureAmount, 0);

    return sum;
  });

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: {
        show: false,
      },
      offsetX: -8,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "10px",
        borderRadius: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      categories: dates.map((date) => format(date, "dd/MM")),
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) =>
          new Intl.NumberFormat("pt-BR", {
            maximumFractionDigits: 2,
          }).format(val),
        style: {
          colors: theme.palette.neutral.dark,
          fontSize: "14px",
        },
      },
      forceNiceScale: true,
      min: 0,
    },
    fill: {
      colors: [theme.palette.primary.main, theme.palette.secondary.darker],
    },
    tooltip: {
      y: {
        formatter: (val) => `R$ ${val > 0 ? formatCurrency(val) : "0,00"}`,
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: "Nenhum dado a ser mostrado.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 15,
      offsetY: -15,
    },
  };

  return (
    <div className="flex h-full flex-col rounded-sm bg-neutral-white px-[37.5px] py-[16.5px]">
      <Chart
        options={options}
        series={[
          {
            name: "Recebidos",
            data: paidData,
          },
          {
            name: "Futuras",
            data: futureData,
          },
        ]}
        type="bar"
      />

      <div className="flex w-full items-center justify-evenly">
        <div className="flex flex-col">
          <p className="text-xs font-bold leading-[150%] text-neutral-darkest">
            Recebidos
          </p>
          <b className="text-sm font-bold leading-[150%] text-primary-main">
            <small className="mr-inline-x3s text-xs font-regular">R$</small>
            {formatCurrency(data.paidTotal)}
          </b>
        </div>

        <div className="flex flex-col">
          <p className="text-xs font-bold leading-[150%] text-neutral-darkest">
            Futuras
          </p>
          <b className="text-sm font-bold leading-[150%] text-secondary-darker">
            <small className="mr-inline-x3s text-xs font-regular">R$</small>
            {formatCurrency(data.futureTotal)}
          </b>
        </div>
      </div>
    </div>
  );
};
