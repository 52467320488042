import {
  ContactType,
  UpdateContactAccount,
  UseUpdateMutationVariables,
} from "@/api/account-management/manage-contacts";
import { FavoredListItem } from "@/pages/settings/react-query/favored-list";
import { unMask } from "@/utils";
import { DOCK_BANK_CODE } from "@/utils/constants";
import { z } from "zod";

export const selectOptions = ["Conta bancária", "Chave Pix"] as const;
export const radioGroupOptions = ["Corrente", "Poupança", "Pagamento"] as const;

export const CPF_LENGTH = 11;
export const CNPJ_LENGTH = 14;

export const newFavoredSchemaObject = z.object({
  type: z.enum([selectOptions[0], ...selectOptions.slice(1)], {
    required_error: "Selecione um tipo",
  }),
  name: z.string().min(1, "O nome é obrigatório"),
  agency: z.string().optional(),
  accountNumber: z.string().optional(),
  bank: z
    .object({
      bankCode: z.string(),
      bankName: z.string(),
      ispb: z.string(),
      shortBankName: z.string(),
    })
    .optional(),
  document: z
    .string()
    .min(1, "O cpf ou cnpj é obrigatório")
    .transform((val, ctx) => {
      const parsed = val.match(/\d/g)?.join("");

      if (parsed.length < CPF_LENGTH) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Faltam ${CPF_LENGTH - parsed.length} dígitos`,
        });

        return z.NEVER;
      }

      if (parsed.length > CPF_LENGTH && parsed.length < CNPJ_LENGTH) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Faltam ${CNPJ_LENGTH - parsed.length} dígitos`,
        });

        return z.NEVER;
      }

      return parsed;
    }),
  bankType: z
    .enum([radioGroupOptions[0], ...radioGroupOptions.slice(1)], {
      required_error: "Selecione um tipo",
    })
    .optional(),
  pixKey: z.string().optional(),
});

export type NewFavoredSchemaInput = z.input<typeof newFavoredSchemaObject>;
export type NewFavoredSchemaOutput = z.output<typeof newFavoredSchemaObject>;

export function superRefineCallback(
  val: NewFavoredSchemaOutput,
  ctx: z.RefinementCtx,
) {
  if (val.type === "Chave Pix") {
    if (val.pixKey?.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "A chave pix é obrigatória",
        path: ["pixKey"],
      });
    }
  }

  if (val.type === "Conta bancária") {
    if (!val.bank) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "O banco é obrigatório",
        path: ["bank"],
      });
    }

    if (val.accountNumber?.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "O número da conta é obrigatório",
        path: ["accountNumber"],
      });
    }

    if (val.agency?.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "A agência é obrigatória",
        path: ["agency"],
      });
    }
  }
}

export const newFavoredSchema =
  newFavoredSchemaObject.superRefine(superRefineCallback);

export type Step = "FORM" | "TOKEN";

export const stepTitle: Record<Step, string> = {
  FORM: "Novo favorecido",
  TOKEN: "Validação",
};

export type RadioOptions = (typeof radioGroupOptions)[number];
export type AccountType = "CC" | "SA" | "PA";

export const bankAccountType: Record<RadioOptions, AccountType> = {
  Corrente: "CC",
  Poupança: "SA",
  Pagamento: "PA",
};

export type NewFavoredSheetProps = {
  isOpen?: boolean;
};

export type LookupState = {
  isPix: boolean;
  isTed: boolean;
  isP2P: boolean;
};

type LookupPayload<TOmit> = Omit<Extract<ContactType, TOmit>, "transferType">;

type LookupGetCreateContactData =
  | {
      transferType: "p2p";
      payload: LookupPayload<{ transferType: "p2p" }>;
    }
  | {
      transferType: "pix";
      payload: LookupPayload<{ transferType: "pix" }>;
    }
  | {
      transferType: "ted";
      payload: LookupPayload<{ transferType: "ted" }>;
    };

type CreateContactDataLookUp = {
  condition: (state: LookupState) => boolean;
  getCreateContactData: (
    formData: NewFavoredSchemaInput,
  ) => LookupGetCreateContactData;
};

export const createContactDataLookup: CreateContactDataLookUp[] = [
  {
    condition: (state: LookupState) => state.isPix,
    getCreateContactData: (formData: NewFavoredSchemaInput) => ({
      transferType: "pix",
      payload: {
        contactPix: {
          pixKey: formData.pixKey,
        },
      },
    }),
  },
  {
    condition: (state: LookupState) => state.isTed,
    getCreateContactData: (formData: NewFavoredSchemaInput) => {
      const accountNumber = formData.accountNumber.replace(/\D/g, "");
      return {
        transferType: "ted",
        payload: {
          contactTed: {
            branch: formData.agency,
            branchDigit: "0",
            accountNumber: accountNumber.slice(0, -1),
            accountDigit: accountNumber.at(-1),
            bankCode: formData.bank.bankCode,
            bankName: formData.bank.shortBankName,
            bankAccountType: bankAccountType[formData.bankType],
          },
        },
      };
    },
  },
  {
    condition: (state: LookupState) => state.isP2P,
    getCreateContactData: (formData: NewFavoredSchemaInput) => {
      const accountNumber = formData.accountNumber.replace(/\D/g, "");
      return {
        transferType: "p2p",
        payload: {
          contactP2p: {
            branch: formData.agency,
            accountNumber: accountNumber.slice(0, -1),
            accountDigit: accountNumber.at(-1),
          },
        },
      };
    },
  },
];

type UpdateContactDataLookUp = {
  condition: (state: LookupState) => boolean;
  getUpdateContactData: (
    formData: NewFavoredSchemaInput,
  ) => UpdateContactAccount;
};

const updateContactDataLookup: UpdateContactDataLookUp[] = [
  {
    condition: (state: LookupState) => state.isTed,
    getUpdateContactData: (formData: NewFavoredSchemaInput) => {
      const accountNumber = formData.accountNumber.replace(/\D/g, "");
      return {
        TransferType: "ted",
        AccountDigit: accountNumber.at(-1),
        AccountNumber: accountNumber.slice(0, -1),
        BankAccountType: bankAccountType[formData.bankType],
        BankCode: formData.bank.bankCode,
        BankName: formData.bank.shortBankName,
        Branch: formData.agency,
        BranchDigit: "0",
      };
    },
  },
  {
    condition: (state: LookupState) => state.isPix,
    getUpdateContactData: (formData: NewFavoredSchemaInput) => {
      return {
        TransferType: "pix",
        PixKey: formData.pixKey,
      };
    },
  },
  {
    condition: (state: LookupState) => state.isP2P,
    getUpdateContactData: (formData: NewFavoredSchemaInput) => {
      const accountNumber = formData.accountNumber.replace(/\D/g, "");
      return {
        TransferType: "p2p",
        AccountDigit: accountNumber.at(-1),
        AccountNumber: accountNumber.slice(0, -1),
        Branch: formData.agency,
        BankName: "Hyperlocal",
        BankCode: "301",
        BankAccountType: bankAccountType[formData.bankType],
        BranchDigit: "0",
      };
    },
  },
];

export type ContactExistsStatus =
  | "NEW_ACCOUNT"
  | "NEW_CONTACT"
  | "CONTACT_ACCOUNT_EXISTS";

export const getUpdateContactData = ({
  contactList,
  formData,
  status,
}: {
  formData: NewFavoredSchemaInput;
  status: ContactExistsStatus;
  contactList: FavoredListItem[];
}): UseUpdateMutationVariables => {
  const contact = contactList.find(
    (contact) => unMask(contact.document) === unMask(formData.document),
  );

  if (!contact) {
    throw new Error("Contact data not found");
  }

  const contactAccounts: UpdateContactAccount[] = contact.accounts.map(
    (account) => ({
      Id: account.id,
      AccountDigit: account.accountDigit,
      AccountNumber: account.accountNumber,
      BankAccountType: account.bankAccountType,
      BankCode: account.bankCode,
      BankName: account.bankName,
      Branch: account.branch,
      BranchDigit: account.branchDigit,
      ISPB: Number(account.ispb),
      P2PAccountId: account.P2PAccountId,
      PixKey: account.pixKey,
      TransferType: account.transferType,
    }),
  );

  const isPixType = formData.type === "Chave Pix";

  const state: LookupState = {
    isPix: isPixType,
    isP2P: !isPixType && formData.bank.bankCode === DOCK_BANK_CODE,
    isTed: !isPixType && formData.bank.bankCode !== DOCK_BANK_CODE,
  };

  const { getUpdateContactData } = updateContactDataLookup.find(
    ({ condition }) => condition(state),
  );

  const transferData = getUpdateContactData(formData);

  if (status !== "CONTACT_ACCOUNT_EXISTS") {
    contactAccounts.push(transferData);
  }

  const payload: UseUpdateMutationVariables = {
    isFavoredContact: true,
    document: contact.document,
    contactName: contact.name,
    accounts: contactAccounts,
  };

  return payload;
};

export const getContactExistsStatus = ({
  contactList,
  form,
}: {
  form: NewFavoredSchemaInput;
  contactList: FavoredListItem[];
}): ContactExistsStatus => {
  console.log("contactList", contactList);
  console.log("form", form);
  const contactExists = contactList.find(
    (contact) => unMask(contact.document) === unMask(form.document),
  );

  console.log("contactExists", contactExists);

  // Criar novo contato
  if (!contactExists) return "NEW_CONTACT";

  const accountExists = contactExists.accounts.find((account) => {
    const hasPixKey =
      form.type === "Chave Pix" && form.pixKey === account.pixKey;

    const hasBankAccount =
      form.agency === account.branch &&
      form.accountNumber ===
        `${account.accountNumber}${account.accountDigit || ""}`;

    return hasPixKey || hasBankAccount;
  });

  // Editar contato para favorito
  if (accountExists) return "CONTACT_ACCOUNT_EXISTS";

  // Editar contato para favorito e adicionar nova conta
  return "NEW_ACCOUNT";
};
