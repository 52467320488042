import successDesktop from "@/assets/success-desktop.svg";
import { Button, Dialog } from "@hyperlocal/vital2";

type SuccessModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};

export function SuccessModal({ isOpen, onCloseModal }: SuccessModalProps) {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="my-auto flex flex-col items-center p-inset-lg mobile:!h-full mobile:!max-w-none">
          <img
            src={successDesktop}
            alt="Fazendo sinal de Ok com a mão"
            className="mt-8 mobile:!mt-auto mobile:!w-[285px]"
          />
          <h4 className="text-center text-2xl/md font-bold text-neutral-black">
            A verificação em duas etapas foi concluída com sucesso.
          </h4>
          <span className="text-center text-neutral-darkest mobile:!mb-28">
            Você configurou a verificação em duas etapas, o que torna sua conta
            bancária mais segura. Vamos fazer o login novamente para usar o
            banco.
          </span>
          <Button.Root
            variant="primary"
            className="mt-4 w-full justify-center"
            onClick={onCloseModal}
          >
            Ok
          </Button.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
