import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Button, Sheet, TextField, toast } from "@hyperlocal/vital2";
import { useForm } from "react-hook-form";
import { z } from "zod";

const addressSchema = z.object({
  cep: z.string().min(1, "O cep é obrigatório"),
  address: z.string().min(1, "O endereço é obrigatório"),
  number: z.string().min(1, "O número é obrigatório"),
  neighborhood: z.string().min(1, "O bairro é obrigatório"),
  city: z.string().min(1, "A cidade é obrigatório"),
  state: z.string().min(1, "O estado é obrigatório"),
});

type AddressSchema = z.infer<typeof addressSchema>;

export function ChangeAddressSheet() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<AddressSchema>({
    resolver: zodResolver(addressSchema),
    mode: "all",
  });

  function onOpenChange(open: boolean) {
    if (open) {
      reset();
    }

    setIsSheetOpen(open);
  }

  function handleCloseSheet() {
    setIsSheetOpen(false);
  }

  async function handleFormSubmit(formData: AddressSchema) {
    try {
      setIsSendingForm(true);

      const validatedFormData = addressSchema.parse(formData);

      // TODO: Integrar back-end
      await new Promise((resolve) => setTimeout(resolve, 500));
      console.log(validatedFormData);

      toast({
        variant: "success",
        title: "Endereço alterado",
      });
      handleCloseSheet();
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar o novo endereço",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsSendingForm(false);
    }
  }

  const values = watch();
  const hasErrors = !addressSchema.safeParse(values).success;

  return (
    <Sheet.Root onOpenChange={onOpenChange} open={isSheetOpen}>
      <Sheet.Trigger asChild>
        {/* <Button.Root
          variant="link"
          className="!h-auto !p-0"
          disabled
          onClick={() => setIsSheetOpen(true)}
        >
          Alterar endereço
        </Button.Root> */}
        {/* removido temporariamente */}
      </Sheet.Trigger>

      <Sheet.Content
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="px-4"
      >
        <Sheet.Header>
          <Sheet.Title>Alterar {isMobile ? "dados" : "endereço"}</Sheet.Title>
        </Sheet.Header>

        <form
          className="flex flex-1 flex-col justify-between"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="space-y-6">
            <h2 className="text-sm/md font-bold text-neutral-darkest mobile:!hidden">
              Endereço de entrega
            </h2>

            <div className="flex items-start gap-4">
              <div className="flex-1">
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="cep"
                >
                  CEP
                </TextField.Label>
                <TextField.Input
                  id="cep"
                  autoComplete="postal-code"
                  placeholder="14051-070"
                  data-status={errors.cep && "error"}
                  {...register("cep")}
                />
                {errors.cep && (
                  <TextField.Helper>{errors.cep.message}</TextField.Helper>
                )}
              </div>

              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noreferrer noopener"
                className="mt-6 px-6 py-3 font-base text-xs/md font-medium text-primary-main underline transition-colors hover:text-primary-dark"
              >
                Não sei meu CEP
              </a>
            </div>

            <div className="grid grid-cols-[7fr_2fr] gap-4">
              <div>
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="address"
                >
                  Endereço
                </TextField.Label>
                <TextField.Input
                  id="address"
                  placeholder="Rua Sebastião Gumercindo da Silva"
                  data-status={errors.address && "error"}
                  {...register("address")}
                />
                {errors.address && (
                  <TextField.Helper>{errors.address.message}</TextField.Helper>
                )}
              </div>

              <div>
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="number"
                >
                  Número
                </TextField.Label>
                <TextField.Input
                  id="number"
                  placeholder="5001"
                  data-status={errors.number && "error"}
                  {...register("number")}
                />
                {errors.number && (
                  <TextField.Helper>{errors.number.message}</TextField.Helper>
                )}
              </div>
            </div>

            <div>
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="neighborhood"
              >
                Bairro
              </TextField.Label>
              <TextField.Input
                id="neighborhood"
                autoComplete="address-level3"
                placeholder="Alameda dos Sebastiões"
                data-status={errors.neighborhood && "error"}
                {...register("neighborhood")}
              />
              {errors.neighborhood && (
                <TextField.Helper>
                  {errors.neighborhood.message}
                </TextField.Helper>
              )}
            </div>

            <div className="grid grid-cols-[7fr_2fr] gap-4">
              <div>
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="city"
                >
                  Cidade
                </TextField.Label>
                <TextField.Input
                  id="city"
                  autoComplete="address-level2"
                  placeholder="Sebastilandia do Norte"
                  data-status={errors.city && "error"}
                  {...register("city")}
                />
                {errors.city && (
                  <TextField.Helper>{errors.city.message}</TextField.Helper>
                )}
              </div>

              <div>
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="state"
                >
                  Estado
                </TextField.Label>
                <TextField.Input
                  id="state"
                  autoComplete="address-level1"
                  placeholder="SP"
                  data-status={errors.state && "error"}
                  {...register("state")}
                />
                {errors.state && (
                  <TextField.Helper>{errors.state.message}</TextField.Helper>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-1">
            <Button.Root
              type="button"
              variant="link"
              className="w-full"
              onClick={handleCloseSheet}
            >
              Cancelar
            </Button.Root>

            <Button.Root
              variant="primary"
              className="w-full whitespace-nowrap"
              disabled={hasErrors}
              isLoading={isSendingForm}
            >
              Salvar alteração
            </Button.Root>
          </div>
        </form>
      </Sheet.Content>
    </Sheet.Root>
  );
}
