import { Fragment } from "react/jsx-runtime";
import { ErrorFallback } from "@/components/error-fallback";
import { LoadingTransaction } from "@/components/loading-transaction";
import { ScrollArea } from "@/components/scroll-area";
import { SalesListItem } from "@/services/types/get-sales";
import {
  capitalizeFirstLetter,
  dateFormat,
  getToday,
  parseAmountToBRL,
} from "@/utils";
import {
  capitalizeOperation,
  cardBrandIcon,
  formatPlataform,
  formatTransactionType,
  iconName,
  parseTransactionStatus,
} from "@/utils/sales";
import Icon from "@hyperlocal/vital-icons";
import { Avatar, Card, Sheet } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { useSales } from "../../api/sales/use-sales";

const avatarStyles = tv({
  variants: {
    status: {
      approved: "bg-secondary-lighter text-secondary-lighter",
      pending: "bg-status-warning-light text-status-warning-light",
      reversed: "bg-status-error-light text-status-error-light",
      canceled: "bg-status-error-light text-status-error-light",
      undone: "bg-status-error-light text-status-error-light",
      denied: "bg-status-error-light text-status-error-light",
    },
  },
});

const avatarIconStyles = tv({
  variants: {
    status: {
      approved: "fill-secondary-darker stroke-secondary-darker",
      pending: "fill-status-warning-darker stroke-status-warning-darker",
      reversed: "fill-status-error-darker stroke-status-error-darker",
      canceled: "fill-status-error-darker stroke-status-error-darker",
      undone: "fill-status-error-darker stroke-status-error-darker",
      denied: "fill-status-error-darker stroke-status-error-darker",
    },
  },
});

const subTitleStyles = tv({
  base: "text-neutral-darkest",
  variants: {
    status: {
      approved: "text-secondary-darker",
      pending: "text-status-warning-darker",
      reversed: "text-status-error-darker",
      canceled: "text-status-error-darker",
      undone: "text-status-error-darker",
      denied: "text-status-error-darker",
    },
  },
});

const generalStyles = tv({
  slots: {
    dl: "flex justify-between my-4 gap-4 w-full flex-wrap",
    dt: "font-base font-medium text-neutral-darkest text-xs/default w-fit",
    dd: "font-base text-neutral-dark text-xs/default text-end",
  },
});

const SalesDetail = ({ transaction }: { transaction: SalesListItem }) => {
  const { dd, dl, dt } = generalStyles();

  return (
    <Sheet.Root>
      <Sheet.Trigger asChild>
        <Card className="mb-4 !w-full !p-0">
          <ul
            role="button"
            className="flex h-[73px] w-full items-center justify-between gap-4 bg-transparent px-3 py-6 mobile:!h-[63px]"
          >
            <Avatar.Root
              variant="primary"
              size="md"
              className={twMerge(
                "flex-shrink-0",
                avatarStyles({
                  status: parseTransactionStatus(
                    transaction.transaction_status,
                  ),
                }),
              )}
            >
              <Avatar.Slot
                name={iconName(transaction.transaction_status)}
                fill="currentColor"
                className={avatarIconStyles({
                  status: parseTransactionStatus(
                    transaction.transaction_status,
                  ),
                })}
              />
            </Avatar.Root>
            <div className="flex flex-[2] flex-col">
              <p className="w-[10em] overflow-hidden text-ellipsis whitespace-nowrap text-left text-[14px]/md text-neutral-darkest">
                {capitalizeOperation(
                  transaction.transaction_type,
                  transaction?.credit_card_company,
                  transaction?.transaction_status,
                )}
              </p>
              <div className="flex gap-1">
                <Icon
                  name={cardBrandIcon(transaction?.credit_card_company)}
                  width={24}
                  height={24}
                  fill="currentColor"
                />
                <p className="w-[10em] overflow-hidden text-ellipsis whitespace-nowrap text-left text-[14px]/md text-neutral-darkest">
                  {transaction.acquirer_nsu}
                </p>
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <p className="text-[14px]/md text-neutral-dark">
                {transaction.total_installments > 1
                  ? `Parcelado em ${transaction.total_installments}x`
                  : `à Vista`}
              </p>
              <p className="text-xs/sm text-secondary-darker">
                {parseAmountToBRL(transaction.total_value)}
              </p>
            </div>
          </ul>
        </Card>
      </Sheet.Trigger>
      <Sheet.Content>
        <Sheet.Header>
          <Sheet.Title>Detalhes da venda</Sheet.Title>
        </Sheet.Header>
        <div className="mb-6 flex w-full flex-col items-center">
          <Avatar.Root
            variant="primary"
            size="lg"
            className={twMerge(
              "flex-shrink-0",
              avatarStyles({
                status: parseTransactionStatus(transaction.transaction_status),
              }),
            )}
          >
            <Avatar.Slot
              name={iconName(transaction.transaction_status)}
              fill="currentColor"
              className={avatarIconStyles({
                status: parseTransactionStatus(transaction.transaction_status),
              })}
            />
          </Avatar.Root>
          <h3 className="mt-6 text-center text-sm font-bold text-neutral-darkest">
            {capitalizeOperation(
              transaction?.transaction_type,
              transaction?.credit_card_company,
              transaction?.transaction_status,
            )}
          </h3>
          <h3
            className={twMerge(
              "text-center text-sm font-bold text-neutral-darkest",
              subTitleStyles({
                status: parseTransactionStatus(transaction.transaction_status),
              }),
            )}
          >
            {capitalizeFirstLetter(
              transaction?.transaction_status.toLowerCase(),
            )}
          </h3>
          <hr className="my-4 w-full" />
          <dl className={dl()}>
            <dt className={dt()}>Valor da venda</dt>
            <dd className={dd()}>
              {parseAmountToBRL(transaction?.total_value)}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Data da transação</dt>
            <dd className={dd()}>
              {dateFormat(transaction?.transaction_date)}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Tipo da transação</dt>
            <dd className={dd()}>
              {formatTransactionType(transaction?.transaction_type)}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Método de pagamento</dt>
            <dd className={dd()}>
              {capitalizeOperation(
                transaction?.transaction_type,
                transaction?.credit_card_company,
                transaction?.transaction_status,
              )}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Bandeira</dt>
            <div className="flex items-center justify-center">
              <Icon
                height={24}
                width={24}
                name={cardBrandIcon(transaction?.credit_card_company)}
              />
            </div>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Nº do cartão</dt>
            <dd className={dd()}>{transaction?.truncated_number}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Captura</dt>
            <dd className={dd()}>{formatPlataform(transaction?.platform)}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Nº serial do terminal</dt>
            <dd className={dd()}>{transaction?.terminal_serial_number}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Cod de autorização</dt>
            <dd className={dd()}>{transaction?.authorization_code}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>NSU</dt>
            <dd className={dd()}>{transaction?.nsu}</dd>
          </dl>
          <hr className="w-full" />
          <div className={twMerge(dl(), "flex-col items-start")}>
            <dt className={twMerge(dt(), "text-start")}>Id da operação</dt>
            <dd className={twMerge(dd(), "text-start")}>
              {transaction?.guid_transaction}
            </dd>
          </div>
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
};

export const SalesListComponent = () => {
  const { data, isLoading } = useSales();

  if (isLoading)
    return (
      <p className="p-6">
        <LoadingTransaction />
      </p>
    );

  if (!data.transacoes.length) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center pt-[80px]">
        <p className="text-3xs font-medium text-neutral-main">
          Nenhuma venda encontrada
        </p>
      </div>
    );
  }

  return (
    <ul className="flex h-full flex-col overflow-hidden py-6">
      <ScrollArea className="h-full px-6 pb-6 mobile:pb-0">
        {data.transacoes.map((transaction, index) => (
          <Fragment key={index}>
            <div className="my-4 flex flex-row items-center justify-between">
              <h5 className="font-base text-sm/[30px] font-bold text-neutral-darkest mobile:!text-x3s mobile:!font-medium mobile:!text-neutral-dark">
                {getToday(transaction.date)}
              </h5>
              <p className="text-xs/sm text-neutral-dark mobile:!text-x3s mobile:!font-medium">
                Vendas no dia {parseAmountToBRL(transaction.amount)}
              </p>
            </div>
            {transaction.transactions.map((el, index) => (
              <SalesDetail transaction={el} key={index} />
            ))}
          </Fragment>
        ))}
      </ScrollArea>
    </ul>
  );
};

export const SalesList = () => {
  return (
    <ErrorFallback>
      <SalesListComponent />
    </ErrorFallback>
  );
};
