import { ComponentProps } from "react";
import { useTransaction } from "@/pages/pending-transactions/common";
import { PendingTransaction } from "@/services/types/get-pending-transactions";
import { formatLabel } from "@/utils/pending-transactions/format-label";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import * as Accordion from "@radix-ui/react-accordion";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { twMerge } from "tailwind-merge";

const Field = {
  Root: (props: ComponentProps<"div">) => (
    <div className="grid grid-cols-2 gap-2" {...props} />
  ),
  Label: (props: ComponentProps<"span">) => (
    <span className="text-[14px] text-neutral-darker" {...props} />
  ),
  Value: ({ className, ...props }: ComponentProps<"span">) => (
    <span
      className={twMerge("text-end text-[14px] text-neutral-dark", className)}
      {...props}
    />
  ),
};

type SummaryItemProps = { transaction: PendingTransaction; isOpen: boolean };
export const SummaryItem = ({ transaction, isOpen }: SummaryItemProps) => {
  const { data } = useTransaction(transaction.TransactionId, isOpen);

  const {
    TransactionId,
    DateTransaction,
    TransactionStatus,
    TransactionType,
    BeneficiaryName,
  } = transaction;

  const day = format(new Date(DateTransaction), "dd");
  const month = format(new Date(DateTransaction), "MMM", {
    locale: ptBR,
  });

  return (
    <Accordion.Item
      value={TransactionId}
      className="rounded-sm border px-6 py-3"
    >
      <Accordion.Header className="flex justify-between">
        <div className="flex gap-2">
          <div className="flex flex-col items-center justify-center">
            <p className="font-bold">{day}</p>
            <p className="font-medium uppercase">{month}</p>
          </div>

          <div className="flex flex-col gap-1">
            <p>{formatLabel({ TransactionStatus, TransactionType })}</p>
            <p className="text-neutral-dark">{BeneficiaryName}</p>
          </div>
        </div>

        <Accordion.Trigger className="transition-all data-[state=open]:rotate-180">
          <Icon
            name="ArrowArrowNoLineDown"
            className="size-4 fill-neutral-darkest"
          />
        </Accordion.Trigger>
      </Accordion.Header>

      <Accordion.Content className="mt-2 space-y-2 overflow-hidden data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
        {data ? (
          <>
            <Field.Root>
              <Field.Label>CPF</Field.Label>
              <Field.Value>{data.BeneficiaryDocument}</Field.Value>
            </Field.Root>

            <Field.Root>
              <Field.Label>Favorecido</Field.Label>
              <Field.Value>{data.BeneficiaryName}</Field.Value>
            </Field.Root>

            {data.TransactionType === "Payment" && (
              <Field.Root>
                <Field.Label>Código de barras</Field.Label>
                <Field.Value>{data.BarCodeNumber}</Field.Value>
              </Field.Root>
            )}

            {data.TransactionType === "Pix" && (
              <Field.Root>
                <Field.Label>Chave Pix</Field.Label>
                <Field.Value>{data.PixKey}</Field.Value>
              </Field.Root>
            )}

            <Field.Root>
              <Field.Label>Banco</Field.Label>
              <Field.Value>{data.BankName}</Field.Value>
            </Field.Root>

            <Field.Root>
              <Field.Label>Agência</Field.Label>
              <Field.Value>{data.BankBranch}</Field.Value>
            </Field.Root>

            <Field.Root>
              <Field.Label>Conta</Field.Label>
              <Field.Value>
                {data.BankAccountNumber}-{data.BankAccountDigit}
              </Field.Value>
            </Field.Root>

            <Field.Root>
              <Field.Label>Data da transação</Field.Label>
              <Field.Value>
                {format(new Date(data.DateTransaction), "dd/MM/yyyy - HH:mm")}
              </Field.Value>
            </Field.Root>

            <Field.Root>
              <Field.Label>Tipo</Field.Label>
              <Field.Value>{data.TransactionType}</Field.Value>
            </Field.Root>

            <Field.Root>
              <Field.Label>Valor da transação</Field.Label>
              <Field.Value className="font-bold text-primary-main">
                - {formatCurrency(data.Amount)}
              </Field.Value>
            </Field.Root>
          </>
        ) : (
          <>
            {Array.from({ length: 9 }).map((_, index) => (
              <Field.Root key={index}>
                <div className="h-[2ex] w-[10ch] animate-pulse rounded-[2px] bg-neutral-lighter" />
                <div className="ml-auto h-[2ex] w-[10ch] animate-pulse rounded-[2px] bg-neutral-lighter" />
              </Field.Root>
            ))}
          </>
        )}
      </Accordion.Content>
    </Accordion.Item>
  );
};
