import { useState } from "react";
import {
  STATUS,
  TeamListItem,
} from "@/pages/settings/react-query/team-access-list";
import { ChangeUserSheet } from "@/pages/settings/sections/team-access/components/change-user-sheet";
import { DeleteUserModal } from "@/pages/settings/sections/team-access/components/delete-user-modal";
import * as Svg from "@/pages/settings/svgs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { getDashboardRoutesData } from "@hyperlocal/banking-utility";
import { toast } from "@hyperlocal/vital2";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

// import { useNavigate } from "react-router-dom";

interface TableOptionsProps {
  profileStatus: STATUS;
  profile: TeamListItem;
}

// const routes = getDashboardRoutesData();

type OptionActive = "DELETED_MODAL" | "CHANGE_SHEET";

export function TableOptions({ profile, profileStatus }: TableOptionsProps) {
  // const navigate = useNavigate();
  const [optionActive, setOptionActive] = useState<OptionActive | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // function handleRedirectHistory() {
  //   navigate(routes.settingsUserHistory.replace(":id", profile.id));
  // }

  async function handleResendLink() {
    try {
      setIsLoading(true);

      // TODO: Integrar back-end
      await new Promise((resolve) => setTimeout(resolve, 500));

      toast({
        variant: "success",
        title: "Link reenviado",
      });
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao re-enviar o link",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleSelectItem(option: OptionActive) {
    setOptionActive(option);
  }

  function onOpenChange(open: boolean) {
    if (open) {
      setOptionActive(null);
    }
  }

  return (
    <>
      <DropdownMenu.Root modal={false} onOpenChange={onOpenChange}>
        <DropdownMenu.Trigger
          className="rounded-xs hover:bg-neutral-lighter hover:brightness-95 data-[state=open]:bg-neutral-lighter data-[state=open]:brightness-95"
          data-testid="options-row-trigger"
        >
          <Svg.Dots />
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="min-w-32 overflow-hidden rounded-sm border border-neutral-lighter bg-neutral-white shadow-lower"
            align="end"
            sideOffset={5}
          >
            {profileStatus === "PENDING" ? (
              <DropdownMenu.Item
                className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
                onSelect={handleResendLink}
                disabled={isLoading}
              >
                <Svg.Link /> Reenviar
              </DropdownMenu.Item>
            ) : (
              <>
                {/* <DropdownMenu.Item
                className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
                onSelect={handleRedirectHistory}
              >
                <Svg.Eye /> Histórico
              </DropdownMenu.Item> */}

                <DropdownMenu.Item
                  className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
                  onSelect={() => handleSelectItem("CHANGE_SHEET")}
                >
                  <Svg.Edit /> Editar
                </DropdownMenu.Item>
              </>
            )}

            <DropdownMenu.Item
              className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
              onSelect={() => handleSelectItem("DELETED_MODAL")}
            >
              <Svg.Trash /> Excluir
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {optionActive === "CHANGE_SHEET" && (
        <ChangeUserSheet profile={profile} isOpen />
      )}

      {optionActive === "DELETED_MODAL" && (
        <DeleteUserModal profileId={profile.id} isOpen />
      )}
    </>
  );
}
