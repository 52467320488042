import { ChartData, GetChartDataResponse } from "@/services/types";
import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import apis from ".";
import { useAccountStore } from "../utils";

const MAX_LENGTH = 7;

type Reducing = {
  categories: string[];
  credit: number[];
  debit: number[];
  totalCredit: number;
  totalDebit: number;
};

const transformWeeklyResponse = (response: GetChartDataResponse): ChartData => {
  const items = response.Items;
  const result = items.reduce<Reducing>(
    (prev, cur, index) => {
      if (index <= MAX_LENGTH) {
        prev.totalCredit += cur.Credit;

        prev.totalDebit += cur.Debit;

        const date = parse(cur.Date, "yyyy-MM-dd", new Date());

        prev.categories.push(format(date, "dd/MM"));

        prev.credit.push(cur.Credit || -0.01);

        prev.debit.push(cur.Debit || -0.01);
      }

      return prev;
    },
    {
      categories: [],
      credit: [],
      debit: [],
      totalCredit: 0,
      totalDebit: 0,
    },
  );

  return {
    categories: result.categories.reverse(),
    totalCredit: result.totalCredit,
    totalDebit: result.totalDebit,
    chatData: [
      { name: "Entradas", data: result.credit.reverse() },
      { name: "Saídas", data: result.debit.reverse() },
    ],
  };
};

export const useGetWeeklyReport = () => {
  const { currentAccountId: accountId } = useAccountStore();

  return useQuery<GetChartDataResponse, Error, ChartData>({
    queryKey: ["weeklyReport", accountId],
    queryFn: () => apis.GetChartData({ accountId }),
    enabled: !!accountId,
    initialData: {
      Balance: {
        Credit: 0,
        Debit: 0,
      },
      Items: [],
      Week: { End: "", Start: "" },
    },
    select: (data) => transformWeeklyResponse(data),
  });
};
