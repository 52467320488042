import { ScrollArea } from "@/components/scroll-area";
import { getVertical } from "@/utils";
import * as Collapsible from "@radix-ui/react-collapsible";

const limits = [
  {
    title: "Tarifas",
    items: [
      {
        label: "Manutenção de conta",
        transaction: "R$ 0,00",
        note: "Por manutenção",
      },
      {
        label: "Pagamento de conta",
        transaction: "R$ 0,00",
        note: "Por pagamento",
      },
      {
        label: `Conta ${getVertical()}`,
        transaction: "R$ 0,00",
        note: "Por transferência",
      },
      {
        label: "Pix In",
        transaction: "R$ 0,00",
        note: "Por transferência",
      },
      {
        label: "Pix Out",
        transaction: "R$ 0,00",
        note: "Por transferência",
      },
      {
        label: "TED In",
        transaction: "R$ 0,00",
        note: "Por transferência",
      },
      {
        label: "TED Out",
        transaction: "R$ 7,60",
        note: "Por transferência",
      },
      {
        label: "Inatividade",
        transaction: "R$ 9,90",
        note: "Por mês",
      },
    ],
  },
] as const;

export function Fees() {
  return (
    <div className="-mr-4 flex h-full flex-col overflow-hidden">
      <ScrollArea>
        <div className="space-y-2 p-px pr-4">
          {limits.map((limit) => (
            <Collapsible.Root
              key={limit.title}
              className="overflow-hidden rounded-b-sm rounded-t-sm bg-neutral-white text-neutral-darkest shadow-border shadow-neutral-lighter"
              open
            >
              <Collapsible.Trigger className="group grid w-full grid-cols-[2fr_1fr_1fr_24px] items-center gap-2 p-4 text-start text-xs/md font-bold transition-all duration-200 data-[state=open]:bg-primary-lighter data-[state=open]:text-primary-main mobile:!grid-cols-[1.5fr_2fr_2fr_24px] mobile:!text-x2s/default">
                <span className="truncate">{limit.title}</span>
                <span className="truncate">Transação</span>
                <span className="truncate">Observação</span>
              </Collapsible.Trigger>

              <Collapsible.Content className="space-y-1 overflow-hidden data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down">
                {limit.items.map((item) => (
                  <div
                    key={item.label}
                    className="grid grid-cols-[2fr_1fr_1fr_24px] items-center gap-2 text-nowrap p-4 text-start text-xs/md font-regular text-neutral-darkest mobile:!grid-cols-[1.5fr_2fr_2fr_24px] mobile:!text-x2s/md"
                  >
                    <span className="truncate text-wrap font-bold">
                      {item.label}
                    </span>
                    <span className="truncate">{item.transaction}</span>
                    <span className="truncate">{item.note}</span>
                  </div>
                ))}
              </Collapsible.Content>
            </Collapsible.Root>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}
