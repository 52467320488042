import { useRef } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { VerticalLogo } from "@/components/vertical-logo";
import {
  GetPendingTransactionByIdResponse,
  TransactionType,
} from "@/services/types/get-pending-transactions";
import { formatCurrency, getVerticalBankName, usePrint } from "@/utils";
import { Button, Sheet } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const descriptionStyles = tv({
  slots: {
    definitionTerm:
      "font-base font-medium text-neutral-darkest text-xs/default w-fit",
    definitionDetail: "font-base text-neutral-dark text-xs/default text-end",
    definitionContainer: "flex justify-between my-4 gap-4 w-full flex-wrap",
  },
});

const getReceiptData = ({
  receipt,
}: {
  receipt: GetPendingTransactionByIdResponse;
}) => {
  const defaultData = {
    "Data da transação": new Intl.DateTimeFormat("pt-BR").format(
      new Date(receipt.DateTransaction),
    ),
    Valor: formatCurrency(receipt.Amount),
    Favorecido: receipt.BeneficiaryName,
  };

  const receiptData: Record<TransactionType, Record<string, string>> = {
    P2P: {
      ...defaultData,
      Agência: receipt.BankBranch,
      Conta: `${receipt.BankAccountNumber}-${receipt.BankAccountDigit}`,
    },
    TED: {
      ...defaultData,
      Banco: `${receipt.BankCode} - ${receipt.BankName}`,
      Agência: receipt.BankBranch,
      Conta: `${receipt.BankAccountNumber} - ${receipt.BankAccountDigit}`,
    },
    Pix: {
      ...defaultData,
      "Chave pix": receipt.PixKey,
    },
    Payment: {
      ...defaultData,
    },
  };

  return receiptData[receipt.TransactionType] || defaultData;
};

const receiptInfo: Record<TransactionType, string> = {
  TED: "Transferência TED",
  Pix: "Transferência Pix",
  P2P: `Transferência ${getVerticalBankName()}`,
  Payment: "Pagamento de boleto",
};

export const SummaryReceipt = ({
  receipt,
}: {
  receipt: GetPendingTransactionByIdResponse;
}) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  return (
    <Sheet.Root>
      <Sheet.Trigger asChild>
        <Button.Root>Ver comprovante</Button.Root>
      </Sheet.Trigger>
      <Sheet.Content className="px-0">
        <Sheet.Header className="px-6">
          <Sheet.Title>Comprovante</Sheet.Title>
        </Sheet.Header>
        <div
          className="mb-6 flex w-full flex-col items-center overflow-hidden"
          ref={componentRef}
        >
          <ScrollArea className="flex h-full w-full flex-col px-6">
            <VerticalLogo />
            <div className="flex w-full flex-col items-center overflow-hidden">
              <h5 className="mb-6 font-base text-sm/md font-bold text-neutral-darkest">
                {receiptInfo[receipt.TransactionType]}
              </h5>
              <div className="flex h-full w-full flex-col overflow-hidden">
                <hr className="h-[1px] w-full bg-neutral-light" />
                {Object.entries(getReceiptData({ receipt })).map(
                  ([key, value]) => (
                    <dl key={key} className={definitionContainer()}>
                      <dt className={definitionTerm()}>{key}</dt>
                      <dd className={twMerge(definitionDetail())}>{value}</dd>
                    </dl>
                  ),
                )}

                <hr className="h-[1px] w-full bg-neutral-light" />
                <dl className="mt-4 w-full">
                  <dt className={definitionTerm()}>Id da Operação</dt>
                  <dd
                    className={twMerge(
                      definitionDetail(),
                      "mt-1 break-all text-start",
                    )}
                  >
                    {receipt.TransactionId}
                  </dd>
                </dl>
              </div>
            </div>
          </ScrollArea>
        </div>
        <Button.Root className="m-6 mt-auto" onClick={handlePrint}>
          <Button.Slot name="GeralPrint" />
          Imprimir comprovante
        </Button.Root>
      </Sheet.Content>
    </Sheet.Root>
  );
};
