import React from "react";
import Icon from "@hyperlocal/vital-icons";
import { useTheme } from "styled-components";
import * as Styles from "./styles";
import * as Types from "./types";

export const ContentList: React.FC<Types.IDrawerContentList> = ({
  options,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Styles.Container hasMarginTop={props.hasMarginTop}>
      <Styles.Description>{props?.description}</Styles.Description>
      {options?.map((option) => (
        <Styles.Option
          key={option?.text}
          onClick={option.action ? () => option.action() : null}
        >
          <Icon name={option?.icon} color={theme.palette.neutral.darkest} />
          <Styles.TextWraper>
            <Styles.OptionText>{option?.text}</Styles.OptionText>
            <Styles.SubTitle>{option?.subTitle}</Styles.SubTitle>
          </Styles.TextWraper>
          <Icon
            name="ArrowArrowNoLineRight"
            color={theme.palette.neutral.darkest}
          />
        </Styles.Option>
      ))}
    </Styles.Container>
  );
};

export default ContentList;
