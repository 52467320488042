import { createContext, useContext, useMemo, useReducer } from "react";
import { Action, Context, State } from "./team-access-list-filter.types";

export const initialState: State = {
  selectedExcluded: false,
  pageNumber: 1,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_SELECTED_EXCLUDED":
      return {
        ...state,
        selectedExcluded: action.payload.selectedExcluded,
        pageNumber: 1,
      };
    case "SET_PAGE_NUMBER":
      return { ...state, pageNumber: action.payload.number };
    case "SET_NEXT_PAGE_NUMBER":
      return { ...state, pageNumber: state.pageNumber + 1 };
    case "SET_PREV_PAGE_NUMBER":
      return { ...state, pageNumber: state.pageNumber - 1 };
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

export const TeamAccessListFilterContext = createContext<Context>({
  filterState: initialState,
  dispatchFilter: () => null,
});

export function ProductListFilterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ filterState, dispatchFilter }),
    [filterState, dispatchFilter],
  );

  return (
    <TeamAccessListFilterContext.Provider value={value}>
      {children}
    </TeamAccessListFilterContext.Provider>
  );
}

export function useTeamAccessListFilter() {
  const context = useContext(TeamAccessListFilterContext);

  if (!context) {
    throw new Error(
      "TeamAccessListFilterContext must be used within TeamAccessListFilterProvider",
    );
  }

  return context;
}
