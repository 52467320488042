import { PendingTransaction } from "@/services/types/get-pending-transactions";
import { format } from "date-fns";

export const groupByDate = (arr: PendingTransaction[]) => {
  return arr.reduce(
    (acc, transaction) => {
      const dateOnly = format(
        new Date(transaction.DateTransaction),
        "yyyy/MM/dd",
      );

      let dateGroup = acc.find((group) => group.date === dateOnly);

      if (!dateGroup) {
        dateGroup = { date: dateOnly, items: [] };
        acc.push(dateGroup);
      }

      dateGroup.items.push(transaction);

      return acc;
    },
    [] as { date: string; items: PendingTransaction[] }[],
  );
};
