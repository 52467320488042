import { Filter } from "@/assets/svgs";
import { FiltersContent } from "@/pages/pending-transactions/tabs/history/components/filters-content";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Button, Sheet, TextField } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";

export const Filters = () => {
  const [params, setParams] = useSearchParams();
  const isMobile = Hooks.useMediaQuery("mobile");

  const hasFilters = [
    "startDate",
    "endDate",
    "transactionType",
    "transactionStatus",
  ].some((key) => params.get(key));

  return (
    <div className="flex gap-4 mobile:flex-col-reverse mobile:p-[1px]">
      <TextField.Root className="flex-1">
        <TextField.Input
          placeholder="Buscar por tipo de transação e destinatário"
          onChange={({ target: { value } }) => {
            if (value) {
              return setParams((params) => {
                params.set("query", value);
                return params;
              });
            }

            return setParams((params) => {
              params.delete("query");
              return params;
            });
          }}
          value={params.get("query")}
        />
        <TextField.Slot name="SearchNormal" />
      </TextField.Root>

      {isMobile ? (
        <div>
          <Button.Root
            className="mobile:!h-8 mobile:!px-2"
            variant={hasFilters ? "primary" : "secondary"}
            onClick={() => {
              setParams((params) => {
                params.set("showFilters", "true");
                return params;
              });
            }}
          >
            <Filter
              className={hasFilters ? "text-white" : "text-primary-main"}
            />
            Filtrar lançamentos
          </Button.Root>
        </div>
      ) : (
        <Sheet.Root>
          <Sheet.Trigger>
            <Button.Root
              className="mobile:h-8 mobile:px-2"
              variant={hasFilters ? "primary" : "secondary"}
            >
              <Filter
                className={
                  hasFilters ? "size-6 text-white" : "size-6 text-primary-main"
                }
              />
              Filtrar lançamentos
            </Button.Root>
          </Sheet.Trigger>

          <Sheet.Content>
            <Sheet.Header>
              <Sheet.Title className="text-md text-neutral-darkest">
                Filtrar lançamentos
              </Sheet.Title>
            </Sheet.Header>

            <FiltersContent />
          </Sheet.Content>
        </Sheet.Root>
      )}
    </div>
  );
};
