import React, { useEffect } from "react";
import { useLogin } from "@/contexts/login";
import { getErrorTranslated } from "@/formatters/cognito";
import {
  confirmSignInWithCustomChallenge,
  getMFAActive,
  handleSetupTOTP,
} from "@/pages/login/login";
import {
  getDashboardRoutesData,
  getVerticalAccountId,
  signIn,
  signOut,
  useAccountStore,
} from "@/utils";
import { Button, TextField, toast } from "@hyperlocal/vital2";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export function SignIn() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSendingForm, setIsSendingForm] = React.useState(false);
  const accountStore = useAccountStore((store) => ({
    clear: store.clear,
    accountId: store.currentAccountId,
  }));

  const { filterState, dispatchFilter } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: filterState.email,
      password: filterState.password,
    },
  });

  useEffect(() => {
    if (accountStore.accountId && getVerticalAccountId()) {
      accountStore.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setTotpSetupURL(URL: string, secret: string) {
    dispatchFilter({ type: "SET_TOTP_SETUP", payload: { URL, secret } });
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function handleSignIn(data) {
    try {
      setIsSendingForm(true);
      await signOut();
      const { nextStep } = await signIn({
        email: data.email,
        password: data.password,
      });

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
        dispatchFilter({ type: "SET_STEP", payload: { step: "confirmTotp" } });
      }

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
        await confirmSignInWithCustomChallenge(
          nextStep.additionalInfo.accountsJson,
        );

        const isMFAActive = await getMFAActive();

        if (!isMFAActive) {
          await handleSetupTOTP(setTotpSetupURL, data.email);
        }
      }
    } catch (error) {
      toast({
        variant: "error",
        title: "Houve um erro ao fazer login",
        description: getErrorTranslated(error.message),
      });
    } finally {
      setIsSendingForm(false);
    }
  }

  return (
    <>
      <h3 className="mb-8 text-lg/default font-bold text-neutral-darkest mobile:!text-sm/default">
        Login
      </h3>

      <form onSubmit={handleSubmit(handleSignIn)}>
        <div>
          <TextField.Label htmlFor="email" className="mobile:!text-x2s/default">
            E-mail
          </TextField.Label>
          <TextField.Input
            id="e-mail"
            type="text"
            placeholder="Digite seu email"
            {...register("email", {
              required: "Email é obrigatório",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Email inválido",
              },
              onChange: (event) => {
                const emailWithoutSpaces = event.target.value.replace(
                  /\s+/g,
                  "",
                );
                dispatchFilter({
                  type: "SET_EMAIL",
                  payload: { email: emailWithoutSpaces },
                });
              },
            })}
          />

          {errors.email && (
            <TextField.Helper className="text-status-error-dark">
              {errors.email.message}
            </TextField.Helper>
          )}

          <TextField.Label
            htmlFor="password"
            className="mt-8 mobile:!text-x2s/default"
          >
            Senha
          </TextField.Label>
          <TextField.Root>
            <TextField.Input
              id="password"
              placeholder="Digite sua senha"
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Senha é obrigatória",
                onChange: (event) => {
                  dispatchFilter({
                    type: "SET_PASSWORD",
                    payload: { password: event.target.value },
                  });
                },
              })}
            />
            <button
              type="button"
              aria-label="show-confirm-new-password"
              onClick={() => handleTogglePasswordVisibility()}
            >
              <TextField.Slot
                name={showPassword ? "ComputersEye" : "ComputersEyeSlash"}
              />
            </button>
          </TextField.Root>

          {errors.password && (
            <TextField.Helper className="text-status-error-dark">
              {errors.password.message}
            </TextField.Helper>
          )}

          <Link
            to={getDashboardRoutesData().sessionResetPassword}
            className="block h-stack-md py-2.5 font-medium text-primary-main underline transition duration-300 hover:text-primary-dark"
          >
            Esqueci minha senha
          </Link>
        </div>

        <Button.Root
          className="mt-10 w-full mobile:!mt-auto"
          type="submit"
          isLoading={isSendingForm}
          disabled={!isValid && Object.keys(errors).length > 0}
        >
          Entrar
        </Button.Root>
      </form>
    </>
  );
}
