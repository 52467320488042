import { useEffect, useState } from "react";
import { Breadcrumb } from "@/components/breadcrumb";
import { DocumentViewer } from "@/components/document-viewer";
import { DocumentsList } from "@/pages/contracts/documents-list";
import { getDashboardRoutesData, Hooks, setHeader } from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { toast } from "@hyperlocal/vital2";
import { useNavigate } from "react-router-dom";
import privacyPolicies from "../../assets/politica_de_privacidade.pdf";
import termsOfUse from "../../assets/termos_de_uso.pdf";
import { Document } from "./types";

const staticDocuments: Document[] = [
  {
    title: "Política de privacidade",
    url: privacyPolicies,
  },
  {
    title: "Termos de uso",
    url: termsOfUse,
  },
];

export function Contracts() {
  const navigate = useNavigate();
  const isMobile = Hooks.useMediaQuery("mobile");
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    staticDocuments[0],
  );

  const handleDownload = async (fileUrl: string, filename: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      filename = filename.toLowerCase().replace(/[^a-z0-9]/gi, "_");
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
      toast({
        title: "Contrato baixado",
        variant: "inform",
        icon: <Icon name="Check" className="h-6 w-6" />,
      });
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
      toast({
        title: "Erro",
        description: "Ocorreu um erro ao baixar o arquivo",
        variant: "error",
      });
    }
  };

  const handleSelectPdf = (document: Document) => {
    setSelectedDocument(document);
  };

  useEffect(() => {
    setHeader({
      title: "Contratos",
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigate(-1),
      },
      show: true,
    });
  }, [navigate]);

  return (
    <div className="flex h-full w-full flex-1 flex-col bg-neutral-lighter mobile:max-h-[100vh] mobile:bg-neutral-white tablet:max-h-[100vh] tablet:bg-neutral-white">
      <div className="mx-auto flex w-full max-w-[1248px] flex-1 flex-col gap-8 mobile:!overflow-hidden desktop:my-inset-lg desktop:px-16">
        {!isMobile && (
          <header>
            <Breadcrumb
              breadcrumbs={[
                {
                  name: "Contratos",
                  path: "contracts",
                  active:
                    location.pathname === getDashboardRoutesData().contracts,
                },
              ]}
            />
          </header>
        )}
        <section
          role="region"
          aria-labelledby="section-title"
          className="flex flex-col gap-8"
        >
          {!isMobile && (
            <div className="flex items-center gap-2 text-neutral-darkest">
              <button type="button" onClick={() => navigate(-1)}>
                <Icon
                  name="ArrowArrowNoLineLeft"
                  className="h-6 w-6 fill-current"
                />
              </button>
              <h3 id="section-title" className="font-base text-lg/8 font-bold">
                Contratos
              </h3>
            </div>
          )}

          <div className="flex h-full w-full gap-4 rounded-sm bg-neutral-white p-6 mobile:flex-col mobile:px-4">
            <div className="flex w-full flex-1 flex-col gap-4">
              <h5 className="font-base text-xs/default font-medium text-primary-main">
                Documentos assinados
              </h5>
              <DocumentsList
                documents={staticDocuments}
                selectedDocument={selectedDocument}
                onSelectDocument={handleSelectPdf}
                onDownloadDocument={handleDownload}
              />
            </div>
            <DocumentViewer
              selectedPdf={{
                title: selectedDocument?.title,
                url: `${selectedDocument?.url}#toolbar=0&navpanes=0`,
              }}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
