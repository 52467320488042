import { SalesListResponse, Transaction } from "@/services/types/get-sales";
import {
  formatToInputDate,
  handleNormalizeDate,
  useAccountStore,
} from "@/utils";
import { TODAY_INPUT } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import { sub } from "date-fns";
import apis from "../../services";

const END_DATE_RANGE = 7;

const endDate = TODAY_INPUT;

const getStartDate = () => {
  const normalizedDate = handleNormalizeDate(TODAY_INPUT);
  const startDate = sub(normalizedDate, { days: END_DATE_RANGE });
  return formatToInputDate(startDate);
};

function formatTransactionType(
  transactionType: string,
  creditCardCompany: string,
) {
  switch (transactionType) {
    case "PARCELAMENTO SEM JUROS":
    case "CREDITO A VISTA":
      return "Crédito";
    case "DEBITO":
      return creditCardCompany === "PIX" ? "Pix" : "Débito";
  }
}

function formatTransactions(sales: SalesListResponse): Transaction[] {
  const transactions = sales.transacoes.reduce(
    (acc, transaction) => {
      const date = transaction.transaction_date.split("T")[0];
      const transactionType = formatTransactionType(
        transaction.transaction_type,
        transaction.credit_card_company,
      );
      const amount =
        transaction.transaction_status === "ESTORNADO"
          ? transaction.total_value * -1
          : transaction.total_value;

      const key = `${date}_${transactionType}`;
      if (acc[key]) {
        acc[key].amount += amount;
        acc[key].transactions.push(transaction);
      } else {
        acc[key] = { date, amount, transactions: [transaction] };
      }
      return acc;
    },
    {} as { [key: string]: Transaction },
  );

  const result = Object.values(transactions);

  return result;
}

export const useSales = () => {
  const { currentAccountId } = useAccountStore();

  return useQuery({
    queryKey: ["getSales", currentAccountId],
    queryFn: () =>
      apis.getSales({
        guidAccount: currentAccountId,
        endDate,
        startDate: getStartDate(),
      }),
    select: (data) => ({
      aprovadas: data.aprovadas,
      estornadas: data.estornadas,
      pendentes: data.pendentes,
      transacoes: formatTransactions(data),
    }),
    throwOnError: true,
  });
};
