import { useEffect } from "react";
import { usePendingTransactions } from "@/pages/pending-transactions/common";
import { Summary } from "@/pages/pending-transactions/tabs/pending/components/summary";
import {
  getDashboardRoutesData,
  Hooks,
  setHeader,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useNavigate } from "react-router-dom";

export type Tab = "pendentes" | "historico";

export const PendingTransactionsDetails = () => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();

  const { data, isLoading } = usePendingTransactions();

  useEffect(() => {
    isMobile &&
      setHeader({
        title: "Lançamentos",
        rightIcon: {
          name: "GeralClose",
          onClick: () => navigate(getDashboardRoutesData().pendingTransactions),
        },
      });
  }, [isMobile, navigate]);

  if (!data || isLoading) return <></>;

  return <Summary data={data} />;
};
