import { ComponentProps } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { ChangeAddressSheet } from "@/pages/settings/sections/account-data/components/change-address-sheet";
import { formatDocument } from "@/utils";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Account, useAccountStore } from "@hyperlocal/banking-utility";
import { Divider } from "@hyperlocal/vital";
import { twJoin } from "tailwind-merge";

function Title({ className, ...props }: ComponentProps<"h5">) {
  return (
    <h5
      className={twJoin(
        "text-sm/md font-bold text-neutral-darkest mobile:!text-xs/md",
        className,
      )}
      {...props}
    />
  );
}

function SubTitle({ className, ...props }: ComponentProps<"span">) {
  return (
    <span
      className={twJoin(
        "block whitespace-nowrap text-xs/md font-bold text-neutral-darker mobile:!text-x2s/default mobile:!font-medium mobile:!text-neutral-darkest",
        className,
      )}
      {...props}
    />
  );
}

function Text({ className, ...props }: ComponentProps<"span">) {
  return (
    <span
      className={twJoin(
        "block truncate text-xs/md text-neutral-darkest mobile:!text-x2s/lg",
        className,
      )}
      {...props}
    />
  );
}

function Wrapper({ className, ...props }: ComponentProps<"div">) {
  return (
    <div
      className={twJoin(
        "flex flex-col gap-2 mobile:!flex-row mobile:!items-center mobile:!justify-between mobile:!gap-8",
        className,
      )}
      {...props}
    />
  );
}

type ProvisoryCompanyInfoResponse = {
  name: string;
  document: string;
};

const getInfo = (account: Account) => {
  const { contact, document, email, name } =
    "companyInfoResponse" in account
      ? account.representativeInfoResponse[0]
      : account.personInfoResponse;
  return { contact, document, email, name };
};

export function AccountData() {
  const { account } = useAccountStore();

  const { contact, document, email, name } = getInfo(account);

  return (
    <div className="-mr-6 flex h-full flex-col mobile:!-mr-4">
      <ScrollArea>
        <div className="pr-6 mobile:!pr-4">
          {"companyInfoResponse" in account && (
            <>
              <Title>Dados da empresa</Title>
              <div className="mt-4 grid grid-cols-2 gap-4 mobile:!grid-cols-1 mobile:!gap-2">
                <Wrapper>
                  <SubTitle>Razão Social:</SubTitle>
                  <Text>
                    {
                      (
                        account.companyInfoResponse as ProvisoryCompanyInfoResponse
                      ).name
                    }
                  </Text>
                </Wrapper>

                <Wrapper>
                  <SubTitle>CNPJ:</SubTitle>
                  <Text>
                    {formatDocument(
                      (
                        account.companyInfoResponse as ProvisoryCompanyInfoResponse
                      ).document,
                    )}
                  </Text>
                </Wrapper>
              </div>

              <Divider className="my-4" />
            </>
          )}

          <div className="flex items-center justify-between">
            <Title>Dados do responsável</Title>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4 mobile:!grid-cols-1 mobile:!gap-2">
            <Wrapper>
              <SubTitle>Nome usuário:</SubTitle>
              <Text>{name}</Text>
            </Wrapper>

            <Wrapper>
              <SubTitle>CPF:</SubTitle>
              <Text>{formatDocument(document)}</Text>
            </Wrapper>

            <Wrapper>
              <SubTitle>E-mail:</SubTitle>
              <Text>{email}</Text>
            </Wrapper>

            <Wrapper>
              <SubTitle>Contato:</SubTitle>
              <Text>{contact}</Text>
            </Wrapper>
          </div>

          <Divider className="!my-4" />

          <div className="flex items-center justify-between">
            <Title>Dados de endereço</Title>
            <ChangeAddressSheet />
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4 mobile:!grid-cols-1 mobile:!gap-2">
            <Wrapper>
              <SubTitle>CEP:</SubTitle>
              <Text>{account.addressInfoResponse.cep}</Text>
            </Wrapper>

            <Wrapper>
              <SubTitle>Bairro:</SubTitle>
              <Text>{account.addressInfoResponse.district}</Text>
            </Wrapper>

            <Wrapper>
              <SubTitle>Endereço:</SubTitle>
              <Text>{account.addressInfoResponse.street}</Text>
            </Wrapper>

            <Wrapper>
              <SubTitle>Nº:</SubTitle>
              <Text>{account.addressInfoResponse.number}</Text>
            </Wrapper>

            <div className="flex gap-4 mobile:!flex-col mobile:!gap-2">
              <Wrapper className="w-20 mobile:!w-auto">
                <SubTitle>UF:</SubTitle>
                <Text>{account.addressInfoResponse.uf}</Text>
              </Wrapper>

              <Wrapper>
                <SubTitle>Cidade:</SubTitle>
                <Text>{account.addressInfoResponse.city}</Text>
              </Wrapper>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}
