import { ScrollArea } from "@/components/scroll-area";
import { useTeamAccessListFilter } from "@/pages/settings/contexts/team-access-filter";
import {
  getFormattedStatus,
  TeamListItem,
} from "@/pages/settings/react-query/team-access-list";
import { TableOptions } from "@/pages/settings/sections/team-access/components/table-options";
import * as Svg from "@/pages/settings/svgs";
import { editEquipAccess } from "@/utils/allowed-roles";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { hasPermission } from "@hyperlocal/banking-utility";
import { Avatar, Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

function EmptyList() {
  const { filterState, dispatchFilter } = useTeamAccessListFilter();

  function handleCleanFilters() {
    dispatchFilter({ type: "CLEAR_FILTERS" });
  }

  const hasFilter =
    filterState.selectedExcluded || filterState.pageNumber !== 1;

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2">
      <span className="text-center text-xs/md text-neutral-main">
        {hasFilter
          ? "Você não possui usuários nesse filtro."
          : "Ops! Você ainda não possui usuários cadastrados."}
      </span>

      {hasFilter && (
        <Button.Root variant="link" onClick={handleCleanFilters}>
          Remover filtros
        </Button.Root>
      )}
    </div>
  );
}

const SKELETON_LENGTH = 7;

function TableSkeleton() {
  return (
    <div
      data-testid="table-skeleton"
      className="grid animate-pulse grid-cols-[200px_auto_90px_24px] items-center gap-4 p-4 shadow-lower mobile:!grid-cols-[auto_90px_24px] mobile:!gap-2 mobile:!p-2"
    >
      <div className="flex items-center gap-2 mobile:!w-2/3">
        <div className="block size-10 rounded-circle bg-neutral-lighter" />

        <div className="flex flex-1 flex-col gap-0.5 mobile:!gap-1">
          <div className="block h-[22px] w-full rounded-sm bg-neutral-lighter mobile:!h-[24px]" />
          <div className="block h-[21px] w-full rounded-sm bg-neutral-lighter mobile:!h-[18px]" />
        </div>
      </div>

      <div className="block h-6 w-full rounded-sm bg-neutral-lighter mobile:!hidden" />
      {/* <div className="block h-6 w-full rounded-sm bg-neutral-lighter mobile:!hidden" /> */}
      <div className="block h-6 w-4/5 rounded-sm bg-neutral-lighter mobile:!justify-self-end" />
      <div className="block h-6 w-full rounded-sm bg-neutral-lighter" />
    </div>
  );
}

export function getInitials(name: string) {
  if (name.includes(" ")) {
    const splitName = name.split(" ");
    return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`.toUpperCase();
  }

  return `${name[0]}${name[1]}`.toUpperCase();
}

// const routes = getDashboardRoutesData();

type TableProps = {
  pageData: TeamListItem[];
  isLoading: boolean;
};

export function Table({ pageData, isLoading }: TableProps) {
  const isEmpty = !pageData || pageData.length === 0;

  if (!isLoading && isEmpty) return <EmptyList />;

  return (
    <ScrollArea>
      <div className="space-y-1 pr-6 mobile:!pr-0">
        {isLoading
          ? Array.from({ length: SKELETON_LENGTH }).map((_, index) => (
              <TableSkeleton key={index} />
            ))
          : pageData.map((profile) => (
              <div
                key={profile.id}
                className="grid grid-cols-[200px_auto_90px_24px] items-center gap-4 p-4 pl-3.5 shadow-lower mobile:!grid-cols-[auto_90px_24px] mobile:!gap-2 mobile:!p-2 mobile:!pl-1.5"
              >
                <div className="flex items-center gap-2 overflow-hidden pl-0.5">
                  <Avatar.Root
                    size="md"
                    variant={profile.photoURL ? "primary" : "secondary"}
                    className="shrink-0"
                  >
                    {profile.photoURL ? (
                      <Avatar.Image
                        src={profile.photoURL}
                        alt={`${profile.name} foto de perfil`}
                      />
                    ) : (
                      <Avatar.Fallback>
                        {getInitials(profile.name)}
                      </Avatar.Fallback>
                    )}
                  </Avatar.Root>

                  <div className="flex flex-col overflow-hidden mobile:!gap-1">
                    <span className="truncate text-xs/md text-neutral-darkest">
                      {profile.name}
                    </span>
                    <span className="text-x2s/md text-primary-main mobile:!text-x3s/md mobile:!font-medium">
                      {profile.role}
                    </span>
                  </div>
                </div>

                <span className="truncate text-xs/md text-neutral-black mobile:!hidden">
                  {profile.email}
                </span>

                {/* <span className="text-xs/md tabular-nums text-neutral-black mobile:!hidden">
                  {profile.createdAt ? (
                    new Date(profile.createdAt).toLocaleDateString()
                  ) : (
                    <span className="opacity-30">
                      --/--/----
                      </span>
                  )}
                </span> */}

                <span
                  data-testid="table-status-item"
                  className={twMerge(
                    "block w-fit rounded-xs px-2 py-1 text-xs/default font-medium mobile:!justify-self-end mobile:!py-[5px] mobile:!text-x2s/default",
                    profile.status === "ACTIVE" &&
                      "border border-status-success-dark bg-status-success-light text-status-success-dark",
                    ["EXPIRED", "DELETED"].includes(profile.status) &&
                      "border border-status-error-dark bg-status-error-light text-status-error-dark",
                    profile.status === "PENDING" &&
                      "border border-status-warning-dark bg-status-warning-light text-status-warning-dark",
                  )}
                >
                  {getFormattedStatus(profile.status)}
                </span>

                {profile.status === "DELETED" ? (
                  <div
                    className="rounded-xs opacity-30"
                    // to={routes.settingsUserHistory.replace(":id", profile.id)}
                  >
                    <Svg.Eye />
                  </div>
                ) : hasPermission(editEquipAccess) ? (
                  <TableOptions
                    profile={profile}
                    profileStatus={profile.status}
                  />
                ) : (
                  <div />
                )}
              </div>
            ))}
      </div>
    </ScrollArea>
  );
}
