import { Suspense, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from "@/components/breadcrumb";
import { DashboardContainer } from "@/components/dashboard-container";
import { ErrorFallback } from "@/components/error-fallback";
import { History } from "@/pages/pending-transactions/tabs/history";
import { FiltersContent } from "@/pages/pending-transactions/tabs/history/components/filters-content";
import {
  Pending,
  PendingLoading,
} from "@/pages/pending-transactions/tabs/pending";
import * as Svg from "@/pages/settings/svgs";
import { getDashboardRoutesData, Hooks, setHeader, Types } from "@/utils";
import {
  Link,
  NavigateFunction,
  SetURLSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";

const breadcrumbs: BreadcrumbItem[] = [
  { name: "Lançamentos pendentes", path: "pendingTransactions", active: true },
];

export type Tab = "pendentes" | "historico";

const routes = getDashboardRoutesData();

type LookupState = {
  isMobile: boolean;
  showFilters: boolean;
  activeTransaction: boolean;
};

type LookupFn = {
  setSearchParams: SetURLSearchParams;
  navigate: NavigateFunction;
};

const getFilterHeader = ({ setSearchParams }: LookupFn): Types["IHeader"] => ({
  title: "Filtrar lançamentos",
  rightIcon: {
    name: "GeralClose",
    onClick: () => {
      setSearchParams((params) => {
        params.delete("showFilters");
        return params;
      });
    },
  },
});

const getTransactionHeader = ({
  setSearchParams,
}: LookupFn): Types["IHeader"] => ({
  title: "Lançamento",
  rightIcon: {
    name: "GeralClose",
    onClick: () =>
      setSearchParams((params) => {
        params.delete("transaction");
        return params;
      }),
  },
});

const getDefaultHeader = ({ navigate }: LookupFn): Types["IHeader"] => ({
  title: "Lançamentos pendentes",
  leftIcon: {
    name: "ArrowArrowNoLineLeft",
    onClick: () => navigate(getDashboardRoutesData().dashboard),
  },
});

const headerLookup = [
  {
    condition: (state: LookupState) => state.isMobile && state.showFilters,
    getHeader: getFilterHeader,
  },
  {
    condition: (state: LookupState) =>
      state.isMobile && state.activeTransaction,
    getHeader: getTransactionHeader,
  },
  { condition: () => true, getHeader: getDefaultHeader },
];

export const PendingTransactions = () => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const activeParam = (searchParams.get("tab") || "pendentes") as Tab;
  const activeTransaction = searchParams.get("transaction");
  const showFilters = searchParams.get("showFilters") === "true";

  function handleChangeTabParam(tab: Tab) {
    setSearchParams(`tab=${tab}`);
  }

  const tabs: Array<{
    label: string;
    key: Tab;
    component: JSX.Element;
  }> = [
    {
      label: "Pendentes",
      key: "pendentes",
      component: (
        <ErrorFallback>
          <Suspense fallback={<PendingLoading />}>
            <Pending />
          </Suspense>
        </ErrorFallback>
      ),
    },
    {
      label: "Histórico",
      key: "historico",
      component: <History />,
    },
  ];

  const activeTab = tabs.find((tab) => tab.key === activeParam);

  useEffect(() => {
    const state: LookupState = {
      isMobile,
      showFilters,
      activeTransaction: !!activeTransaction,
    };

    const { getHeader } = headerLookup.find(({ condition }) =>
      condition(state),
    );

    const header = getHeader({
      navigate,
      setSearchParams,
    });

    setHeader(header);
  }, [activeTransaction, isMobile, navigate, setSearchParams, showFilters]);

  const shouldHideTab =
    activeParam === "historico" && !!activeTransaction && isMobile;

  if (isMobile) {
    if (showFilters) {
      return <FiltersContent />;
    }
  }

  return (
    <div className="h-full overflow-y-auto bg-neutral-lighter font-base mobile:!bg-neutral-white">
      <DashboardContainer>
        <header>
          <Breadcrumb breadcrumbs={breadcrumbs} />

          {!isMobile && (
            <div className="mt-4 flex items-center justify-between">
              <Link
                className="flex w-fit items-center gap-2 py-2 mobile:!m-4"
                to={routes.dashboard}
              >
                <Svg.ArrowDown />
                <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm">
                  Lançamentos pendentes
                </h3>
              </Link>
            </div>
          )}
        </header>

        <div className="flex mobile:w-full desktop:mt-4">
          {tabs.map(({ key, label }) => {
            const active = activeTab.key === key;

            return (
              <div
                className={twMerge(
                  "flex h-[42px] cursor-pointer items-center justify-center border-b-2 border-transparent font-bold text-neutral-darker mobile:w-1/2 desktop:w-[186px]",
                  active && "border-primary-main text-primary-main",
                  shouldHideTab && "hidden",
                )}
                key={key}
                onClick={() => handleChangeTabParam(key)}
              >
                {label}
              </div>
            );
          })}
        </div>
        {activeTab.component}
      </DashboardContainer>
    </div>
  );
};
