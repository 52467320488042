import { ScrollArea } from "@/components/scroll-area";
import { Role } from "@/pages/settings/sections/team-access/new-access/components/new-user-form";
import * as Svg from "@/pages/settings/svgs";
import * as Collapsible from "@radix-ui/react-collapsible";
import { twJoin } from "tailwind-merge";

type Access = Role | "All";

type PermissionsList = ({
  scope: string;
} & (
  | { access: Access[] }
  | {
      items: {
        name: string;
        access: Access[] | { read: Access[]; write: Access[] };
      }[];
    }
))[];

const PERMISSIONS_LIST: PermissionsList = [
  {
    scope: "Dashboard",
    access: ["All"],
  },
  {
    scope: "Extrato",
    access: ["All"],
  },
  {
    scope: "Pagar",
    items: [
      {
        name: "Boleto",
        access: ["Administrador", "Moderador", "Financeiro"],
      },
      {
        name: "Pix Copia e cola",
        access: ["Administrador", "Moderador", "Financeiro"],
      },
    ],
  },
  {
    scope: "Pix",
    items: [
      {
        name: "Dashboard",
        access: ["Administrador", "Moderador", "Analista"],
      },
      {
        name: "Estornar Pix",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Contestar Pix",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Pix Copia e cola",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Cobrar (Pix)",
        access: ["Administrador", "Moderador", "Financeiro"],
      },
      {
        name: "Contestação",
        access: {
          read: ["Administrador", "Moderador"],
          write: ["Administrador"],
        },
      },
      {
        name: "Minhas chaves",
        access: {
          read: ["Administrador", "Moderador", "Financeiro"],
          write: ["Administrador", "Moderador"],
        },
      },
      {
        name: "Cadastrar Chave",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Deletar chave Pix",
        access: ["Administrador", "Moderador"],
      },
    ],
  },
  {
    scope: "Transferir",
    items: [
      {
        name: "TED / P2P",
        access: ["Administrador", "Moderador", "Financeiro"],
      },
      {
        name: "Pix",
        access: ["Administrador", "Moderador", "Financeiro"],
      },
    ],
  },
  {
    scope: "Cobrança (bank)",
    items: [
      {
        name: "Dashboard",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Gerar cobrança",
        access: {
          read: ["Administrador", "Moderador", "Financeiro"],
          write: ["Administrador", "Moderador"],
        },
      },
      {
        name: "Estornar cobrança",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Cancelar cobrança",
        access: ["Administrador", "Moderador"],
      },
    ],
  },
  {
    scope: "Meus Cartões",
    items: [
      {
        name: "Dashboard",
        access: ["Administrador"],
      },
      {
        name: "Pedir cartão físico",
        access: ["Administrador"],
      },
      {
        name: "Gerar cartão Virtual",
        access: ["Administrador"],
      },
      {
        name: "Gerar cartão temporário",
        access: ["Administrador"],
      },
      {
        name: "Visualizar cartão",
        access: ["Administrador"],
      },
      {
        name: "Bloquear cartão",
        access: ["Administrador"],
      },
      {
        name: "Desbloquear cartão",
        access: ["Administrador"],
      },
      {
        name: "Cancelar cartão",
        access: ["Administrador"],
      },
    ],
  },
  {
    scope: "Aprovar transação",
    items: [
      {
        name: "Dashboard",
        access: {
          read: ["Administrador", "Moderador"],
          write: ["Administrador"],
        },
      },
      {
        name: "Aprovar transação",
        access: ["Administrador"],
      },
      {
        name: "Reprovar transação",
        access: ["Administrador"],
      },
    ],
  },
  {
    scope: "Dashboard - Pay",
    access: ["Administrador", "Moderador", "Analista"],
  },
  {
    scope: "Antecipar",
    access: ["Administrador", "Moderador"],
  },
  {
    scope: "Vendas",
    access: ["Administrador", "Moderador", "Analista"],
  },
  {
    scope: "Recebimentos",
    access: ["Administrador", "Moderador", "Analista"],
  },
  {
    scope: "Agenda futura",
    access: ["Administrador", "Moderador", "Analista"],
  },
  {
    scope: "Cobrança (pay)",
    items: [
      {
        name: "Dashboard",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Gerar cobrança",
        access: ["Administrador", "Moderador", "Financeiro"],
      },
      {
        name: "Estornar cobrança",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Cancelar cobrança",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Parcelar futuras",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Antecipar cobrança",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Prorrogar cobrança",
        access: ["Administrador", "Moderador"],
      },
      {
        name: "Pausar cobrança",
        access: ["Administrador", "Moderador"],
      },
    ],
  },
  {
    scope: "Configurações",
    items: [
      {
        name: "Dados da conta",
        access: ["Administrador"],
      },
      {
        name: "Acesso da equipe",
        access: {
          read: ["Administrador", "Moderador"],
          write: ["Administrador"],
        },
      },
      {
        name: "Gestão de limites",
        access: ["Administrador"],
      },
      {
        name: "Notificações",
        access: ["Administrador"],
      },
      {
        name: "Favorecidos",
        access: ["Administrador", "Moderador"],
      },
    ],
  },
  {
    scope: "Políticas",
    access: ["Administrador", "Analista"],
  },
];

function TwoAllowedIcons() {
  return (
    <>
      <Svg.Allowed className="justify-self-center text-status-success-default" />
      <Svg.Allowed className="justify-self-center text-status-success-default" />
    </>
  );
}

function TwoDeniedIcons() {
  return (
    <>
      <Svg.Denied className="justify-self-center text-status-error-default" />
      <Svg.Denied className="justify-self-center text-status-error-default" />
    </>
  );
}

function getAllowedOrDeniedIcon(condition: boolean) {
  return condition ? (
    <Svg.Allowed className="justify-self-center text-status-success-default" />
  ) : (
    <Svg.Denied className="justify-self-center text-status-error-default" />
  );
}

function getAccessIcons(condition: boolean) {
  return condition ? <TwoAllowedIcons /> : <TwoDeniedIcons />;
}

type UserPermissionsListProps = {
  role: Role;
};

const cardStyles =
  "shadow-border bg-neutral-white text-neutral-darkest shadow-neutral-lighter first:rounded-t-sm last:rounded-b-sm";

export function UserPermissionsList({ role }: UserPermissionsListProps) {
  return (
    <div className="flex flex-col overflow-hidden mobile:!hidden">
      <div className="mb-6 grid grid-cols-[3fr_1fr_1fr_1fr] items-center p-px pr-4 text-center text-neutral-darkest">
        <span className="text-start text-sm/md font-bold">Permissões</span>
        <span className="text-xs/default font-medium">Visualizar</span>
        <span className="text-xs/default font-medium">Editar</span>
      </div>

      <ScrollArea>
        <div className="p-px pr-4">
          {PERMISSIONS_LIST.map((permission) =>
            "items" in permission ? (
              <Collapsible.Root
                key={permission.scope}
                className={cardStyles}
                defaultOpen
              >
                <Collapsible.Trigger className="group grid w-full grid-cols-[3fr_1fr] py-4">
                  <span className="pl-4 text-start text-xs/md font-bold">
                    {permission.scope}
                  </span>
                  <div className="justify-self-end pr-4">
                    <Svg.ArrowUp className="transition duration-200 group-data-[state=open]:rotate-180" />
                  </div>
                </Collapsible.Trigger>

                <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down">
                  {permission.items.map((item) => (
                    <li
                      key={item.name}
                      className="grid grid-cols-[3fr_1fr_1fr_1fr] pb-4"
                    >
                      <span className="ml-10 list-item text-start text-xs/md font-regular">
                        {item.name}
                      </span>

                      {"read" in item.access ? (
                        <>
                          {getAllowedOrDeniedIcon(
                            item.access.read.includes(role),
                          )}
                          {getAllowedOrDeniedIcon(
                            item.access.write.includes(role),
                          )}
                        </>
                      ) : (
                        getAccessIcons(item.access.includes(role))
                      )}
                    </li>
                  ))}
                </Collapsible.Content>
              </Collapsible.Root>
            ) : (
              <div
                key={permission.scope}
                className={twJoin(
                  "grid grid-cols-[3fr_1fr_1fr_1fr] py-4",
                  cardStyles,
                )}
              >
                <span className="pl-4 text-start text-xs/md font-bold">
                  {permission.scope}
                </span>

                {permission.access.includes("All") ||
                permission.access.includes(role) ? (
                  <TwoAllowedIcons />
                ) : (
                  <TwoDeniedIcons />
                )}
              </div>
            ),
          )}
        </div>
      </ScrollArea>
    </div>
  );
}
