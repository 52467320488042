import pdfIcon from "@/assets/pdf-icon.png";
import { icons } from "@/pages/contracts/icons";
import { Document, DownloadDocument } from "./types";

type DocumentsListProps = {
  selectedDocument: Document | null;
  onSelectDocument: (document: Document) => void;
  onDownloadDocument: DownloadDocument;
  documents: Document[];
};

export function DocumentsList({
  selectedDocument,
  onSelectDocument,
  onDownloadDocument,
  documents,
}: DocumentsListProps) {
  return (
    <ul className="flex w-full flex-col gap-2">
      {documents.length > 0 ? (
        documents.map((document) => (
          <li key={document.title}>
            <div
              data-state={
                document.title === selectedDocument?.title ? "active" : ""
              }
              className="flex w-full cursor-pointer items-center gap-2 rounded-sm border px-2 py-4 data-[state=active]:border-primary-main"
              onClick={() => onSelectDocument(document)}
            >
              <img src={pdfIcon} alt="pdf-icon" />
              <div>
                <h6>{document.title}</h6>
              </div>
              <button
                type="button"
                className="ml-auto"
                onClick={async (event) => {
                  event.stopPropagation();
                  await onDownloadDocument(document.url, document.title);
                }}
              >
                <icons.download className="h-6 w-6 fill-current" />
              </button>
            </div>
          </li>
        ))
      ) : (
        <li className="flex w-full items-center justify-center gap-2 rounded-sm border px-2 py-4 text-center text-neutral-darkest">
          <p>Nenhum documento disponível</p>
        </li>
      )}
    </ul>
  );
}
