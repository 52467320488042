import { useEffect } from "react";
import { useGetReceivables } from "@/api/anticipation/use-get-receivables";
import { DashboardPayAnticipation } from "@/components/dashboard-pay/dashboard-pay-anticipation";
import { getDashboardRoutesData, setHeader } from "@/utils";
import { useNavigate } from "react-router-dom";

export const DashboardPayAnticipationPage = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetReceivables();

  useEffect(() => {
    setHeader({
      title: "Antecipar",
      show: true,
      rightIcon: undefined,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigate(getDashboardRoutesData().dashboardPay),
      },
    });
  }, [navigate]);

  if (isLoading) return <></>;

  return (
    <DashboardPayAnticipation {...data} className="min-h-[100dvh] px-4 py-6" />
  );
};
