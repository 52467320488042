import {
  PendingTransaction,
  TransactionStatus,
  TransactionType,
} from "@/services/types/get-pending-transactions";

export const formatLabel = (
  params: Pick<PendingTransaction, "TransactionStatus" | "TransactionType">,
) => {
  const typeTranslations: { [key in TransactionType]: string } = {
    TED: "Transferência",
    P2P: "Transferência",
    Payment: "Pagamento",
    Pix: "Transferência",
  };

  const statusTranslations: { [key in TransactionStatus]: string } = {
    REPPROVED: "reprovada",
    REPROVED: "reprovada",
    PENDING: "pendente",
    APPROVED: "aprovada",
  };

  const transactionTypeLabel =
    typeTranslations[params.TransactionType] || "Transferência";

  const transactionStatusLabel =
    statusTranslations[params.TransactionStatus] || "Pendente";

  return `${transactionTypeLabel} ${transactionStatusLabel}`;
};
