import { TabParam } from "@/pages/settings/settings";
import { AccessGroupName, hasPermission, Hooks } from "@/utils";
import { seeAccountData, seeEquipAccess } from "@/utils/allowed-roles";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import * as Svg from "../svgs";

type NavigationProps = {
  activeParam: TabParam;
  handleChangeTabParam: (tab: TabParam) => void;
};

type TabOption = {
  text: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  param: TabParam;
  allowedRoles: AccessGroupName[];
};

export const TABS_OPTIONS: TabOption[] = [
  {
    text: "Dados da conta",
    icon: Svg.Person,
    param: "dados-da-conta",
    allowedRoles: seeAccountData,
  },
  {
    text: "Acesso da equipe",
    icon: Svg.Persons,
    param: "acesso-da-equipe",
    allowedRoles: seeEquipAccess,
  },
  {
    text: "Gestão de limites",
    icon: Svg.DisplaySettings,
    param: "gestao-de-limites",
    allowedRoles: seeAccountData,
  },
  {
    text: "Tarifas",
    icon: Svg.RequestPage,
    param: "tarifas",
    allowedRoles: seeAccountData,
  },
  // { text: "Notificações", icon: Svg.Notifications, param: "notificacoes" },
  {
    text: "Favorecidos",
    icon: Svg.Star,
    param: "favorecidos",
    allowedRoles: seeEquipAccess,
  },
];

export function Navigation({
  activeParam,
  handleChangeTabParam,
}: NavigationProps) {
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <div className="flex flex-col gap-2">
      {TABS_OPTIONS.map((option) => (
        <Button.Root
          key={option.text}
          onClick={() => handleChangeTabParam(option.param)}
          className={twMerge(
            "justify-start border-none px-4 font-bold",
            activeParam !== option.param &&
              "bg-transparent text-neutral-darkest hover:text-neutral-white focus:text-neutral-white",
            !hasPermission(option.allowedRoles) && "hidden",
          )}
        >
          <option.icon /> {option.text}
          {isMobile && <Svg.ArrowDown className="ml-auto rotate-180" />}
        </Button.Root>
      ))}
      <a
        href="https://www.youtube.com/playlist?list=PLBCVnRxwpZHIULpbBQjCADKrq89nC3jgf"
        target="_blank"
        rel="noreferrer"
        className={
          "ease flex h-stack-md cursor-pointer flex-row items-center justify-start gap-inline-x2s rounded-sm border-none bg-transparent p-squished-md px-4 text-xs font-bold leading-md text-neutral-darkest transition-[background,color,border-color] duration-300 hover:bg-primary-dark hover:text-neutral-white focus:text-neutral-white"
        }
      >
        <Svg.Faq /> FAQ
        {/* <option.icon /> {option.text} */}
        {isMobile && <Svg.ArrowDown className="ml-auto rotate-180" />}
      </a>

      {/* <Button.Root className="justify-start border-none bg-transparent px-4 font-bold text-status-error-dark hover:bg-status-error-dark hover:text-neutral-white">
        <Svg.CloseRounded /> Cancelamento de conta
      </Button.Root> */}
    </div>
  );
}
