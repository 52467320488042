import { useState } from "react";
import { useResetPassword } from "@/contexts/reset-password";
import { getErrorTranslated } from "@/formatters/cognito";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { confirmResetPassword } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Button, toast, Token } from "@hyperlocal/vital2";
import { useNavigate } from "react-router-dom";

export function ConfirmEmailTotp() {
  const { filterState, dispatchFilter } = useResetPassword();
  const [totpCode, setTotpCode] = useState("");
  const navigate = useNavigate();

  function goToPreviousStep() {
    dispatchFilter({
      type: "SET_STEP",
      payload: { step: "changePassword" },
    });
  }

  async function handleChangePassword() {
    try {
      await confirmResetPassword({
        email: filterState.email,
        confirmationCode: totpCode,
        newPassword: filterState.password,
      });
      navigate("/");
      toast({
        variant: "success",
        title: "Senha alterada",
      });
    } catch (error) {
      toast({
        variant: "error",
        title: "Houve um erro alterar a senha",
        description: getErrorTranslated(error.message),
      });
    }
  }

  const isDisabled = totpCode.length !== 6;

  return (
    <>
      <div className="flex items-center gap-2">
        <button onClick={goToPreviousStep} title="Voltar para o login">
          <Icon
            name="ArrowArrowNoLineLeft"
            width="24"
            height="24"
            fill="currentColor"
          />
        </button>

        <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm/default">
          Código de segurança
        </h3>
      </div>

      <div className="p-6 mobile:!px-0">
        <span className="text-neutral-darkest mobile:!text-x2s/normal">
          Digite o código de segurança que enviamos para o seu e-mail:{" "}
          <span className="font-bold text-primary-main">
            {filterState.securityEmail}
          </span>
        </span>

        <div className="mx-auto mt-8 w-fit">
          <Token.Label className="mobile:!text-x2s/default">
            Digite o código
          </Token.Label>
          <Token.Root onValueChange={setTotpCode}>
            <Token.Input />
            <Token.Input />
            <Token.Input />
            <Token.Input />
            <Token.Input />
            <Token.Input />
          </Token.Root>
        </div>
      </div>

      <Button.Root
        variant="primary"
        className="mx-auto mt-10 w-[calc(100%-48px)] mobile:!mt-auto mobile:!w-full"
        onClick={handleChangePassword}
        disabled={isDisabled}
      >
        Continuar
      </Button.Root>
    </>
  );
}
