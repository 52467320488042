import {
  AccessGroupName,
  accountsQueryKeyFactory,
  API,
  configureAuth,
  confirmSignIn,
  EmptyState,
  fetchAuthSession,
  formatCurrency,
  GetAccountInfoUseCaseRequest,
  getAccountUseCase,
  getDashboardRoutesData,
  getReceiptRoutesData,
  getReceivedRoutesData,
  getVertical,
  getVerticalAccountId,
  getVerticalBankName,
  globalQueryClient,
  GlobalQueryClientProvider,
  hasPermission,
  Hooks,
  IdentityConfirmationDialog,
  isAuthenticated,
  listAccountsQueryConfig,
  listAccountsSuspenseQueryConfig,
  listAccountsUseCase,
  Notifications,
  prefetchAccountInfo,
  ProtectedComponent,
  ProtectedRoute,
  queries,
  QueryConfig,
  setHeader,
  setUpTOTP,
  signIn,
  signOut,
  storage,
  SuspenseQueryConfig,
  themeProvider,
  Types,
  updateMFAPreference,
  useAccountStore,
  UseListAccounts,
  User,
  UseSuspenseListAccounts,
  useUserStore,
  useVerticalStore,
  validateIdentity,
  verifyTOTPSetup,
  Vertical,
  type Account,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

const routes = getReceiptRoutesData();

export {
  getReceivedRoutesData,
  configureAuth,
  routes,
  Notifications,
  setHeader,
  queries,
  formatCurrency,
  API,
  Types,
  useAccountStore,
  getVerticalAccountId,
  getDashboardRoutesData,
  globalQueryClient,
  useVerticalStore,
  prefetchAccountInfo,
  accountsQueryKeyFactory,
  GetAccountInfoUseCaseRequest,
  getAccountUseCase,
  QueryConfig,
  listAccountsQueryConfig,
  listAccountsSuspenseQueryConfig,
  UseListAccounts,
  UseSuspenseListAccounts,
  Hooks,
  confirmSignIn,
  fetchAuthSession,
  listAccountsUseCase,
  updateMFAPreference,
  User,
  verifyTOTPSetup,
  storage,
  SuspenseQueryConfig,
  setUpTOTP,
  signIn,
  signOut,
  getVertical,
  isAuthenticated,
  EmptyState,
  themeProvider,
  AccessGroupName,
  useUserStore,
  Account,
  ProtectedRoute,
  GlobalQueryClientProvider,
  validateIdentity,
  ProtectedComponent,
  hasPermission,
  Vertical,
  getVerticalBankName,
  IdentityConfirmationDialog,
};
