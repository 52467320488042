import { ReactNode, useState } from "react";
import { useResetPassword } from "@/contexts/reset-password";
import Icon from "@hyperlocal/vital-icons";
import { Button, TextField } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

interface TextHelperProps {
  children: ReactNode;
  isConfirmed: boolean;
}

function TextHelper({ children, isConfirmed }: TextHelperProps) {
  return (
    <div className="flex items-center gap-2">
      <Icon
        name="Check"
        width="20"
        height="20"
        fill="currentColor"
        className={twMerge(
          "rounded-full text-neutral-white",
          isConfirmed ? "bg-status-success-dark" : "bg-neutral-light",
        )}
      />
      <span
        className={
          isConfirmed ? "text-status-success-dark" : "text-neutral-main"
        }
      >
        {children}
      </span>
    </div>
  );
}

const LETTER_AND_NUMBER_REGEX = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
const SMALL_AND_UPPER_LETTER_REGEX = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
const SPECIAL_CHARACTERS = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;

export function ChangePassword() {
  const { dispatchFilter } = useResetPassword();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function goToPreviousStep() {
    dispatchFilter({
      type: "SET_STEP",
      payload: { step: "forgotPassword" },
    });
  }

  async function handleContinue() {
    dispatchFilter({ type: "SET_PASSWORD", payload: { password } });
    dispatchFilter({ type: "SET_STEP", payload: { step: "confirmEmailTotp" } });
  }

  const condition1 = password.length >= 8 && password.length <= 32;
  const condition2 = LETTER_AND_NUMBER_REGEX.test(password);
  const condition3 = SMALL_AND_UPPER_LETTER_REGEX.test(password);
  const condition4 = SPECIAL_CHARACTERS.test(password);
  const condition5 = password === confirmPassword && password !== "";

  const isActive =
    condition1 && condition2 && condition3 && condition4 && condition5;

  return (
    <>
      <div className="flex items-center gap-2">
        <button onClick={goToPreviousStep} title="Voltar para o login">
          <Icon
            name="ArrowArrowNoLineLeft"
            width="24"
            height="24"
            fill="currentColor"
          />
        </button>

        <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm/default">
          Trocar senha
        </h3>
      </div>

      <img src={null} alt="" />

      <div className="p-6 mobile:!px-0">
        <TextField.Label
          htmlFor="password1"
          className="mt-2 mobile:!text-x2s/default"
        >
          Nova senha
        </TextField.Label>
        <TextField.Input
          id="password1"
          variant="password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <TextField.Label
          htmlFor="password2"
          className="mt-8 mobile:!text-x2s/default"
        >
          Confirmar nova senha
        </TextField.Label>
        <TextField.Input
          id="password2"
          variant="password"
          placeholder="Confirme sua senha"
          onChange={(event) => setConfirmPassword(event.target.value)}
        />

        <div className="mt-8 flex flex-col gap-2">
          <TextHelper isConfirmed={condition1}>
            Senha de 8 a 32 dígitos
          </TextHelper>
          <TextHelper isConfirmed={condition2}>
            Contém letras e números
          </TextHelper>
          <TextHelper isConfirmed={condition3}>
            Contém letra maiúscula e minúscula
          </TextHelper>
          <TextHelper isConfirmed={condition4}>
            Contém caractere especial
          </TextHelper>
          <TextHelper isConfirmed={condition5}>
            Senha de confirmação igual
          </TextHelper>
        </div>
      </div>

      <Button.Root
        variant="primary"
        className="mx-auto mt-8 w-[calc(100%-48px)] mobile:!mt-auto mobile:!w-full"
        onClick={handleContinue}
        disabled={!isActive}
      >
        Trocar senha
      </Button.Root>
    </>
  );
}
