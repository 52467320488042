import {
  BankResponse,
  transformBankResponse,
} from "@/pages/settings/react-query/get-banks";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function getBanks() {
  try {
    const response = await axios.get<BankResponse[]>(
      "https://brasilapi.com.br/api/banks/v1",
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
}

export function useGetBanks() {
  return useQuery({
    queryKey: ["getBanks"],
    queryFn: getBanks,
    select: (data) => transformBankResponse(data),
  });
}
