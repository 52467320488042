import { MutationConfig } from "@/lib";
import { transactionQueryKeyFactory } from "@/pages/pending-transactions/common";
import apis from "@/services";
import { ManageTransactionRequest } from "@/services/types";
import { GetPendingTransactionsResponse } from "@/services/types/get-pending-transactions";
import { globalQueryClient } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { useMutation } from "@tanstack/react-query";

export type ApproveVariables = Omit<ManageTransactionRequest, "variant">;

type RemoveTransactionsProps = {
  pendingList: GetPendingTransactionsResponse;
  selectedTransactions: ApproveVariables["body"]["Items"];
};

type QueryContext = {
  previousPendingList: GetPendingTransactionsResponse;
};

const removeSelectedTransactions = ({
  pendingList,
  selectedTransactions,
}: RemoveTransactionsProps) => {
  const idsToRemove = new Set(
    selectedTransactions.map((transaction) => transaction.TransactionId),
  );

  return pendingList.filter(
    (pendingTransaction) => !idsToRemove.has(pendingTransaction.TransactionId),
  );
};

export const onMutatePendingActions = async (variables: ApproveVariables) => {
  await globalQueryClient.cancelQueries({
    queryKey: transactionQueryKeyFactory.pending(),
  });

  const previousPendingList =
    globalQueryClient.getQueryData<GetPendingTransactionsResponse>(
      transactionQueryKeyFactory.pending(),
    );

  globalQueryClient.setQueryData(
    transactionQueryKeyFactory.pending(),
    (oldList: GetPendingTransactionsResponse) =>
      removeSelectedTransactions({
        pendingList: oldList,
        selectedTransactions: variables.body.Items,
      }),
  );

  return { previousPendingList };
};

const defaultMutationOptions = {
  onError: (error: Error, _, context: QueryContext) => {
    const errorMessage = error?.message || "Houve um erro, tente novamente.";

    toast({
      title: errorMessage,
      variant: "error",
    });

    globalQueryClient.setQueryData(
      transactionQueryKeyFactory.pending(),
      context.previousPendingList,
    );
  },
};

export const useApproveTransactions = (
  config?: MutationConfig<typeof apis.manageTransactions>,
) => {
  return useMutation({
    ...config,
    mutationFn: async (variables: ApproveVariables) =>
      await apis.manageTransactions({
        variant: "APPROVE",
        body: variables.body,
      }),
    ...defaultMutationOptions,
  });
};

export const useReproveTransactions = (
  config?: MutationConfig<typeof apis.manageTransactions>,
) => {
  return useMutation({
    ...config,
    mutationFn: async (variables: ApproveVariables) =>
      await apis.manageTransactions({
        variant: "REPROVE",
        body: variables.body,
      }),
    ...defaultMutationOptions,
  });
};
