import { ReactNode, useState } from "react";
import { queryClient } from "@/lib";
import {
  FAVORED_LIST_KEY,
  FavoredListItem,
} from "@/pages/settings/react-query/favored-list";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API, Hooks } from "@hyperlocal/banking-utility";
import { Button, Dialog, Drawer, toast } from "@hyperlocal/vital2";

type DeleteBankAccountModalProps = {
  children?: ReactNode;
  profile: FavoredListItem;
  accountId: string;
  isOpen?: boolean;
};

export function DeleteBankAccountModal({
  children,
  profile,
  accountId,
  isOpen,
}: DeleteBankAccountModalProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(false);

  function onOpenChange(open: boolean) {
    setIsModalOpen(open);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function handleDeleteBankAccount() {
    try {
      setIsLoading(true);

      await API.contact.put("/Contact", {
        document: profile.document,
        contactName: profile.name,
        accounts: profile.accounts.filter(
          (profile) => profile.id !== accountId
        ),
      });
      queryClient.invalidateQueries({ queryKey: [FAVORED_LIST_KEY] });

      toast({
        variant: "success",
        title: "Conta excluída",
      });
      handleCloseModal();
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return isMobile ? (
    <Drawer.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}

      <Drawer.Content onOpenAutoFocus={(event) => event.preventDefault()}>
        <Drawer.Header>
          <Drawer.Title>Excluir conta</Drawer.Title>
        </Drawer.Header>
        <div className="mt-6 text-xs/md text-neutral-darker">
          Você tem certeza de que deseja Excluir a conta bancária do favorecido?
          Ao fazer isso, você não poderá realizar transferências para esta
          conta.
        </div>
        <div className="mt-4 flex gap-2">
          <Drawer.Close asChild>
            <Button.Root variant="secondary" className="w-full border">
              Cancelar
            </Button.Root>
          </Drawer.Close>
          <Button.Root
            className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
            disabled={isLoading}
            onClick={handleDeleteBankAccount}
          >
            Excluir
          </Button.Root>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  ) : (
    <Dialog.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}

      <Dialog.Content className="max-w-[372px]">
        <Dialog.Header>
          <Dialog.Title>Excluir conta</Dialog.Title>
        </Dialog.Header>
        <div className="text-xs/md text-neutral-darker">
          Você tem certeza de que deseja Excluir a conta bancária do favorecido?
          Ao fazer isso, você não poderá realizar transferências para esta
          conta.
        </div>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button.Root variant="secondary" className="w-full border">
              Cancelar
            </Button.Root>
          </Dialog.Close>
          <Button.Root
            className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
            disabled={isLoading}
            onClick={handleDeleteBankAccount}
          >
            Excluir
          </Button.Root>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}
