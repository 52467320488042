import { Check, Error } from "@/assets/svgs";
import apis from "@/services";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import { useSuspenseQuery } from "@tanstack/react-query";

const totalQueryKey = {
  default: ["total"] as const,
};

function listTotal() {
  return apis.getTotal();
}

export function useTotal() {
  const { data, isLoading } = useSuspenseQuery({
    queryFn: listTotal,
    queryKey: totalQueryKey.default,
  });

  return { data, isLoading };
}

export const Total = () => {
  const { data } = useTotal();

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between rounded-sm bg-white px-4 py-4 mobile:flex-col mobile:!items-start mobile:gap-2 mobile:border desktop:px-6">
        <div className="flex items-center gap-2">
          <div className="flex size-8 items-center justify-center rounded-full bg-status-success-light">
            <Check className="size-4 fill-status-warning-dark" />
          </div>

          <h3 className="font-medium mobile:text-[12px]">Aprovados</h3>
        </div>

        <span className="font-bold text-status-success-dark">
          <span className="mr-1 mobile:text-[10px]">R$</span>
          <span className="text-[24px] mobile:text-sm">
            {formatCurrency(data?.Approved || 0).replace("R$", "")}
          </span>
        </span>
      </div>

      <div className="flex items-center justify-between rounded-sm bg-white px-4 py-4 mobile:flex-col mobile:!items-start mobile:gap-2 mobile:border desktop:px-6">
        <div className="flex items-center gap-2">
          <div className="flex size-8 items-center justify-center rounded-full bg-status-error-light">
            <Error className="size-4" />
          </div>

          <h3 className="font-medium mobile:text-[12px]">Cancelados</h3>
        </div>

        <span className="font-bold text-status-error-dark">
          <span className="mr-1 mobile:text-[10px]">R$</span>
          <span className="text-[24px] mobile:text-sm">
            {formatCurrency(data?.Repproved || 0).replace("R$", "")}
          </span>
        </span>
      </div>
    </div>
  );
};

export const LoadingTotal = () => (
  <div className="grid grid-cols-2 gap-4">
    <div className="flex items-center justify-between rounded-sm bg-white px-4 py-4 mobile:flex-col mobile:!items-start mobile:gap-2 mobile:border desktop:px-6">
      <div className="flex items-center gap-2">
        <div className="flex size-8 items-center justify-center rounded-full bg-status-success-light">
          <Check className="size-4 fill-status-warning-dark" />
        </div>

        <h3 className="font-medium mobile:text-[12px]">Aprovados</h3>
      </div>

      <span className="font-bold text-status-success-dark">
        <span className="mr-1 mobile:text-[10px]">R$</span>
        <span className="text-[24px] mobile:text-sm">
          {formatCurrency(0).replace("R$", "")}
        </span>
      </span>
    </div>

    <div className="flex items-center justify-between rounded-sm bg-white px-4 py-4 mobile:flex-col mobile:!items-start mobile:gap-2 mobile:border desktop:px-6">
      <div className="flex items-center gap-2">
        <div className="flex size-8 items-center justify-center rounded-full bg-status-error-light">
          <Error className="size-4" />
        </div>

        <h3 className="font-medium mobile:text-[12px]">Cancelados</h3>
      </div>

      <span className="font-bold text-status-error-dark">
        <span className="mr-1 mobile:text-[10px]">R$</span>
        <span className="text-[24px] mobile:text-sm">
          {formatCurrency(0).replace("R$", "")}
        </span>
      </span>
    </div>
  </div>
);
