import EmptyLogo from "@/assets/lupinha.png";
import { ScrollArea } from "@/components/scroll-area";
import { Skeleton } from "@/components/skeleton";
import { Filters } from "@/pages/pending-transactions/tabs/history/components/filters";
import {
  ListItem,
  ListItemSkeleton,
} from "@/pages/pending-transactions/tabs/history/components/list-item";
import apis from "@/services";
import { GetPendingTransactionsParams } from "@/services/types/get-pending-transactions";
import { groupByDate } from "@/utils/pending-transactions/group-by-date";
import { useSuspenseQuery } from "@tanstack/react-query";
import { format, isToday, subDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useSearchParams } from "react-router-dom";

const historyQueryKey = {
  default: (params: string[]) => ["history", ...params] as const,
};

const CURRENT_DATE = new Date();
const PAST_DAYS = 7;

function listHistory(params: GetPendingTransactionsParams) {
  return apis.getPendingTransactions(params);
}

function parseParams(searchParams: URLSearchParams) {
  const params: GetPendingTransactionsParams = {
    startDate:
      searchParams.get("startDate") ||
      format(subDays(CURRENT_DATE, PAST_DAYS), "yyyy-MM-dd"),
    endDate: searchParams.get("endDate") || format(CURRENT_DATE, "yyyy-MM-dd"),
    transactionType: searchParams.get("transactionType"),
    beneficiaryName: searchParams.get("beneficiaryName"),
    transactionStatus: searchParams.getAll("transactionStatus").join("-"),
  };

  return params;
}

export function useHistory() {
  const [searchParams] = useSearchParams();
  const historyParams = parseParams(searchParams);
  const query = searchParams.get("query");

  const { data, isLoading } = useSuspenseQuery({
    queryFn: () => listHistory(historyParams),
    queryKey: historyQueryKey.default(Object.values(historyParams)),
    select: (data) => {
      if (query) {
        const lowerQuery = query.toLowerCase();

        return data.filter(
          (item) =>
            item.BeneficiaryName.toLowerCase().includes(lowerQuery) ||
            item.TransactionType.toLowerCase().includes(lowerQuery),
        );
      }

      return data;
    },
  });

  return { data, isLoading };
}

export const List = () => {
  const { data } = useHistory();

  const list = groupByDate(data).reverse();

  if (list.length > 0) {
    return (
      <ScrollArea className="rounded-sm bg-white mobile:h-[75vh] desktop:h-[637px]">
        <div className="h-full flex-1 space-y-6 desktop:p-6">
          <Filters />

          <div className="space-y-4">
            {list.map(({ date, items }) => {
              return (
                <div className="space-y-4" key={date}>
                  <div className="flex items-center justify-between">
                    <h5 className="text-[20px] font-bold text-neutral-darkest">
                      {isToday(new Date(date)) && "Hoje, "}

                      {format(new Date(date), "dd 'de' MMMM", {
                        locale: ptBR,
                      })}
                    </h5>

                    <span className="text-neutral-dark">
                      {format(new Date(date), "EEEE", {
                        locale: ptBR,
                      })}
                    </span>
                  </div>

                  {items.map((item) => {
                    return <ListItem item={item} key={item.TransactionId} />;
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </ScrollArea>
    );
  }

  return (
    <div className="flex h-full flex-col space-y-6 rounded-sm bg-white mobile:h-[68vh] desktop:h-[637px] desktop:p-6">
      <Filters />

      <div className="mt-auto flex h-full flex-1 flex-col items-center justify-center rounded-sm bg-white">
        <img src={EmptyLogo} alt="" />

        <p className="text-center text-[#A6A6A6]">
          Aqui está vazio! Nenhum lançamento.
        </p>
      </div>
    </div>
  );
};

export const LoadingList = () => (
  <ScrollArea className="rounded-sm bg-white mobile:h-[68vh] desktop:h-[637px]">
    <div className="h-full flex-1 space-y-6 desktop:p-6">
      <Filters />

      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <div className="space-y-4" key={index}>
            <div className="flex items-center justify-between">
              <Skeleton className="h-[2ex] w-[10ch]" />

              <Skeleton className="h-[1.5ex] w-[7ch]" />
            </div>

            {Array.from({ length: 3 }).map((_, index) => {
              return <ListItemSkeleton key={index} />;
            })}
          </div>
        );
      })}
    </div>
  </ScrollArea>
);
