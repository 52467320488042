import apis from "@/services";
import { useAccountStore } from "@/utils";
import { useQuery } from "@tanstack/react-query";

export const useGetReceivables = () => {
  const { account } = useAccountStore();

  const { customerId: guidCustomer } =
    account.companyInfoResponse || account.personInfoResponse;

  return useQuery({
    queryKey: ["receivables"],
    queryFn: async () => await apis.getAnticipationData(guidCustomer),
  });
};
