import { useState } from "react";
import { useAnticipate } from "@/api/pay/useAnticipate";
import {
  getDashboardRoutesData,
  Hooks,
  useAccountStore,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Dialog, Drawer, toast } from "@hyperlocal/vital2";
import { AxiosError } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AnticipationReceiptSheet } from "./dashboard-pay-anticipation-receipt";

const dictionary = {
  trigger: "Solicitar antecipação",
  title: "Atenção",
  description: (
    <>
      A <b>solicitação de antecipação</b> pode levar até dois dias úteis para
      ser processada. Além disso, você só pode{" "}
      <b>solicitar a antecipação uma vez por dia</b>. Você tem certeza de que
      deseja prosseguir com a antecipação?
    </>
  ),
  cancel: "Cancelar",
  anticipate: "Antecipar",
};

type AnticipationConfirmationProps = {
  nsuList: string[];
};

export const AnticipationConfirmation = ({
  nsuList,
}: AnticipationConfirmationProps) => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const routes = getDashboardRoutesData();

  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const { account } = useAccountStore();

  const { customerId: guidCustomer } =
    account.companyInfoResponse || account.personInfoResponse;

  const anticipationMutation = useAnticipate();

  const handleConfirmAnticipation = () => {
    anticipationMutation.mutate(
      {
        guidCustomer,
        installmentsNsuList: nsuList,
      },
      {
        onSuccess: (data) => {
          setSearchParams({ anticipationId: data.guidSpotRequests });

          if (data.ocurrenceMessage !== "") {
            toast({
              title: "Erro ao antecipar",
              description:
                data.ocurrenceMessage ??
                "Ocorreu um erro ao solicitar a antecipação",
              variant: "error",
            });
          }

          if (isMobile) {
            return navigate(
              `${routes.dashboardPayAnticipationReceipt}?anticipationId=${data.guidSpotRequests}`,
            );
          }

          setIsSheetOpen(true);
        },
        onError: (error: AxiosError<{ errors: { Messages: string[] } }>) => {
          console.log("error");
          toast({
            title: "Erro ao antecipar",
            description:
              error.response.data.errors.Messages[0] ??
              "Ocorreu um erro ao solicitar a antecipação",
            variant: "error",
          });
        },
      },
    );
  };

  if (isMobile) {
    return (
      <Drawer.Root>
        <Drawer.Trigger asChild>
          <Button.Root className="w-full" variant="primary">
            {dictionary.trigger}
          </Button.Root>
        </Drawer.Trigger>

        <Drawer.Content className="pb-9">
          <Drawer.Header>
            <Drawer.Title>{dictionary.title}</Drawer.Title>
          </Drawer.Header>

          <p className="mt-6">{dictionary.description}</p>

          <div className="mt-6 grid w-full grid-cols-2 space-x-2">
            <Drawer.Close asChild>
              <Button.Root variant="secondary">{dictionary.cancel}</Button.Root>
            </Drawer.Close>

            <Button.Root
              variant="primary"
              onClick={handleConfirmAnticipation}
              isLoading={anticipationMutation.isPending}
            >
              {dictionary.anticipate}
            </Button.Root>
          </div>
        </Drawer.Content>
      </Drawer.Root>
    );
  }

  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button.Root className="w-full" variant="primary">
            {dictionary.trigger}
          </Button.Root>
        </Dialog.Trigger>

        <Dialog.Content className="gap-0">
          <Dialog.Header>
            <Dialog.Title>{dictionary.title}</Dialog.Title>
          </Dialog.Header>

          <p className="mt-2">{dictionary.description}</p>

          <Dialog.Footer className="mt-6 grid w-full grid-cols-2 space-x-2">
            <Dialog.Close asChild>
              <Button.Root variant="secondary">Cancelar</Button.Root>
            </Dialog.Close>

            <Button.Root
              onClick={handleConfirmAnticipation}
              isLoading={anticipationMutation.isPending}
            >
              Antecipar
            </Button.Root>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>

      <AnticipationReceiptSheet
        open={isSheetOpen}
        onOpenChange={setIsSheetOpen}
      />
    </>
  );
};
