type IconProps = React.HTMLAttributes<SVGElement>;

export const icons = {
  download: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6.3 19.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 17.7V15H6v2.7c0 .067.033.133.1.2s.133.1.2.1h11.4c.067 0 .133-.033.2-.1s.1-.133.1-.2V15h1.5v2.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm5.7-3.875L7.725 11.35l1.05-1.1 2.475 2.475v-8.4h1.5v8.4l2.475-2.475 1.05 1.1L12 15.625z"
        fill="#1A1A1A"
      />
    </svg>
  ),
};
