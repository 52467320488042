export function getErrorTranslated(errorMessage: string) {
  const mappedError = {
    "Attempt limit exceeded, please try after some time.":
      "Limite de tentativas excedido. Tente depois de algum tempo.",
    "Incorrect username or password.": "Usuário ou senha incorretos.",
    "Invalid code received for user": "Código inválido.",
    "Code mismatch": "Código inválido.",
    "There is already a signed in user.": "Já existe um usuário logado.",
  };

  const translatedMessage = mappedError[errorMessage];

  if (!translatedMessage) {
    console.error(`Error não mapeado: ${errorMessage}`);
  }

  return translatedMessage ?? "";
}
