import { ScrollArea } from "@/components/scroll-area";
import * as Svg from "@/pages/settings/svgs";
import { getVertical } from "@/utils";
import * as Collapsible from "@radix-ui/react-collapsible";

const limits = [
  {
    title: "Limites padrões",
    items: [
      {
        label: "TED",
        daily: "R$ 80.000,00",
        transaction: "R$ 30.000,00",
      },
      {
        label: `Conta ${getVertical()}`,
        daily: "R$ 20.000,00",
        transaction: "R$ 10.000,00",
      },
      {
        label: "Pix Diurno",
        daily: "R$ 80.000,00",
        transaction: "R$ 30.000,00",
      },
      {
        label: "Pix Noturno",
        daily: "R$ 1.000,00",
        transaction: "R$ 1.000,00",
      },
      {
        label: "Boleto",
        daily: "R$ 90.000,00",
        transaction: "R$ 30.000,00",
      },
      {
        label: "Pagamento de conta",
        daily: "R$ 90.000,00",
        transaction: "R$ 30.000,00",
      },
    ],
  },
  // {
  //   title: "Limites favorecidos",
  //   items: [
  //     {
  //       label: "TED",
  //       daily: "R$ 10.000,00",
  //       transaction: "R$ 10.000,00",
  //     },
  //     {
  //       label: "Conta hyperlocal",
  //       daily: "R$ 10.000,00",
  //       transaction: "R$ 10.000,00",
  //     },
  //     {
  //       label: "Pix Diurno",
  //       daily: "R$ 10.000,00",
  //       transaction: "R$ 10.000,00",
  //     },
  //     {
  //       label: "Pix Noturno",
  //       daily: "R$ 10.000,00",
  //       transaction: "R$ 10.000,00",
  //     },
  //     {
  //       label: "Boleto",
  //       daily: "R$ 10.000,00",
  //       transaction: "R$ 10.000,00",
  //     },
  //     {
  //       label: "Pagamento de conta",
  //       daily: "R$ 10.000,00",
  //       transaction: "R$ 10.000,00",
  //     }
  //   ],
  // },
] as const;

export function LimitManagement() {
  return (
    <div className="-mr-4 flex h-full flex-col overflow-hidden">
      <ScrollArea>
        <div className="space-y-2 p-px pr-4">
          {limits.map((limit) => (
            <Collapsible.Root
              key={limit.title}
              className="overflow-hidden rounded-b-sm rounded-t-sm bg-neutral-white text-neutral-darkest shadow-border shadow-neutral-lighter"
              defaultOpen={limit.title === "Limites padrões"}
              open
            >
              <Collapsible.Trigger className="group grid w-full grid-cols-[2fr_1fr_1fr_24px] items-center gap-2 p-4 text-start text-xs/md font-bold transition-all duration-200 data-[state=open]:bg-primary-lighter data-[state=open]:text-primary-main mobile:!grid-cols-[1.5fr_2fr_2fr_24px] mobile:!text-x2s/default">
                <span className="truncate">{limit.title}</span>
                <span className="truncate">Diário</span>
                <span className="truncate">Transação</span>
                <div className="justify-self-end">
                  <Svg.ArrowUp className="transition duration-200 group-data-[state=open]:rotate-0" />
                </div>
              </Collapsible.Trigger>

              <Collapsible.Content className="space-y-1 overflow-hidden data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down">
                {limit.items.map((item) => (
                  <div
                    key={item.label}
                    className="grid grid-cols-[2fr_1fr_1fr_24px] items-center gap-2 text-nowrap p-4 text-start text-xs/md font-regular text-neutral-darkest mobile:!grid-cols-[1.5fr_2fr_2fr_24px] mobile:!text-x2s/md"
                  >
                    <span className="truncate text-wrap font-bold">
                      {item.label}
                    </span>
                    <span className="truncate">{item.daily}</span>
                    <span className="truncate">{item.transaction}</span>
                  </div>
                ))}
              </Collapsible.Content>
            </Collapsible.Root>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}
