import { FavoredListResponse } from "@/pages/settings/react-query/favored-list/use-favored-list.types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API, toast } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";

export const FAVORED_LIST_KEY = "get-favored-list";

export async function getFavoredList() {
  try {
    const response = await API.contact.get<FavoredListResponse>("/Contact");

    return response.data.Contacts.map((item) => ({
      id: item.Id,
      name: item.ContactName,
      document: item.Document,
      isFavored: item.IsFavoredContact,
      accounts: item.ContactAccounts.map((account) => {
        if (account.TransferType === "pix") {
          return {
            id: account.Id,
            transferType: account.TransferType,
            pixKey: account.PixKey,
            ispb: String(account.ISPB).padStart(8, "0"),
            branch: account.Branch,
            accountNumber: account.AccountNumber,
          };
        }

        return {
          id: account.Id,
          transferType: account.TransferType,
          branch: account.Branch,
          branchDigit: account.BranchDigit,
          accountNumber: account.AccountNumber,
          accountDigit: account.AccountDigit,
          bankCode: account.BankCode,
          bankName: account.BankName,
          bankAccountType: account.BankAccountType,
          ispb: String(account.ISPB).padStart(8, "0"),
          P2PAccountId: account.P2PAccountId,
        };
      }),
    }));
  } catch (error) {
    toast.error("Não foi possível buscar as contestações.");
    throw new Error(error);
  }
}

export function useFavoredList() {
  return useQuery({
    queryKey: [FAVORED_LIST_KEY],
    queryFn: () => getFavoredList(),
    staleTime: 1000 * 60,
  });
}
