import { getDashboardRoutesData } from "@/utils/utility";
import Icon from "@hyperlocal/vital-icons";
import { Breadcrumb as VitalBreadcrumb } from "@hyperlocal/vital2";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type DashboardRoutesPath = keyof ReturnType<typeof getDashboardRoutesData>;

export type BreadcrumbItem<
  Param extends string = string,
  ParamValue extends string = string,
> =
  | { isLoading?: boolean }
  | {
      name: string;
      path: DashboardRoutesPath;
      searchParam?: `?${Param}=${ParamValue}`;
      active?: boolean;
      id?: string;
    };

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
}

const routes = getDashboardRoutesData();

export function Breadcrumb({ breadcrumbs }: BreadcrumbProps) {
  return (
    <VitalBreadcrumb.Root className="mobile:!hidden">
      <VitalBreadcrumb.List>
        <VitalBreadcrumb.ListItem>
          <VitalBreadcrumb.Anchor asChild className="ml-0 size-6">
            <Link to={routes.dashboard}>
              <Icon name="GeralHome" fill="currentColor" />
            </Link>
          </VitalBreadcrumb.Anchor>
        </VitalBreadcrumb.ListItem>

        {breadcrumbs.map((item, index) => {
          if ("name" in item) {
            return (
              <VitalBreadcrumb.ListItem key={item.name}>
                <VitalBreadcrumb.Separator />
                <VitalBreadcrumb.Anchor
                  asChild
                  active={item.active}
                  className={twMerge(item.active && "pointer-events-none")}
                >
                  <Link
                    to={
                      routes[item.path]?.replace(":id", item.id ?? "") +
                      (item.searchParam ?? "")
                    }
                  >
                    {item.name}
                  </Link>
                </VitalBreadcrumb.Anchor>
              </VitalBreadcrumb.ListItem>
            );
          }

          return (
            <div
              key={index}
              className="block h-8 w-40 animate-pulse rounded-sm bg-neutral-light opacity-15"
            />
          );
        })}
      </VitalBreadcrumb.List>
    </VitalBreadcrumb.Root>
  );
}
