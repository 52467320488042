import {
  formatCNPJ,
  formatCPF,
  isValidCNPJ,
  isValidCPF,
} from "@brazilian-utils/brazilian-utils";
import { format } from "date-fns";

export const unMask = (value: string) => {
  return value.replace(/[^\d]/g, "");
};

export const formatDocument = (document: string) => {
  if (!document) return "";
  const unmaskedDocument = unMask(document);

  if (isValidCNPJ(unmaskedDocument)) return formatCNPJ(document);

  if (isValidCPF(unmaskedDocument)) return formatCPF(document);

  return document;
};

export function formatNumber(value: number) {
  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: 2,
  }).format(value);
}

export const formatToInputDate = (date: Date) => format(date, "yyyy-MM-dd");

export const handleNormalizeDate = (date: Date | string) => {
  const parsedDate = new Date(date);
  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setDate(parsedDate.getDate() + 1);
  return parsedDate;
};

const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
  day: "numeric",
  month: "long",
});

export function getToday(date: string) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);

  if (!date) {
    return dateFormatter.format(new Date());
  }
  const isToday = newDate.toDateString() === new Date().toDateString();

  if (isToday) {
    return `Hoje ${dateFormatter.format(newDate)}`;
  }

  return dateFormatter.format(newDate);
}

export function parseAmountToBRL(amount = 0) {
  return amount.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function dateFormat(date: string | Date) {
  if (!date) return;
  const dateFormatted = new Date(date);
  return dateFormatted.toLocaleDateString("pt-BR", { timeZone: "UTC" });
}

export function capitalizeFirstLetter(value: string) {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const removeSpecialCharacters = (value: string) =>
  value.replace(/\D/g, "");

export const getPixAccountNumber = (account: string) => {
  const sanitizedValue = removeSpecialCharacters(account);

  const accountNumber = sanitizedValue.substring(0, sanitizedValue.length - 1);

  const accountDigit = sanitizedValue.charAt(sanitizedValue.length - 1);

  return {
    accountNumber,
    accountDigit,
  };
};
