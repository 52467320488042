import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { Action, State } from "@/contexts/login/types";
import { Types } from "@/utils";

const initialState: State = {
  step: "signIn",
  email: "",
  password: "",
  totpSetupURL: "",
  totpSetupSecret: "",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload.email };
    case "SET_PASSWORD":
      return { ...state, password: action.payload.password };
    case "SET_STEP":
      return { ...state, step: action.payload.step };
    case "SET_TOTP_SETUP":
      return {
        ...state,
        totpSetupURL: action.payload.URL,
        totpSetupSecret: action.payload.secret,
        step: "totpSetup",
      };
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

export interface Context {
  filterState: State;
  dispatchFilter: React.Dispatch<Action>;
}

export const LoginContext = createContext<Context>({
  filterState: initialState,
  dispatchFilter: () => null,
});

interface LoginProviderProps {
  defaultRoutes: Types["IDashboardRoutes"];
  children: ReactNode;
}

export function LoginProvider({ children }: LoginProviderProps) {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ filterState, dispatchFilter }),
    [filterState, dispatchFilter],
  );

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
}

export function useLogin() {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("LoginContext must be used within LoginProvider");
  }
  return context;
}
