import {
  Account,
  accountsQueryKeyFactory,
  GetAccountInfoUseCaseRequest,
  getAccountUseCase,
  useAccountStore,
} from "@/utils";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export type UseAccountInfo = {
  config?: UseQueryOptions<Account>;
} & GetAccountInfoUseCaseRequest;

const fiveMinutes = 5 * 60 * 1000;

async function getAccountInfo({ accountId }: GetAccountInfoUseCaseRequest) {
  const { updateAccountId, updateAccount } = useAccountStore.getState();

  const accountResponse = await getAccountUseCase.execute({ accountId });

  if (accountResponse.isFailure()) {
    throw new Error("Falha ao buscar o detalhes da conta.");
  }

  updateAccountId(accountId);
  updateAccount(accountResponse.value);
  return accountResponse.value;
}

export const useAccountInfo = ({ accountId, config }: UseAccountInfo) => {
  const query = useQuery({
    queryKey: accountsQueryKeyFactory.detail(accountId),
    queryFn: () => getAccountInfo({ accountId }),
    staleTime: fiveMinutes,
    ...config,
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
