import { useState } from "react";
import { queryClient } from "@/lib";
import {
  FAVORED_LIST_KEY,
  FavoredListItem,
} from "@/pages/settings/react-query/favored-list";
import {
  bankAccountType,
  newFavoredSchemaObject,
  radioGroupOptions,
  selectOptions,
  superRefineCallback,
} from "@/pages/settings/sections/favored/components/new-favored-sheet-helper";
import { SelectBankComboBox } from "@/pages/settings/sections/favored/components/select-bank-combo-box";
import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import {
  Button,
  RadioGroup,
  Select,
  TextField,
  toast,
} from "@hyperlocal/vital2";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

type AddNweBankFormProps = {
  setIsAddNewBank: React.Dispatch<React.SetStateAction<boolean>>;
  profile: FavoredListItem;
};

const addNewBankSchema = newFavoredSchemaObject
  .omit({ name: true, document: true })
  .superRefine(superRefineCallback);

export type NewFavoredSchemaInput = z.input<typeof addNewBankSchema>;
export type NewFavoredSchemaOutput = z.output<typeof addNewBankSchema>;

export function AddNewBankForm({
  setIsAddNewBank,
  profile,
}: Readonly<AddNweBankFormProps>) {
  const [isSendingForm, setIsSendingForm] = useState(false);
  const methods = useForm<NewFavoredSchemaInput>({
    resolver: zodResolver(addNewBankSchema),
    mode: "onTouched",
    values: {
      type: "Conta bancária",
      bankType: "Corrente",
      pixKey: "",
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = methods;

  async function handleFormSubmit(data) {
    try {
      setIsSendingForm(true);

      const formData = data as NewFavoredSchemaOutput;
      const newBankAccount =
        formData.type === "Chave Pix"
          ? {
              transferType: "pix",
              pixKey: formData.pixKey,
            }
          : {
              transferType: "ted",
              branch: formData.agency,
              branchDigit: "0",
              accountNumber: formData.accountNumber.slice(0, -1),
              accountDigit: formData.accountNumber.at(-1),
              bankCode: formData.bank.bankCode,
              bankName: formData.bank.bankName,
              bankAccountType: bankAccountType[formData.bankType],
            };

      await API.contact.put("/Contact", {
        document: profile.document,
        contactName: profile.name,
        accounts: [...profile.accounts, newBankAccount],
      });
      queryClient.invalidateQueries({ queryKey: [FAVORED_LIST_KEY] });

      toast({
        variant: "success",
        title: "Conta adicionada",
      });
      setIsAddNewBank(false);
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsSendingForm(false);
    }
  }

  const values = watch();
  const hasErrors = !addNewBankSchema.safeParse(values).success;

  return (
    <FormProvider {...methods}>
      <div className="m-4 space-y-6 rounded-sm p-4 ring-1 ring-inset ring-primary-main">
        <div>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Select.Root
                value={field.value}
                onValueChange={field.onChange}
                onOpenChange={(value) => !value && field.onBlur()}
              >
                <Select.Label htmlFor="access-level">Tipo</Select.Label>
                <Select.Trigger
                  id="access-level"
                  data-testid="access-level-trigger"
                  status={errors.type ? "error" : undefined}
                  className="peer w-full gap-2 data-[status=error]:!border-status-error-default first:[&_span]:truncate"
                >
                  <Select.Value />
                </Select.Trigger>
                <Select.Content className="w-[var(--radix-select-trigger-width)] font-base">
                  <Select.Group>
                    {selectOptions.map((option) => (
                      <Select.Item key={option} value={option}>
                        {option}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            )}
          />
          {errors.type && <Select.Helper>{errors.type.message}</Select.Helper>}
        </div>

        {values.type === "Chave Pix" && (
          <div>
            <TextField.Label
              className="mobile:!text-x2s/default"
              htmlFor="pixKey"
            >
              Chave Pix
            </TextField.Label>
            <TextField.Input
              id="pixKey"
              placeholder="Digite a chave Pix"
              data-status={errors.pixKey && "error"}
              {...register("pixKey")}
            />
            {errors.pixKey && (
              <TextField.Helper>{errors.pixKey.message}</TextField.Helper>
            )}
          </div>
        )}

        {values.type === "Conta bancária" && (
          <>
            <div>
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="select-bank-combo-box"
              >
                Instituição
              </TextField.Label>
              <SelectBankComboBox />
            </div>

            <Controller
              name="bankType"
              control={control}
              render={({ field }) => (
                <RadioGroup.Root
                  orientation="horizontal"
                  className="justify-between"
                  value={field.value}
                  onValueChange={field.onChange}
                >
                  {radioGroupOptions.map((item) => (
                    <RadioGroup.Items key={item} className="pl-0">
                      <RadioGroup.Item value={item} id={item} />
                      <RadioGroup.Label htmlFor={item}>{item}</RadioGroup.Label>
                    </RadioGroup.Items>
                  ))}
                </RadioGroup.Root>
              )}
            />

            <div className="grid grid-cols-[1fr_2.5fr] gap-4">
              <div>
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="agency"
                >
                  Agência
                </TextField.Label>
                <TextField.Input
                  id="agency"
                  placeholder="Digite"
                  data-status={errors.agency && "error"}
                  {...register("agency")}
                />
                {errors.agency && (
                  <TextField.Helper>{errors.agency.message}</TextField.Helper>
                )}
              </div>
              <div>
                <TextField.Label
                  className="mobile:!text-x2s/default"
                  htmlFor="accountNumber"
                >
                  Conta
                </TextField.Label>
                <TextField.Input
                  id="accountNumber"
                  placeholder="Digite"
                  data-status={errors.accountNumber && "error"}
                  {...register("accountNumber")}
                />
                {errors.accountNumber && (
                  <TextField.Helper>
                    {errors.accountNumber.message}
                  </TextField.Helper>
                )}
              </div>
            </div>
          </>
        )}

        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={() => setIsAddNewBank(false)}
            className="rounded-sm p-2 text-xs/default font-medium text-status-error-default ring-1 ring-inset ring-status-error-default hover:bg-status-error-light"
          >
            {isSendingForm ? "Fechar" : "Cancelar"}
          </button>
          <Button.Root
            type="button"
            variant="secondary"
            className="!h-fit transform-gpu border-0 p-2 !leading-default ring-1 ring-inset ring-primary-main hover:ring-primary-dark disabled:ring-neutral-light"
            onClick={handleSubmit(handleFormSubmit)}
            disabled={hasErrors}
          >
            Adicionar conta
          </Button.Root>
        </div>
      </div>
    </FormProvider>
  );
}
