import { Select, TextField } from "@hyperlocal/vital2";
import { Controller, useFormContext } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { z } from "zod";

export const SELECT_OPTIONS = [
  {
    value: "Administrador",
    description:
      "O proprietário da conta, tem acesso e controle da conta digital.",
  },
  {
    value: "Moderador",
    description:
      "O responsável por uma equipe pode visualizar relatórios e transações sem aprovação ",
  },
  {
    value: "Financeiro",
    description:
      "Responsável apenas por transações que exigem aprovação e cobrança. ",
  },
  {
    value: "Analista",
    description: "Responsável pela visualização da conta digital.",
  },
] as const;

export type Role = (typeof SELECT_OPTIONS)[number]["value"];

const options = SELECT_OPTIONS.map((option) => option.value);

export const newUserSchema = z.object({
  name: z.string().min(1, "O nome é obrigatório"),
  cpf: z
    .string()
    .min(1, "O cpf é obrigatório")
    .transform((val, ctx) => {
      const parsed = val.match(/\d/g)?.join("");

      if (parsed.length < 11) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Faltam ${11 - parsed.length} dígitos`,
        });

        return z.NEVER;
      }

      return parsed;
    }),
  phone: z
    .string()
    .min(1, "O número é obrigatório")
    .transform((val, ctx) => {
      const parsed = val.match(/\d/g)?.join("");

      if (parsed.length < 11) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Faltam ${11 - parsed.length} números`,
        });

        return z.NEVER;
      }

      return Number(parsed);
    }),
  email: z
    .string()
    .min(1, "O email é obrigatório")
    .email("Endereço de email inválido"),
  role: z.enum([options[0], ...options.slice(1)], {
    required_error: "Selecione um nível de acesso",
  }),
});

export type NewUserSchemaInput = z.input<typeof newUserSchema>;
export type NewUserSchemaOutput = z.output<typeof newUserSchema>;

export function NewUserForm() {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<NewUserSchemaInput>();

  const registerWithMask = useHookFormMask(register);

  return (
    <div className="flex flex-col gap-6 overflow-hidden">
      <h3 className="text-sm/md font-bold text-neutral-darkest">
        Dados do usuários
      </h3>

      <div className="flex flex-col gap-6 overflow-auto p-px">
        <div>
          <TextField.Label className="mobile:!text-x2s/default" htmlFor="name">
            Nome do usuário
          </TextField.Label>
          <TextField.Input
            id="name"
            autoComplete="name"
            placeholder="Digite o nome"
            data-status={errors.name && "error"}
            {...register("name")}
          />
          {errors.name && (
            <TextField.Helper>{errors.name.message}</TextField.Helper>
          )}
        </div>

        <div className="grid grid-cols-2 gap-6 mobile:!grid-cols-1">
          <div>
            <TextField.Label className="mobile:!text-x2s/default" htmlFor="cpf">
              CPF
            </TextField.Label>
            <TextField.Input
              id="cpf"
              data-status={errors.cpf && "error"}
              className="tabular-nums"
              placeholder="___.___.___-__"
              {...registerWithMask("cpf", ["999.999.999-99"])}
            />
            {errors.cpf && (
              <TextField.Helper>{errors.cpf.message}</TextField.Helper>
            )}
          </div>
          <div>
            <TextField.Label
              className="mobile:!text-x2s/default"
              htmlFor="phone"
            >
              Contato
            </TextField.Label>
            <TextField.Input
              id="phone"
              autoComplete="tel"
              data-status={errors.phone && "error"}
              className="tabular-nums"
              placeholder="(__) _ ____-____"
              {...registerWithMask("phone", ["(99) 9 9999-9999"])}
            />
            {errors.phone && (
              <TextField.Helper>{errors.phone.message}</TextField.Helper>
            )}
          </div>
        </div>

        <div>
          <TextField.Label className="mobile:!text-x2s/default" htmlFor="email">
            E-mail de acesso
          </TextField.Label>
          <TextField.Input
            id="email"
            autoComplete="email"
            placeholder="Digite o e-mail"
            data-status={errors.email && "error"}
            {...register("email")}
          />
          {errors.email && (
            <TextField.Helper>{errors.email.message}</TextField.Helper>
          )}
        </div>

        <div>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select.Root
                value={field.value}
                onValueChange={field.onChange}
                onOpenChange={(value) => !value && field.onBlur()}
              >
                <Select.Label htmlFor="access-level">
                  Nível de acesso
                </Select.Label>
                <Select.Trigger
                  id="access-level"
                  data-testid="access-level-trigger"
                  status={errors.role ? "error" : undefined}
                  className="peer w-full gap-2 data-[status=error]:!border-status-error-default first:[&_span]:truncate"
                >
                  <Select.Value placeholder="Selecione o nível" />
                </Select.Trigger>
                <Select.Content className="w-[var(--radix-select-trigger-width)] font-base">
                  <Select.Group>
                    {SELECT_OPTIONS.map((option) => (
                      <Select.Item
                        key={option.value}
                        value={option.value}
                        description={
                          <span
                            key={option.value}
                            className="block text-x2s/md text-neutral-dark"
                          >
                            {option.description}
                          </span>
                        }
                      >
                        {option.value}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            )}
          />
          {errors.role && <Select.Helper>{errors.role.message}</Select.Helper>}
        </div>
      </div>
    </div>
  );
}
