import { Fragment } from "react/jsx-runtime";
import { Skeleton } from "@/components/skeleton";
import { usePendingTransactions } from "@/pages/pending-transactions/common";
import { Header } from "@/pages/pending-transactions/tabs/pending/components/header";
import {
  EmptyList,
  List,
} from "@/pages/pending-transactions/tabs/pending/components/list";
import { Summary } from "@/pages/pending-transactions/tabs/pending/components/summary";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";

export const classNames = {
  container:
    "mt-4 grid mobile:h-full desktop:h-[726px] flex-1 grid-cols-[1fr_1fr] gap-4 mobile:grid-cols-1 mobile:px-4",
  content: "flex h-full flex-col gap-y-4",
};

export const EmptyPending = () => {
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <section className={classNames.container}>
      <div className={classNames.content}>
        <Header data={[]} />
        <EmptyList />
      </div>

      {!isMobile && <Summary data={[]} />}
    </section>
  );
};

export const Pending = () => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const { data } = usePendingTransactions();

  return (
    <section className={classNames.container}>
      <div className={classNames.content}>
        <Header data={data} />
        {data.length > 0 ? <List data={data} /> : <EmptyList />}
      </div>

      {!isMobile && <Summary data={data} />}
    </section>
  );
};

export const PendingLoading = () => {
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <section className={classNames.container}>
      <div className={classNames.content}>
        <Header data={[]} />
        <div className="h-full w-full bg-white">
          <div className="flex-1 space-y-6 desktop:p-6">
            <Skeleton className="h-6 w-[160px]" />
          </div>
          <div className="desktop:px-6">
            <div className="mt-2">
              <div className="mb-4 flex items-center justify-between">
                <Skeleton className="h-[2.5ex] w-[20ch]" />
              </div>
              <div className="flex flex-col gap-4">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Fragment key={index}>
                    <div className="flex w-full items-center gap-4 px-6 py-3">
                      <Skeleton className="size-5 rounded-none" />
                      <div className="flex gap-2">
                        <Skeleton className="size-8" />
                        <div className="flex flex-col gap-1">
                          <Skeleton className="h-[2ex] w-[12ch]" />
                          <Skeleton className="h-[1.5ex] w-[9ch]" />
                        </div>
                      </div>
                      <Skeleton className="ml-auto h-[1.5ex] w-[7ch]" />
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isMobile && <Summary data={[]} />}
    </section>
  );
};
