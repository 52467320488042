import { useResetPassword } from "@/contexts/reset-password";
import { getErrorTranslated } from "@/formatters/cognito";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { resetPassword } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Button, TextField, toast } from "@hyperlocal/vital2";
import { useNavigate } from "react-router-dom";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function ForgotPassword() {
  const { filterState, dispatchFilter } = useResetPassword();
  const navigate = useNavigate();

  function goToPreviousStep() {
    navigate("/");
  }

  async function handleSendEmailTotp() {
    try {
      const { nextStep } = await resetPassword({ email: filterState.email });

      if (nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
        dispatchFilter({
          type: "SET_SECURITY_EMAIL",
          payload: {
            securityEmail: nextStep.codeDeliveryDetails.destination,
          },
        });
        dispatchFilter({
          type: "SET_STEP",
          payload: { step: "changePassword" },
        });
      }
    } catch (error) {
      toast({
        variant: "error",
        title: "Erro ao enviar o token",
        description: getErrorTranslated(error.message),
      });
    }
  }

  const isEmail = EMAIL_REGEX.test(filterState.email);

  return (
    <>
      <div className="flex items-center gap-2">
        <button onClick={goToPreviousStep} title="Voltar para o login">
          <Icon
            name="ArrowArrowNoLineLeft"
            width="24"
            height="24"
            fill="currentColor"
          />
        </button>

        <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm/default">
          Esqueci minha senha
        </h3>
      </div>

      <div className="p-6 mobile:!px-0">
        <span className="text-neutral-darkest mobile:!text-x2s/normal">
          Iremos enviar um e-mail com o token necessário para a geração de uma
          nova senha.
        </span>

        <TextField.Label
          htmlFor="e-mail"
          className="mt-8 mobile:!text-x2s/default"
        >
          E-mail
        </TextField.Label>
        <TextField.Input
          id="e-mail"
          type="text"
          placeholder="Digite seu email"
          onChange={(event) =>
            dispatchFilter({
              type: "SET_EMAIL",
              payload: { email: event.target.value },
            })
          }
        />
      </div>

      <Button.Root
        variant="primary"
        className="mx-auto mt-10 w-[calc(100%-48px)] mobile:!mt-auto mobile:!w-full"
        onClick={handleSendEmailTotp}
        disabled={!isEmail}
      >
        Continuar
      </Button.Root>
    </>
  );
}
