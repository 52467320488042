import { useState } from "react";
import { useGetBanks } from "@/pages/settings/react-query/get-banks";
import { NewFavoredSchemaInput } from "@/pages/settings/sections/favored/components/new-favored-sheet-helper";
import Icon from "@hyperlocal/vital-icons";
import { TextField } from "@hyperlocal/vital2";
import { Controller, useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { Command } from "./command";
import { Popover } from "./popover";

export function SelectBankComboBox() {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<NewFavoredSchemaInput>();
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useGetBanks();

  const values = watch();

  return (
    <Controller
      name="bank"
      control={control}
      render={({ field }) => (
        <div>
          <Popover.Root
            open={open}
            onOpenChange={(value) => {
              setOpen(value);
              !value && field.onBlur();
            }}
          >
            <Popover.Trigger
              asChild
              className="flex w-full"
              id="select-bank-combo-box"
            >
              <button
                className={twMerge(
                  "flex justify-between rounded-xs border border-neutral-main px-4 py-3 text-xs/md text-neutral-dark",
                  errors.bank && "border-status-error-default",
                  isLoading && "bg-neutral-lighter",
                )}
                disabled={isLoading}
              >
                {!values.bank ? (
                  <>
                    Buscar instituição
                    <Icon
                      name={isLoading ? "ArrowRefreshLine" : "SearchNormal"}
                      className={twMerge(
                        "size-6 fill-current",
                        isLoading && "animate-spin",
                      )}
                    />
                  </>
                ) : (
                  <>
                    {values.bank.bankCode} - {values.bank.shortBankName}
                  </>
                )}
              </button>
            </Popover.Trigger>
            <TextField.Helper className="text-status-error-default">
              {errors.bank?.message}
            </TextField.Helper>
            <Popover.Content>
              <Command.Root
                value={`${field.value?.shortBankName} - ${field.value?.bankCode}`}
              >
                <Command.Input placeholder="Digite aqui" />
                <Command.List>
                  <Command.Empty>Instituição não encontrada</Command.Empty>
                  <Command.Group>
                    {data?.map((bank) => (
                      <Command.Item
                        key={bank.bankCode}
                        value={`${bank.shortBankName} - ${bank.bankCode}`}
                        onSelect={() => {
                          setOpen(false);
                          field.onChange(bank);
                        }}
                      >
                        {bank.bankCode} - {bank.shortBankName}
                      </Command.Item>
                    ))}
                  </Command.Group>
                </Command.List>
              </Command.Root>
            </Popover.Content>
          </Popover.Root>
        </div>
      )}
    />
  );
}
