// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AccessGroupName } from "@hyperlocal/banking-utility";

export const seeAccountData: AccessGroupName[] = [
  "Administrador",
  "Proprietário",
];

export const seeEquipAccess: AccessGroupName[] = [
  "Administrador",
  "Proprietário",
  "Moderador",
];

export const editEquipAccess: AccessGroupName[] = [
  "Administrador",
  "Proprietário",
];
