export type Document = {
  title: string;
  url: string;
};

type DocumentViewerProps = {
  selectedPdf: Document | null;
} & React.ComponentProps<"div">;

export function DocumentViewer({
  selectedPdf,
  className,
  ...props
}: DocumentViewerProps) {
  return (
    <div className={className} {...props}>
      {selectedPdf ? (
        <div className="flex h-full w-full overflow-y-auto mobile:h-[65vh] desktop:min-h-[660px] desktop:min-w-[536px]">
          <object data={selectedPdf.url} className="h-full w-full" />
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center rounded-sm bg-neutral-lighter desktop:h-[660px] desktop:min-w-[536px]">
          <p className="flex flex-col items-center gap-2 text-center">
            Selecione um documento para visualizar.
          </p>
        </div>
      )}
    </div>
  );
}
