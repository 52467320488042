import { ReactNode, useState } from "react";
import { queryClient } from "@/lib";
import { TEAM_ACCESS_LIST_KEY } from "@/pages/settings/react-query/team-access-list";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API, Hooks } from "@hyperlocal/banking-utility";
import { Button, Dialog, Drawer, toast } from "@hyperlocal/vital2";

type DeleteUserModalProps = {
  children?: ReactNode;
  profileId: string;
  isOpen?: boolean;
};

export function DeleteUserModal({
  children,
  profileId,
  isOpen,
}: DeleteUserModalProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(false);

  function onOpenChange(open: boolean) {
    setIsModalOpen(open);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function handleDeleteUser() {
    try {
      setIsLoading(true);

      await API.accessControl.delete("/api/UserManager", {
        params: {
          userId: profileId,
        },
      });

      queryClient.invalidateQueries({ queryKey: [TEAM_ACCESS_LIST_KEY] });
      toast({
        variant: "success",
        title: "Usuário excluído",
      });
      handleCloseModal();
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return isMobile ? (
    <Drawer.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}

      <Drawer.Content onOpenAutoFocus={(event) => event.preventDefault()}>
        <Drawer.Header>
          <Drawer.Title>Excluir</Drawer.Title>
        </Drawer.Header>
        <div>
          Ao excluir o usuário, o mesmo não poderá mais acessar sua conta
          digital. Você confirma a exclusão?
        </div>
        <div className="mt-4 flex gap-2">
          <Drawer.Close asChild>
            <Button.Root variant="link" className="w-full">
              Cancelar
            </Button.Root>
          </Drawer.Close>
          <Button.Root
            className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
            disabled={isLoading}
            onClick={handleDeleteUser}
          >
            Excluir
          </Button.Root>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  ) : (
    <Dialog.Root onOpenChange={onOpenChange} open={isModalOpen}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}

      <Dialog.Content className="max-w-[372px]">
        <Dialog.Header>
          <Dialog.Title>Excluir</Dialog.Title>
        </Dialog.Header>
        <div>
          Ao excluir o usuário, o mesmo não poderá mais acessar sua conta
          digital. Você confirma a exclusão?
        </div>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button.Root variant="link" className="w-full">
              Cancelar
            </Button.Root>
          </Dialog.Close>
          <Button.Root
            className="w-full border-none bg-status-error-default outline-none hover:bg-status-error-dark focus:bg-status-error-dark mobile:!mb-2"
            disabled={isLoading}
            onClick={handleDeleteUser}
          >
            Excluir
          </Button.Root>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}
