import { useLogin } from "@/contexts/login";
import { getErrorTranslated } from "@/formatters/cognito";
import Icon from "@hyperlocal/vital-icons";
import { Button, toast } from "@hyperlocal/vital2";
import QRCode from "qrcode.react";

export function TotpSetup() {
  const { filterState, dispatchFilter } = useLogin();

  function goToPreviousStep() {
    dispatchFilter({ type: "SET_STEP", payload: { step: "signIn" } });
  }

  function goToNextStep() {
    dispatchFilter({ type: "SET_STEP", payload: { step: "confirmTotp" } });
  }

  async function handleCopyToClipboard() {
    try {
      await navigator.clipboard.writeText(filterState.totpSetupSecret);
      toast({
        variant: "success",
        title: "Chave copiada!",
        description: filterState.totpSetupSecret,
      });
    } catch (error) {
      toast({
        variant: "error",
        title: "Falha em copiar chave!",
        description: getErrorTranslated(error.message),
      });
    }
  }

  return (
    <div>
      <div className="flex items-center gap-2">
        <button onClick={goToPreviousStep} title="Voltar para o login">
          <Icon
            name="ArrowArrowNoLineLeft"
            width="24"
            height="24"
            fill="currentColor"
          />
        </button>

        <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm/default">
          Configurar app autenticador
        </h3>
      </div>

      <div className="p-6 mobile:!px-0">
        <ol className="mx-5 list-decimal text-neutral-darkest mobile:!flex mobile:!flex-col mobile:!text-x2s/normal">
          <li>
            Baixe o aplicativo{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-primary-main underline"
            >
              Google Authenticator.
            </a>
            <br />
            <span className="text-x3s/normal text-neutral-darker">
              O login com a conta google não é obrigatório.
            </span>
          </li>
          <li>
            Toque no ícone <strong>+</strong> abaixo a direita.
          </li>
          <li>
            Selecione a opção <strong>Ler código QR</strong> e aponte para a
            imagem abaixo.
          </li>
        </ol>

        <QRCode
          value={filterState.totpSetupURL}
          className="mx-auto my-8"
          size={140}
        />

        <div className="mobile:!text-x2s/normal">
          Se não funcionar, selecione a opção{" "}
          <strong>Inserir chave de configuração</strong> e insira seu endereço
          de e-mail e esta chave:
        </div>

        <button
          className="mt-4 flex w-full gap-4 px-6 text-primary-main"
          onClick={handleCopyToClipboard}
        >
          <div className="mobile:!text-2xs/default truncate underline">
            {filterState.totpSetupSecret}
          </div>
          <Icon
            name="GeralGeralCopy"
            width="24"
            height="24"
            fill="currentColor"
            className="shrink-0"
          />
        </button>
      </div>

      <Button.Root
        variant="primary"
        className="mx-auto mt-8 w-[calc(100%-48px)] justify-center mobile:!mt-auto mobile:!w-full"
        onClick={goToNextStep}
      >
        Avançar
      </Button.Root>
    </div>
  );
}
