import styled, { css } from "styled-components";

export const Container = styled.div<{ hasMarginTop: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme, hasMarginTop = true }) =>
    hasMarginTop && theme.spacings.stack.x2s};

  a + a {
    margin-top: 8px;
  }
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x2s};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.sm};
    margin-bottom: ${theme.spacings.stack.x3s};
    text-align: start;
  `}
`;

export const Option = styled.a`
  ${({ theme }) => css`
    padding: ${theme.spacings.squished.sm};
    display: flex;
    border-radius: ${theme.borderRadius.sm};
    border: ${theme.borderWidths.sm} solid ${theme.palette.neutral.main};
    gap: 0px ${theme.spacings.inline.x2s};
    cursor: pointer;

    & > svg {
      width: 24px;
    }
  `}
`;

export const OptionText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};
    flex: 1 auto;
    text-align: start;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x3s};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};
    flex: 1 auto;
    text-align: start;
  `}
`;

export const TextWraper = styled.div`
  width: 100%;
`;
