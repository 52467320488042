import { Bank, BankResponse } from "@/pages/settings/react-query/get-banks";

export function transformBankResponse(response: BankResponse[]): Bank[] {
  const result = response.reduce((bankList, bank) => {
    if (bank?.code) {
      const code = bank.code?.toString();

      const bankCode = code.length <= 2 ? code.padStart(3, "0") : code;

      bankList.push({
        bankCode,
        ispb: bank.ispb,
        bankName: bank.fullName,
        shortBankName: bank.name,
      });
    }
    return bankList;
  }, []);

  return result;
}
