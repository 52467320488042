import { useState } from "react";
import { DashboardPayAnticipationFormData } from "@/components/dashboard-pay/dashboard-pay-anticipation";
import { TextField } from "@hyperlocal/vital2";
import * as Slider from "@radix-ui/react-slider";
import { add, format } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";

type DashboardPayAnticipationFieldsProps = {
  dateMin: string;
  dateMax: string;
  DEFAULT_MIN_DATE: string;
  DEFAULT_MAX_DATE: string;
  disabled: boolean;
};

const formatDate = (rawDate: Date) => format(rawDate, "yyyy-MM-dd");

const getDate = (date: Date) => new Date(date.setHours(0, 0, 0, 0));

export const DashboardPayAnticipationFields = ({
  dateMax,
  dateMin,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  disabled,
}: DashboardPayAnticipationFieldsProps) => {
  const [sliderValue, setSliderValue] = useState([
    new Date(DEFAULT_MIN_DATE).getTime(),
    new Date(DEFAULT_MAX_DATE).getTime(),
  ]);

  const { setValue, control, watch } =
    useFormContext<DashboardPayAnticipationFormData>();

  const handleSliderValueChange = (sliderValue: number[]) => {
    const [, max] = sliderValue;

    const minDate = add(getDate(new Date(DEFAULT_MIN_DATE)), { days: 1 });
    setValue("minDate", formatDate(minDate));

    const maxDate = getDate(new Date(max));
    setValue("maxDate", formatDate(maxDate));

    setSliderValue([minDate.getTime(), maxDate.getTime()]);
  };

  return (
    <>
      <Slider.Root
        min={getDate(new Date(dateMin)).getTime()}
        max={getDate(new Date(dateMax)).getTime()}
        value={sliderValue}
        onValueChange={handleSliderValueChange}
        step={86400000}
        className="relative flex h-5 w-full touch-none select-none items-center"
        disabled={disabled}
      >
        <Slider.Track className="relative h-3 grow rounded-full bg-primary-lighter">
          <Slider.Range className="absolute h-full rounded-full bg-primary-main" />

          <div className="absolute -top-6 flex w-full justify-between text-x3s text-neutral-main">
            <span>{format(new Date(dateMin), "dd/MM/yyyy")}</span>

            <span>{format(new Date(dateMax), "dd/MM/yyyy")}</span>
          </div>
        </Slider.Track>

        <Slider.Thumb className="hidden h-6 w-6 rounded-full bg-primary-dark" />

        <Slider.Thumb className="block h-6 w-6 cursor-grab rounded-full bg-primary-main active:cursor-grabbing" />
      </Slider.Root>

      <div className="grid grid-cols-2 gap-4">
        <Controller
          name="minDate"
          control={control}
          render={({ field: { ...field } }) => (
            <div>
              <TextField.Label htmlFor="startDate">
                Antecipar de
              </TextField.Label>

              <TextField.Root>
                <TextField.Input
                  type="date"
                  min={DEFAULT_MIN_DATE}
                  max={watch("maxDate")}
                  disabled
                  {...field}
                />

                {!disabled && <TextField.Slot name="GeralCalendar" />}
              </TextField.Root>
            </div>
          )}
        />

        <Controller
          name="maxDate"
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <div>
              <TextField.Label htmlFor="endDate">Antecipar até</TextField.Label>

              <TextField.Root>
                <TextField.Input
                  type="date"
                  min={watch("minDate")}
                  max={DEFAULT_MAX_DATE}
                  onChange={(event) => {
                    const date = event.target.value;

                    onChange(date);
                    setSliderValue((prevSliderValue) => {
                      const newSliderValue = [
                        prevSliderValue[0],
                        new Date(date).getTime(),
                      ];

                      return newSliderValue;
                    });
                  }}
                  disabled={disabled}
                  {...field}
                />

                {!disabled && <TextField.Slot name="GeralCalendar" />}
              </TextField.Root>
            </div>
          )}
        />
      </div>
    </>
  );
};
