import { STATUS } from "@/pages/settings/react-query/team-access-list/use-team-access-list.types";

export function getFormattedStatus(status: STATUS) {
  const formattedStatus: Record<STATUS, string> = {
    ACTIVE: "Ativo",
    DELETED: "Excluído",
    EXPIRED: "Expirado",
    PENDING: "Pendente",
  };

  return formattedStatus[status];
}
