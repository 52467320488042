import { useState } from "react";
import { FavoredListItem } from "@/pages/settings/react-query/favored-list";
import { ChangeFavoredSheet } from "@/pages/settings/sections/favored/components/change-favored-sheet";
import { DeleteFavoredModal } from "@/pages/settings/sections/favored/components/delete-favored-modal";
import * as Svg from "@/pages/settings/svgs";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

type TableOptionsProps = {
  profile: FavoredListItem;
};

type OptionActive = "DELETED_MODAL" | "CHANGE_SHEET";

export function TableOptions({ profile }: TableOptionsProps) {
  const [optionActive, setOptionActive] = useState<OptionActive | null>(null);

  function handleSelectItem(option: OptionActive) {
    setOptionActive(option);
  }

  function onOpenChange(open: boolean) {
    if (open) {
      setOptionActive(null);
    }
  }

  return (
    <>
      <DropdownMenu.Root modal={false} onOpenChange={onOpenChange}>
        <DropdownMenu.Trigger
          className="rounded-xs hover:bg-neutral-lighter hover:brightness-95 data-[state=open]:bg-neutral-lighter data-[state=open]:brightness-95"
          data-testid="options-row-trigger"
        >
          <Svg.Dots />
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="min-w-32 overflow-hidden rounded-sm border border-neutral-lighter bg-neutral-white shadow-lower"
            align="end"
            sideOffset={5}
          >
            <DropdownMenu.Item
              className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
              onSelect={() => handleSelectItem("CHANGE_SHEET")}
            >
              <Svg.Edit /> Editar
            </DropdownMenu.Item>

            <DropdownMenu.Item
              className="flex cursor-pointer select-none gap-2.5 border px-4 py-2 text-xs/md font-medium text-neutral-darkest outline-none data-[highlighted]:bg-neutral-lighter data-[disabled]:opacity-30 [&+&]:border-t-neutral-lighter"
              onSelect={() => handleSelectItem("DELETED_MODAL")}
            >
              <Svg.Trash /> Excluir
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {optionActive === "CHANGE_SHEET" && (
        <ChangeFavoredSheet profile={profile} isOpen />
      )}

      {optionActive === "DELETED_MODAL" && (
        <DeleteFavoredModal profileDocument={profile.document} isOpen />
      )}
    </>
  );
}
