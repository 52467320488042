import { ReactNode } from "react";
import { LoginWrapper } from "@/components/login-wrapper";
import { useLogin } from "@/contexts/login";
import { ConfirmTotp } from "@/pages/login/steps/confirm-totp";
import { SignIn } from "@/pages/login/steps/sign-in";
import { TotpSetup } from "@/pages/login/steps/totp-setup";
import { confirmSignIn, fetchAuthSession, setUpTOTP } from "@/utils";
import { toast } from "@hyperlocal/vital2";

type Accounts = {
  Banking: string[];
  [x: string]: string[];
};

export async function handleSetupTOTP(
  setTotpSetupURL: (URL: string, secret: string) => void,
  email: string,
) {
  try {
    const totpSetupDetails = await setUpTOTP();
    const appName = "Hyperlocal";
    const setupUri = totpSetupDetails.getSetupUri(appName, email);

    const href = `${setupUri.href}&algorithm=SHA1`;

    setTotpSetupURL(href, totpSetupDetails.sharedSecret);
  } catch (error) {
    toast({
      variant: "error",
      title: "Houve um erro ao fazer setup do autenticador.",
    });
  }
}

export async function getMFAActive() {
  const authSessionResponse = await fetchAuthSession();

  if (!authSessionResponse.accessToken) return null;

  return (
    authSessionResponse.isBanking === "true" &&
    authSessionResponse.isMfaEnabled === "true"
  );
}

export async function confirmSignInWithCustomChallenge(accountsJson: string) {
  try {
    const account: Accounts = JSON.parse(accountsJson);

    const vertical = "Banking";

    await confirmSignIn({
      challenge: `${vertical};${account.Banking[0]}`,
    });
  } catch (error) {
    toast({
      variant: "error",
      title: "Houve um erro ao confirmar o signIn.",
    });
  }
}

export function Login() {
  const { filterState } = useLogin();

  const steps: Record<typeof filterState.step, ReactNode> = {
    signIn: <SignIn />,
    totpSetup: <TotpSetup />,
    confirmTotp: <ConfirmTotp />,
  };

  return <LoginWrapper>{steps[filterState.step]}</LoginWrapper>;
}
