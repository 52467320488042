import { getInitials } from "@/pages/settings/sections/team-access/components/table";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useAccountStore } from "@hyperlocal/banking-utility";
import { Avatar } from "@hyperlocal/vital2";

export function Account() {
  const { account } = useAccountStore();

  const { name } = account.companyInfoResponse || account.personInfoResponse;

  return (
    <div className="mb-4 flex flex-col items-center">
      <Avatar.Root
        size="lg"
        variant={"primary"}
        className="mb-4 mt-0.5 shrink-0"
      >
        <Avatar.Fallback>{getInitials(name)}</Avatar.Fallback>
      </Avatar.Root>
      <span className="block text-xs/md font-bold text-neutral-darkest">
        {name}
      </span>
      <span className="block text-x2s/md font-regular text-neutral-dark">
        Ag: {account.branch} Conta: {account.accountNumber}-
        {account.accountDigit}
      </span>
    </div>
  );
}
