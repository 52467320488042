import { ReactNode } from "react";
import { useLogin } from "@/contexts/login";
import { twMerge } from "tailwind-merge";
import * as Img from "./images";

interface LoginWrapperProps {
  children: ReactNode;
}

export function LoginWrapper({ children }: LoginWrapperProps) {
  const { filterState } = useLogin();

  return (
    <div className="relative flex min-h-[100dvh] overflow-auto bg-primary-darker mobile:!overflow-hidden">
      <div
        className={twMerge(
          "mx-auto my-stack-xl grid w-full grid-cols-2 justify-between gap-stack-xs px-8 mobile:!mb-0 mobile:!flex mobile:!flex-col mobile:!items-center mobile:!justify-between mobile:!gap-0 mobile:!px-0",
          filterState.step !== "signIn" && "mobile:!mt-0",
        )}
      >
        <div className="justify-self-center">
          <img
            className="mb-[210px] h-fit mobile:!mb-0 mobile:!mt-14"
            src={Img.LogoHyperlocal}
            alt="Logo da Hyperlocal"
            width="136"
            height="64"
          />

          <h2 className="max-w-[560px] font-base text-x2l/sm font-bold text-neutral-white mobile:!hidden">
            Tudo que você precisa, em uma ferramenta fácil de usar.
          </h2>
        </div>

        <div className="relative mt-stack-xl h-fit w-full max-w-[576px] mobile:!max-w-none">
          <div className="relative z-10 flex min-h-[602px] flex-col rounded-md bg-neutral-white px-stack-sm py-stack-xl font-base mobile:!min-h-0 mobile:!rounded-b-none mobile:!px-6 mobile:!py-8">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
