import { useEffect } from "react";
import { DashboardPayChart } from "@/components/dashboard-pay/dashboard-pay-chart";
import { SalesList } from "@/components/dashboard-pay/dashboard-pay-sales-lits";
import { DashboardPayTotalAmount } from "@/components/dashboard-pay/dashboard-pay-total-amount";
import { NavActions, NavItems } from "@/pages/dashboard/components/header";
import {
  getDashboardRoutesData,
  Hooks,
  setHeader,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Breadcrumb, BreadCrumbItem, Divider } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";
import { DashboardContainer } from "../dashboard-container";

export const DashboardPay = () => {
  const routes = getDashboardRoutesData();
  const navigate = useNavigate();
  const isMobile = Hooks.useMediaQuery("mobile");

  const dashboardPayNavItems: NavItems[] = [
    {
      label: "Vendas",
      icon: "GeralMoneyPayReceived",
      to: routes.salesReport,
      type: "link",
    },
    {
      label: "Recebimentos",
      icon: "GeralMoneyMoney",
      to: routes.futureReceipts,
      type: "link",
    },
    {
      label: "Agenda futura",
      icon: "MoneyAntecipate",
      type: "link",
      to: routes?.futureReceiptsCalendar,
    },
    {
      label: "Cobrança",
      icon: "GeralMoneyPayDemand",
      type: "link",
      to: routes?.charge,
      allowedRoles: [
        "Administrador",
        "Financeiro",
        "Moderador",
        "Proprietário",
      ],
    },
  ];

  useEffect(() => {
    isMobile &&
      setHeader({
        title: "Menu Pay",
        leftIcon: {
          name: "ArrowArrowNoLineLeft",
          onClick: () => navigate(routes.dashboard),
        },
      });
  }, [isMobile, routes]);

  return (
    <>
      <div className="h-full overflow-y-auto bg-neutral-lighter font-base mobile:!hidden">
        <DashboardContainer>
          <Breadcrumb onClickIcon={() => navigate(routes.dashboard)}>
            <BreadCrumbItem active>Pay</BreadCrumbItem>
          </Breadcrumb>

          <div className="my-[24px] flex items-center gap-2">
            <button
              className="flex h-6 w-6 cursor-pointer items-center justify-center"
              onClick={() => navigate(routes.dashboard)}
            >
              <Icon
                name="ArrowNoLineLeft"
                width={16}
                height={16}
                className="fill-neutral-darkest"
              />
            </button>

            <h1 className="text-lg font-bold text-neutral-darkest">Pay</h1>
          </div>

          <section className="relative grid grid-cols-2 gap-inline-sm">
            <aside className="flex max-w-[584px] flex-col gap-stack-x2s">
              <div className="flex gap-3">
                <NavActions
                  navItems={dashboardPayNavItems}
                  isMobile={false}
                  statusFetched={true}
                />
              </div>

              <div className="flex flex-col gap-stack-x3s rounded-sm bg-white p-squished-lg [&>.balance>section]:border-none [&>.balance>section]:p-0">
                <Parcel
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  config={() => System.import("@hyperlocal/banking-balance")}
                  mountParcel={mountRootParcel}
                  wrapClassName="balance"
                />

                <Divider />

                <DashboardPayTotalAmount />
              </div>

              <DashboardPayChart />
            </aside>

            <aside className="absolute inset-0 col-start-2 h-full w-full overflow-hidden mobile:!contents">
              <div className="flex h-full flex-col gap-6 rounded-sm bg-neutral-white mobile:!relative mobile:!order-3 mobile:!overflow-hidden mobile:!bg-transparent mobile:!p-0">
                <SalesList />
              </div>
            </aside>
          </section>
        </DashboardContainer>
      </div>

      <div className="hidden min-h-screen flex-col mobile:!flex">
        <div className="[&>.balance>section]:bg-neutral-lighter">
          <Parcel
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            config={() => System.import("@hyperlocal/banking-balance")}
            mountParcel={mountRootParcel}
            wrapClassName="balance"
          />
        </div>

        <div className="flex flex-1 flex-col bg-neutral-white">
          <div className="flex flex-col gap-4 px-4 pt-6">
            <DashboardPayTotalAmount />

            <div className="grid grid-cols-4 gap-2">
              {dashboardPayNavItems.map((item) => {
                return (
                  <div
                    key={item.label}
                    className={twMerge(
                      "flex aspect-square cursor-pointer flex-col items-center justify-center gap-1",
                      item.actionsBlocked &&
                        "pointer-events-none cursor-not-allowed opacity-30",
                    )}
                    onClick={() =>
                      !item.actionsBlocked &&
                      item.type === "link" &&
                      navigate(item.to)
                    }
                  >
                    <div className="flex h-12 w-12 items-center justify-center rounded-full border border-primary-main text-primary-main">
                      <Icon
                        name={item.icon}
                        className="h-6 w-6 fill-primary-main"
                      />
                    </div>

                    <p className="text-x4s text-neutral-darkest">
                      {item.label}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="[&>.statements_h5]:!text-[12px] [&>.statements_h5]:!font-medium [&>.statements_h5]:!text-neutral-dark">
            <SalesList />
          </div>
        </div>
      </div>
    </>
  );
};
