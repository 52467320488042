import { ReactNode, useState } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { queryClient } from "@/lib";
import {
  FAVORED_LIST_KEY,
  FavoredListItem,
} from "@/pages/settings/react-query/favored-list";
import { useGetBanks } from "@/pages/settings/react-query/get-banks";
import { AddNewBankForm } from "@/pages/settings/sections/favored/components/add-new-bank-form";
import { DeleteBankAccountModal } from "@/pages/settings/sections/favored/components/delete-bank-account-modal";
import {
  CNPJ_LENGTH,
  CPF_LENGTH,
} from "@/pages/settings/sections/favored/components/new-favored-sheet-helper";
import * as Svg from "@/pages/settings/svgs";
import { getPixAccountNumber } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Button, Sheet, TextField, toast } from "@hyperlocal/vital2";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { z } from "zod";

const changeFavoredSchema = z.object({
  name: z.string().min(1, "O nome é obrigatório"),
  document: z
    .string()
    .min(1, "O cpf ou cnpj é obrigatório")
    .transform((val, ctx) => {
      const parsed = val.match(/\d/g)?.join("");

      if (parsed.length < CPF_LENGTH) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Faltam ${CPF_LENGTH - parsed.length} dígitos`,
        });

        return z.NEVER;
      }

      if (parsed.length > CPF_LENGTH && parsed.length < CNPJ_LENGTH) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Faltam ${CNPJ_LENGTH - parsed.length} dígitos`,
        });

        return z.NEVER;
      }

      return parsed;
    }),
});

type ChangeFavoredSchemaInput = z.input<typeof changeFavoredSchema>;
type ChangeFavoredSchemaOutput = z.infer<typeof changeFavoredSchema>;

type ChangeFavoredSheetProps = {
  children?: ReactNode;
  profile: FavoredListItem;
  isOpen?: boolean;
};

export function ChangeFavoredSheet({
  children,
  profile,
  isOpen,
}: Readonly<ChangeFavoredSheetProps>) {
  const [isAddNewBank, setIsAddNewBank] = useState(false);
  const [isSheetOpen, setIsSheetOpen] = useState(isOpen);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const { data } = useGetBanks();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<ChangeFavoredSchemaInput>({
    resolver: zodResolver(changeFavoredSchema),
    mode: "onTouched",
    values: {
      document: profile?.document,
      name: profile?.name,
    },
  });

  const registerWithMask = useHookFormMask(register);

  function onOpenChange(open: boolean) {
    if (open) {
      reset();
    }

    setIsSheetOpen(open);
  }

  function handleCloseSheet() {
    setIsSheetOpen(false);
  }

  async function handleFormSubmit(data: unknown) {
    try {
      setIsSendingForm(true);

      const formData = data as ChangeFavoredSchemaOutput;

      await API.contact.put("/Contact", {
        document: formData.document,
        contactName: formData.name,
        accounts: profile.accounts,
      });
      queryClient.invalidateQueries({ queryKey: [FAVORED_LIST_KEY] });

      toast({
        variant: "success",
        title: "Dados alterados",
      });
      handleCloseSheet();
    } catch (error) {
      console.error(error);

      if (error.name === "ZodError") {
        toast({
          variant: "error",
          title: "Erro ao validar os campos",
          description: "A validação dos campos falhou.",
        });

        return;
      }

      toast({
        variant: "error",
        title: "Erro ao enviar os dados",
        description: "Tente novamente mais tarde.",
      });
    } finally {
      setIsSendingForm(false);
    }
  }

  function handleOpenAddNewBankForm() {
    setIsAddNewBank(true);
  }

  const values = watch();
  const hasErrors =
    !changeFavoredSchema.safeParse(values).success ||
    values.name === profile.name;

  const handleBankAccountLabel = (
    account: FavoredListItem["accounts"][number],
  ) => {
    if (account.transferType === "pix") {
      const bank = data.find((bank) => bank.ispb === account.ispb);

      const { accountNumber, accountDigit } = getPixAccountNumber(
        account.accountNumber ?? "",
      );
      return {
        title: `Chave Pix${!account.pixKey ? " - " + bank.shortBankName : ""}`,
        info:
          account.pixKey ||
          `Ag ${account.branch ?? "xxxx"} • Conta ${accountNumber} - ${accountDigit}`,
      };
    }

    return {
      title: account.bankName || "Banco não informado",
      info: `Ag ${account.branch ?? "xxxx"} • Conta ${account.accountNumber ?? "xxxxx"}-${account.accountDigit ?? "x"}`,
    };
  };

  return (
    <Sheet.Root onOpenChange={onOpenChange} open={isSheetOpen}>
      {children && <Sheet.Trigger asChild>{children}</Sheet.Trigger>}

      <Sheet.Content
        onOpenAutoFocus={(event) => event.preventDefault()}
        className="px-0 font-base"
      >
        <Sheet.Header className="px-4">
          <Sheet.Title>{profile.name}</Sheet.Title>
        </Sheet.Header>

        <form
          className="mt-4 flex flex-1 flex-col justify-between gap-12"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="flex flex-col space-y-6 overflow-hidden">
            <div className="px-4">
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="cpf"
              >
                CPF/CNPJ
              </TextField.Label>
              <TextField.Input
                id="cpf"
                data-status={errors.document && "error"}
                className="tabular-nums"
                placeholder="___.___.___-__"
                disabled
                {...registerWithMask("document", [
                  "999.999.999-99",
                  "99.999.999/9999-99",
                ])}
              />
              {errors.document && (
                <TextField.Helper>{errors.document.message}</TextField.Helper>
              )}
            </div>

            <div className="px-4">
              <TextField.Label
                className="mobile:!text-x2s/default"
                htmlFor="name"
              >
                Nome do contato
              </TextField.Label>
              <TextField.Input
                id="name"
                autoComplete="name"
                placeholder="Digite o nome"
                data-status={errors.name && "error"}
                {...register("name")}
              />
              {errors.name && (
                <TextField.Helper>{errors.name.message}</TextField.Helper>
              )}
            </div>

            <div className="flex flex-col overflow-hidden">
              <h3 className="mb-4 px-4 text-sm/md font-bold text-neutral-darkest">
                Dados bancários
              </h3>

              <div className="-mr-4 flex flex-col overflow-hidden px-4">
                <ScrollArea>
                  <div className="space-y-4 pr-4">
                    {profile.accounts.map((account) => (
                      <div
                        className="flex items-center justify-between rounded-sm px-4 py-2 ring-1 ring-inset ring-neutral-light"
                        key={account.id}
                      >
                        <div className="flex items-center gap-2">
                          <Icon
                            name={
                              account.transferType === "pix"
                                ? "MoneyPix"
                                : "GeralBank"
                            }
                            width={16}
                            height={16}
                            className="box-content shrink-0 rounded-full fill-primary-main p-3 ring-1 ring-inset ring-primary-main"
                          />

                          <div className="text-neutral-darkest">
                            <span className="mb-px block text-x2s/md">
                              {handleBankAccountLabel(account).title}
                            </span>
                            <span className="block text-xs/md">
                              {handleBankAccountLabel(account).info}
                            </span>
                          </div>
                        </div>

                        <DeleteBankAccountModal
                          accountId={account.id}
                          profile={profile}
                        >
                          <button className="text-neutral-darkest">
                            <Svg.Trash />
                          </button>
                        </DeleteBankAccountModal>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </div>

              {isAddNewBank ? (
                <AddNewBankForm
                  setIsAddNewBank={setIsAddNewBank}
                  profile={profile}
                />
              ) : (
                <Button.Root
                  className="mx-4 mt-4 w-auto"
                  variant="link"
                  onClick={handleOpenAddNewBankForm}
                >
                  Adicionar dados bancários
                </Button.Root>
              )}
            </div>
          </div>

          <div className="flex gap-1 px-4">
            <Button.Root
              type="button"
              variant="link"
              className="w-full"
              onClick={handleCloseSheet}
            >
              Cancelar
            </Button.Root>

            <Button.Root
              variant="primary"
              className="w-full whitespace-nowrap"
              disabled={hasErrors}
              isLoading={isSendingForm}
            >
              Salvar alteração
            </Button.Root>
          </div>
        </form>
      </Sheet.Content>
    </Sheet.Root>
  );
}
