import { ReactNode } from "react";
import { LoginWrapper } from "@/components/login-wrapper";
import { useResetPassword } from "@/contexts/reset-password";
import { ChangePassword } from "@/pages/reset-password/steps/change-password";
import { ConfirmEmailTotp } from "@/pages/reset-password/steps/confirm-email-totp";
import { ForgotPassword } from "@/pages/reset-password/steps/forgot-password";

export function ResetPassword() {
  const { filterState } = useResetPassword();

  const steps: Record<typeof filterState.step, ReactNode> = {
    forgotPassword: <ForgotPassword />,
    confirmEmailTotp: <ConfirmEmailTotp />,
    changePassword: <ChangePassword />,
  };

  return <LoginWrapper>{steps[filterState.step]}</LoginWrapper>;
}
