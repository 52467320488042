import { Suspense } from "react";
import EmptyLogo from "@/assets/lupinha.png";
import { Spinner } from "@/components/spinner";
import { SummaryInfos } from "@/pages/pending-transactions/tabs/history/components/summary-infos";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const classNames = {
  common: "rounded-sm bg-white mobile:p-4 p-6 h-full w-full",
};

export const Summary = () => {
  const [searchParams] = useSearchParams();
  const activeTransactionId = searchParams.get("transaction");

  if (!activeTransactionId)
    return (
      <div
        className={twMerge(
          "flex flex-1 flex-col items-center justify-center",
          classNames.common,
        )}
      >
        <img src={EmptyLogo} alt="" />

        <p className="text-center text-[#A6A6A6]">
          Aqui está vazio! Nenhum pedido foi selecionado. <br />
          Por favor, selecione uma transferência.
        </p>
      </div>
    );

  return (
    <div className={twMerge(classNames.common)}>
      <Suspense fallback={<Spinner />}>
        <SummaryInfos transactionId={activeTransactionId} />
      </Suspense>
    </div>
  );
};
