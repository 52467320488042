import { ComponentProps, Suspense, useEffect } from "react";
import { usePendingTransactions } from "@/pages/pending-transactions/common";
import { hasPermission } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

type WarningComponentProps = Pick<ComponentProps<"div">, "className">;
const WarningComponent = ({ className }: WarningComponentProps) => {
  const { data } = usePendingTransactions();

  const commonClassName = twMerge(
    "absolute right-3 top-3 size-3 mobile:right-2 mobile:top-2",
    className,
  );

  if (data.length > 0) {
    return (
      <>
        <div
          className={twMerge(
            commonClassName,
            "animate-warning-pulse rounded-full border border-status-error-default",
          )}
        />

        <div
          className={twMerge(
            commonClassName,
            "rounded-full bg-status-error-default",
          )}
        />
      </>
    );
  }

  return <></>;
};

export const Warning = (props: WarningComponentProps) => (
  <Suspense fallback={<></>}>
    <WarningComponent {...props} />
  </Suspense>
);

const Toast = () => {
  const { data } = usePendingTransactions();

  useEffect(() => {
    if (data.length && hasPermission(["Administrador", "Proprietário"]))
      toast({
        title: "Lançamentos para aprovar",
        description: `Você tem ${data.length} lançamentos pendentes.`,
      });
  }, [data]);

  return <></>;
};

export const WarningToast = () => (
  <Suspense fallback={<></>}>
    <Toast />
  </Suspense>
);
