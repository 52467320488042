import { useState } from "react";
import EmptyLogo from "@/assets/lupinha.png";
import { ScrollArea } from "@/components/scroll-area";
import { TransactionActionDialog } from "@/pages/pending-transactions/tabs/pending/components/dialogs";
import { SummaryItem } from "@/pages/pending-transactions/tabs/pending/components/summary-item";
import { PendingTransaction } from "@/services/types/get-pending-transactions";
import {
  formatCurrency,
  Hooks,
  ProtectedComponent,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import * as Accordion from "@radix-ui/react-accordion";
import { useSearchParams } from "react-router-dom";

const EmptyComponent = () => (
  <div className="flex flex-1 flex-col items-center justify-center">
    <img src={EmptyLogo} alt="" />

    <p className="text-center text-[#A6A6A6]">
      Aqui está vazio! Nenhum pedido foi selecionado. <br />
      Por favor, selecione uma transferência.
    </p>
  </div>
);

type ListProps = {
  selectedTransactions: PendingTransaction[];
};

const List = ({ selectedTransactions }: ListProps) => {
  const [value, setValue] = useState("");
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <div className="gap- flex-1 flex-col space-y-6">
      {!isMobile && (
        <h4 className="mobile: text-[20px] font-bold text-neutral-darkest">
          Lançamentos
        </h4>
      )}

      <div className="space-y-2">
        <h5 className="font-bold text-neutral-darkest">Transações</h5>

        <ScrollArea className="h-[450px]">
          <Accordion.Root
            type="single"
            collapsible
            className="space-y-2"
            value={value}
            onValueChange={setValue}
          >
            {selectedTransactions.map((transaction) => (
              <SummaryItem
                transaction={transaction}
                key={transaction.TransactionId}
                isOpen={value === transaction.TransactionId}
              />
            ))}
          </Accordion.Root>
        </ScrollArea>
      </div>
    </div>
  );
};

type SummaryProps = { data: PendingTransaction[] };
export const Summary = ({ data }: SummaryProps) => {
  const [searchParams] = useSearchParams();
  const selectedTransactionsIds = searchParams.getAll("transaction");
  const hasTransactions = selectedTransactionsIds.length > 0;

  const selectedTransactions = data.filter((transaction) =>
    selectedTransactionsIds.includes(transaction.TransactionId),
  );

  const sum = selectedTransactions.reduce((acc, item) => acc + item.Amount, 0);

  return (
    <div className="flex flex-col gap-4 rounded-sm bg-white px-4 py-4 mobile:h-[calc(100svh-56px)] desktop:h-[727px] desktop:px-6">
      {hasTransactions ? (
        <List selectedTransactions={selectedTransactions} />
      ) : (
        <EmptyComponent />
      )}

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-[20px] font-bold">Valor do lançamento</span>

          <span className="text-[20px] font-bold">
            <span className="text-[16px]">R$ </span>
            {formatCurrency(sum).replace("R$", "")}
          </span>
        </div>

        <ProtectedComponent allowedRoles={["Proprietário", "Administrador"]}>
          <div className="flex flex-col space-y-2">
            <TransactionActionDialog />
          </div>
        </ProtectedComponent>
      </div>
    </div>
  );
};
