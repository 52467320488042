import React from "react";
import ContentList from "@/components/drawer-contents/content-list";
import { IDrawerContentOption } from "@/components/drawer-contents/content-list/types";
import { getDashboardRoutesData, Hooks } from "@/utils";
import { Dialog, Sheet } from "@hyperlocal/vital2";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

export function PaymentModal({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isMobile = Hooks.useMediaQuery("mobile");

  const routes = getDashboardRoutesData();

  const navigate = useNavigate();

  const paymentDesktopData: IDrawerContentOption[] = [
    {
      icon: "ShopBarcode",
      text: "Boleto",
      action: () =>
        navigate(
          isMobile ? routes.cashOutBoleto : routes.cashOutBoletoCopiaCola,
        ),
    },
    {
      icon: "MoneyPix",
      text: "Pix",
      action: () =>
        navigate(isMobile ? routes.cashOutPix : routes.cashOutPixCopiaCola),
    },
  ];

  const ModalContent = () => (
    <>
      <p className="text-xs font-bold leading-7 text-primary-main">Pagar</p>
      <div className="w-full border-[0.5px] border-neutral-lighter"></div>

      <ContentList
        description="Escolha como deseja pagar:"
        options={paymentDesktopData}
        hasMarginTop={false}
      />
    </>
  );

  const ModalWrapper = isMobile ? (
    <Sheet.Root open={isModalOpen} onOpenChange={() => setIsModalOpen(false)}>
      <Sheet.Content side="bottom">
        <ModalContent />
      </Sheet.Content>
    </Sheet.Root>
  ) : (
    <Dialog.Root open={isModalOpen} onOpenChange={() => setIsModalOpen(false)}>
      <Dialog.Content className="max-w-[372px]">
        <ModalContent />
      </Dialog.Content>
    </Dialog.Root>
  );

  return ReactDOM.createPortal(
    ModalWrapper,
    document.getElementById("modal-root"),
  );
}
