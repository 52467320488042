// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";

export type AnticipationDetails = {
  spotAnticipationRequest: {
    guidSpotRequests: string;
    guidCustomer: string;
    anticipationExecuteDate: string;
    internalApprovalDate: string;
    internalApprovalOcurrence?: string;
    spotRequestType: string;
    cardBrand: null;
    status: string;
    prepaymentGrossAmountRequested: number;
    prepaymentNetAmountRequested: number;
    receivablesCountRequested: number;
    prepaymentGrossAmountPaid: number;
    prepaymentNetAmountPaid: number;
    paymentDate: string;
    receivablesCountPaid: number;
    fee: number;
    createdAt: string;
    updatedAt: string;
    anticipationOcurrenceMessage: string;
  };
  spotRequestReceivables: Array<{
    guidSpotRequests: string;
    nsu: string;
    installmentNsu: string;
    transactionDate: string;
    originalPaymentDate: string;
    saleAmount: number;
    installmentAmount: number;
    paymentAmountToMerchant: number;
    mdr: number;
    anticipationAmount: number;
    createdAt: string;
    receivableAnticipationSended: number;
  }>;
};

const getAnticipationDetails = async (id: string) => {
  const anticipation = await API.spot.get<AnticipationDetails>(
    "/api/SpotReceivables/ListAnticipationsRequestDetails",
    {
      params: {
        guidSpotRequests: id,
      },
    },
  );

  return anticipation.data;
};

const query = (id: string) => {
  return {
    queryKey: ["anticipation", id],
    queryFn: () => getAnticipationDetails(id),
  };
};

export const useAnticipationDetails = (id: string) => {
  return useQuery({
    ...query(id),
  });
};
