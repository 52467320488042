import { ArrowRefresh } from "@/assets/svgs";
import { Button, Dialog } from "@hyperlocal/vital2";

export type ConfirmationDialogProps = {
  onClose: () => void;
};

export function ConfirmationDialog({ onClose }: ConfirmationDialogProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button.Root variant="primary">
          <ArrowRefresh />
          Gerar QR Code
        </Button.Root>
      </Dialog.Trigger>
      <Dialog.Content>
        <div className="mt-6 flex flex-col justify-center gap-2 text-center">
          <h4 className="text-md font-bold">Atenção!</h4>
          <span>
            Para garantir a segurança da sua conta, é{" "}
            <strong>OBRIGATÓRIO</strong> usar o Google Authenticator. Abra o
            aplicativo e escaneie o QR Code diretamente nele. Isso é fundamental
            para que a autenticação em dois fatores funcione corretamente.
          </span>
        </div>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button.Root fullWidth variant="primary" onClick={onClose}>
              Já baixei
            </Button.Root>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
}
