import * as Svg from "@/pages/settings/svgs";
import { TextField } from "@hyperlocal/vital2";

interface TablePaginationProps {
  pagesCount: number;
  isLoading: boolean;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export function TablePagination({
  pagesCount,
  isLoading,
  setPageNumber,
  pageNumber,
}: TablePaginationProps) {
  function handlePrevPage() {
    setPageNumber((oldNumber) => oldNumber - 1);
  }

  function handleNextPage() {
    setPageNumber((oldNumber) => oldNumber + 1);
  }

  const isPrevButtonDisabled = pageNumber <= 1;
  const isNextButtonDisabled = pageNumber >= pagesCount;

  return (
    <div className="flex items-center justify-end gap-2 px-2 py-3 text-neutral-darker">
      <button
        data-testid="pagination-left-button"
        className="rounded-xs text-neutral-main hover:enabled:bg-neutral-lighter disabled:opacity-30"
        onClick={handlePrevPage}
        disabled={isPrevButtonDisabled}
      >
        <Svg.ArrowLeft />
      </button>
      <TextField.Input
        data-testid="page-number-input"
        className="h-6 w-[38px] !p-0 text-center disabled:text-neutral-light"
        type="number"
        min="1"
        value={pageNumber}
        disabled={isLoading}
        onChange={(event) => setPageNumber(Number(event.target.value))}
      />
      <span>de</span>
      <span className="w-8 text-center">{isLoading ? "1" : pagesCount}</span>
      <button
        data-testid="pagination-right-button"
        className="rounded-xs text-neutral-main hover:enabled:bg-neutral-lighter disabled:opacity-30"
        onClick={handleNextPage}
        disabled={isNextButtonDisabled}
      >
        <Svg.ArrowRight />
      </button>
    </div>
  );
}
