import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface DashboardContainerProps {
  children: ReactNode;
}

export function DashboardContainer({ children }: DashboardContainerProps) {
  const mobileClassNames = "mobile:!overflow-hidden mobile:!h-full";
  const desktopClassNames = "desktop:my-inset-lg desktop:px-16 ";
  const commonClassNames = "mx-auto w-full max-w-[1248px] flex-1 flex flex-col";

  return (
    <div
      className={twMerge(mobileClassNames, commonClassNames, desktopClassNames)}
    >
      {children}
    </div>
  );
}
