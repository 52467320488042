import { useState } from "react";
import { useLogin } from "@/contexts/login";
import { getErrorTranslated } from "@/formatters/cognito";
import { SuccessModal } from "@/pages/login/components/success.modal";
import { confirmSignInWithCustomChallenge } from "@/pages/login/login";
import {
  confirmSignIn,
  fetchAuthSession,
  getDashboardRoutesData,
  updateMFAPreference,
  verifyTOTPSetup,
} from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Button, toast, Token } from "@hyperlocal/vital2";
import { useNavigate } from "react-router-dom";

export function ConfirmTotp() {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [isVerifyingToken, setIsVerifyingToken] = useState(false);
  const routes = getDashboardRoutesData();
  const navigate = useNavigate();
  const [totpCode, setTotpCode] = useState("");
  const { filterState, dispatchFilter } = useLogin();

  function handleCloseModal() {
    setSuccessModalOpen(false);
    navigate(routes.dashboard);
  }

  function goToPreviousStep() {
    dispatchFilter({
      type: "SET_STEP",
      payload: {
        step: filterState.totpSetupURL ? "totpSetup" : "signIn",
      },
    });
  }

  async function handleTOTPVerification() {
    try {
      setIsVerifyingToken(true);

      await verifyTOTPSetup({ code: totpCode });
      await updateMFAPreference({ totp: "PREFERRED" });
      await fetchAuthSession({ forceRefresh: true });

      setSuccessModalOpen(true);
    } catch (error) {
      toast({
        variant: "error",
        title: "Erro ao confirmar o código",
        description: getErrorTranslated(error.message),
      });
    } finally {
      setIsVerifyingToken(false);
    }
  }

  async function handleConfirmTotpCode() {
    try {
      setIsVerifyingToken(true);

      const { nextStep } = await confirmSignIn({ challenge: totpCode });

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
        await confirmSignInWithCustomChallenge(
          nextStep.additionalInfo.accountsJson,
        );

        await fetchAuthSession({ forceRefresh: true });

        window.location.href = getDashboardRoutesData().dashboard;
      }
    } catch (error) {
      toast({
        variant: "error",
        title: "Houve um erro ao confirmar o código.",
        description: getErrorTranslated(error.message),
      });
    } finally {
      setIsVerifyingToken(false);
    }
  }

  async function handleVerification() {
    filterState.totpSetupURL
      ? await handleTOTPVerification()
      : await handleConfirmTotpCode();
  }

  const isDisabled = totpCode.length !== 6;

  function handleKeyDown(event) {
    if (event.key === "Enter" && !isDisabled) {
      handleVerification();
    }
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex items-center gap-2">
        <button onClick={goToPreviousStep} title="Voltar para o login">
          <Icon
            name="ArrowArrowNoLineLeft"
            width="24"
            height="24"
            fill="currentColor"
          />
        </button>

        <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm/default">
          Aprovar solicitação
        </h3>
      </div>

      <div className="p-6 mobile:!px-0">
        <h2 className="mb-5 text-x2s/normal text-primary-main">
          {filterState.email}
        </h2>

        <ul className="mx-5 list-disc text-neutral-darkest mobile:!text-x2s/normal">
          <li>
            Abra seu aplicativo Google Authenticator e insira o código de seis
            dígitos exibido no aplicativo.
          </li>
        </ul>

        <div className="mx-auto mt-8 w-fit">
          <Token.Label className="mobile:!text-x2s/default">
            Digite o código
          </Token.Label>
          <Token.Root onValueChange={setTotpCode} onKeyDown={handleKeyDown}>
            <Token.Input />
            <Token.Input />
            <Token.Input />
            <Token.Input />
            <Token.Input />
            <Token.Input />
          </Token.Root>
        </div>

        <SuccessModal
          isOpen={successModalOpen}
          onCloseModal={handleCloseModal}
        />
      </div>

      <Button.Root
        variant="primary"
        isLoading={isVerifyingToken}
        className="mx-auto mt-8 w-[calc(100%-48px)] justify-center mobile:!w-full"
        onClick={handleVerification}
        disabled={isDisabled}
      >
        Verificar
      </Button.Root>
    </div>
  );
}
