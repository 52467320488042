import { ComponentProps } from "react";
import { Button } from "@hyperlocal/vital";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";

export const ErrorFallback = ({ children }: ComponentProps<"div">) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <div
              className="grid h-full place-items-center overflow-y-auto rounded-sm bg-neutral-white p-6"
              role="alert"
              data-type="errorFallback"
            >
              <div className="flex flex-col items-center gap-2">
                <p className="text-xs/[48px] font-regular text-neutral-dark">
                  Desculpe, ocorreu um erro. Por favor, tente novamente mais
                  tarde.
                </p>
                <Button variant="link" onClick={resetErrorBoundary}>
                  Recarregar
                </Button>
              </div>
            </div>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
