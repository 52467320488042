import React, { ReactNode } from "react";
import { Breadcrumb, BreadcrumbItem } from "@/components/breadcrumb";
import { DashboardContainer } from "@/components/dashboard-container";
import { ErrorFallback } from "@/components/error-fallback";
import { ScrollArea } from "@/components/scroll-area";
import { Account } from "@/pages/settings/components/account";
import { Navigation } from "@/pages/settings/components/navigation";
import { ProductListFilterProvider } from "@/pages/settings/contexts/team-access-filter";
import { AccountData } from "@/pages/settings/sections/account-data";
import { Favored } from "@/pages/settings/sections/favored";
import { NewFavoredSheet } from "@/pages/settings/sections/favored/components/new-favored-sheet";
import { Fees } from "@/pages/settings/sections/fees";
import { LimitManagement } from "@/pages/settings/sections/limit-management";
import { TeamAccess } from "@/pages/settings/sections/team-access";
import * as Svg from "@/pages/settings/svgs";
import {
  getDashboardRoutesData,
  hasPermission,
  Hooks,
  setHeader,
} from "@/utils";
import { editEquipAccess, seeAccountData } from "@/utils/allowed-roles";
import { Button } from "@hyperlocal/vital2";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";

const routes = getDashboardRoutesData();

const breadcrumbs: BreadcrumbItem[] = [
  { name: "Configurações", path: "settings", active: true },
];

export type TabParam =
  | "dados-da-conta"
  | "acesso-da-equipe"
  | "gestao-de-limites"
  | "notificacoes"
  | "favorecidos"
  | "tarifas";

const getMobileHeaderTitle = (tabParam: TabParam) => {
  if (!tabParam) return "Configurações";

  const tabMap: Record<TabParam, string> = {
    tarifas: "Tarifas",
    notificacoes: "Notificações",
    "acesso-da-equipe": "Acesso da equipe",
    "dados-da-conta": "Dados da conta",
    "gestao-de-limites": "Gestão de limites",
    favorecidos: "Favorecidos",
  };

  return tabMap[tabParam];
};

const getDefaultParam = (isMobile: boolean) => {
  if (isMobile) return "";

  if (!hasPermission(seeAccountData)) return "acesso-da-equipe";

  return "dados-da-conta";
};

export function Settings() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const [searchParam, setSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  function handleChangeTabParam(tab: TabParam) {
    setSearchParam(`tab=${tab}`);
  }

  const activeParam = (searchParam.get("tab") ||
    getDefaultParam(isMobile)) as TabParam;

  const sections: Record<TabParam, ReactNode> = {
    "dados-da-conta": <AccountData />,
    "acesso-da-equipe": (
      <ProductListFilterProvider>
        <TeamAccess />
      </ProductListFilterProvider>
    ),
    "gestao-de-limites": <LimitManagement />,
    tarifas: <Fees />,
    notificacoes: <div />,
    favorecidos: <Favored />,
  };

  const activeSection = sections[activeParam];

  React.useEffect(() => {
    if (!isMobile) return;
    setHeader({
      title: getMobileHeaderTitle(activeParam),
      show: isMobile,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigate(-1),
      },
    });
  }, [location.search, isMobile, navigate, activeParam]);

  return (
    <div className="h-full overflow-y-auto bg-neutral-lighter font-base mobile:!bg-neutral-white">
      <DashboardContainer>
        <header className="mobile:!hidden tablet:!hidden">
          <Breadcrumb breadcrumbs={breadcrumbs} />

          <div className="mt-4 flex items-center justify-between">
            <Link
              className="flex w-fit items-center gap-2 py-2 mobile:!m-4"
              to={routes.dashboard}
            >
              <Svg.ArrowDown />
              <h3 className="text-lg/default font-bold text-neutral-darkest mobile:!text-sm">
                Configurações
              </h3>
            </Link>

            {activeParam === "acesso-da-equipe" &&
              hasPermission(editEquipAccess) && (
                <Button.Root onClick={() => navigate(routes.settingsNewAccess)}>
                  Novo acesso
                </Button.Root>
              )}

            {activeParam === "favorecidos" && <NewFavoredSheet />}
          </div>
        </header>

        <section className="relative mt-4 flex-1 grid-cols-[1fr_2fr] gap-inset-lg overflow-hidden mobile:!mt-0 mobile:!flex mobile:!flex-col mobile:!gap-0 mobile:!overflow-auto mobile:!px-4 mobile:!pb-4 mobile:!pt-2 desktop:grid desktop:max-h-[800px] desktop:min-h-[800px]">
          <div
            className={twMerge(
              "shrink-0 overflow-hidden rounded-sm bg-neutral-white p-6 mobile:!px-2 mobile:!pb-6",
              isMobile && activeParam && "hidden",
            )}
          >
            <div className="-mr-6 flex h-full flex-col">
              <ScrollArea>
                <div className="pr-6">
                  <ErrorFallback>
                    <Account />
                    <Navigation
                      activeParam={activeParam}
                      handleChangeTabParam={handleChangeTabParam}
                    />
                  </ErrorFallback>
                </div>
              </ScrollArea>
            </div>
          </div>

          <div className="absolute inset-0 col-start-2 overflow-hidden rounded-sm bg-neutral-white p-6 mobile:!contents first:[&>[data-type=errorFallback]]:row-start-2">
            <ErrorFallback>{activeSection}</ErrorFallback>
          </div>
        </section>
      </DashboardContainer>
    </div>
  );
}
