import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { Action, State } from "@/contexts/reset-password/types";

const initialState: State = {
  step: "forgotPassword",
  email: "",
  password: "",
  securityEmail: "",
  totpCode: "",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_TOTP_CODE":
      return {
        ...state,
        step: "changePassword",
        totpCode: action.payload.totpCode,
      };
    case "SET_EMAIL":
      return { ...state, email: action.payload.email };
    case "SET_PASSWORD":
      return { ...state, password: action.payload.password };
    case "SET_STEP":
      return { ...state, step: action.payload.step };
    case "SET_SECURITY_EMAIL":
      return { ...state, securityEmail: action.payload.securityEmail };
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

export interface Context {
  filterState: State;
  dispatchFilter: React.Dispatch<Action>;
}

export const ResetPasswordContext = createContext<Context>({
  filterState: initialState,
  dispatchFilter: () => null,
});

interface ResetPasswordProviderProps {
  children: ReactNode;
}

export function ResetPasswordProvider({
  children,
}: ResetPasswordProviderProps) {
  const [filterState, dispatchFilter] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ filterState, dispatchFilter }),
    [filterState, dispatchFilter],
  );

  return (
    <ResetPasswordContext.Provider value={value}>
      {children}
    </ResetPasswordContext.Provider>
  );
}

export function useResetPassword() {
  const context = useContext(ResetPasswordContext);
  if (!context) {
    throw new Error(
      "ResetPasswordContext must be used within ResetPasswordProvider",
    );
  }
  return context;
}
