import { useState } from "react";
import { DocumentViewer } from "@/components/document-viewer";
import { Select } from "@hyperlocal/vital2";
import privacyPolicies from "../assets/politica_de_privacidade.pdf";
import termsOfUse from "../assets/termos_de_uso.pdf";

export type Document = {
  title: string;
  url: string;
};

const staticDocuments: Document[] = [
  {
    title: "Política de privacidade",
    url: privacyPolicies,
  },
  {
    title: "Termos de uso",
    url: termsOfUse,
  },
];

export function PrivacyPolicies() {
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    staticDocuments[0] || null,
  );

  const handleSelectDocument = (document: Document) => {
    setSelectedDocument(document);
  };

  return (
    <section className="flex h-full w-full flex-1 flex-col bg-neutral-lighter mobile:bg-neutral-white tablet:bg-neutral-white">
      <div className="mx-auto flex h-full w-full flex-1 flex-col gap-8 mobile:!overflow-hidden desktop:my-inset-lg desktop:px-16">
        <div className="relative flex h-full w-full flex-1 flex-col gap-4 rounded-sm bg-neutral-white p-6 mobile:flex-col mobile:px-4">
          <h2 className="font-base text-2xl font-bold text-primary-main">
            Termos de uso
          </h2>
          <form
            className="flex h-full w-full flex-1 flex-col gap-6"
            id="privacy-policy-form"
          >
            <div className="flex flex-col gap-4">
              <Select.Root
                value={selectedDocument?.url}
                onValueChange={(value) =>
                  handleSelectDocument(
                    staticDocuments.find((document) => document?.url === value),
                  )
                }
              >
                <div className="flex items-center gap-4">
                  <label
                    htmlFor="privacy-policy-document-dropdown"
                    className="font-base text-xs/6 font-bold text-neutral-darkest"
                  >
                    Documento:
                  </label>
                  <Select.Trigger
                    id="privacy-policy-document-dropdown"
                    className="cursor-pointer gap-2 first:[&>span]:truncate"
                  >
                    <Select.Value placeholder="Selecione um documento" />
                  </Select.Trigger>
                  <Select.Content>
                    <Select.Group>
                      {staticDocuments.map((document) => (
                        <Select.Item
                          value={document?.url}
                          key={document?.title}
                        >
                          {document?.title}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </div>
              </Select.Root>
              <label className="font-base text-xs/6 font-bold text-neutral-darkest">
                Data de atualização:{" "}
                <span className="font-base text-xs font-normal">
                  17 de julho de 2024.
                </span>
              </label>
            </div>

            {selectedDocument ? (
              <div className="flex h-full w-full justify-center">
                <DocumentViewer
                  selectedPdf={{
                    title: selectedDocument?.title,
                    url: `${selectedDocument?.url}#toolbar=0&navpanes=0`,
                  }}
                  className="h-full w-full"
                />
              </div>
            ) : (
              <div className="flex h-full w-full items-center justify-center rounded-sm bg-neutral-lighter desktop:h-[660px] desktop:min-w-[536px]">
                <p className="flex flex-col items-center gap-2 text-center">
                  Selecione um documento para visualizar.
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}
